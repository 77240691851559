<div
     class="nav-div display-lg"
     *ngIf="headerCategory"
     style="
          box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 12%);
          background-color: #005120;
          padding-top: 0px;
          margin-bottom: -10px;
     ">
     <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light left-logo" [containerInside]="false">
          <mdb-navbar-brand style="display: block">
               <nav>
                    <div class="container-fluid">
                         <h6 style="color: #fff; margin-bottom: 0px">Welcome to Halloumoğlu</h6>

                         <ul
                              class="navbar-nav nav-flex-icons menu-items"
                              style="font-size: 16px; padding-left: 10px; margin: 0 0">
                              <li style="color: #fff">
                                   <i class="fas fa-map-marker-alt" style="margin-left: 10px; color: #fff"></i>
                                   Gaziantep - Mersin
                              </li>
                              <li style="color: #fff">
                                   <a routerLink="/products/ALL" style="margin-left: 10px; color: #fff">
                                        <i class="fas fa-box-open"></i>
                                        Products
                                   </a>
                              </li>
                              <li *ngIf="userInfo" style="color: #fff">
                                   <a routerLink="/profile" style="margin-left: 10px; color: #fff">
                                        <i class="fas fa-user-circle"></i>
                                        My Account
                                   </a>
                              </li>
                              <li style="color: #fff">
                                   <a (click)="goToTR()" style="margin-right: -10px; color: #fff">
                                        <img src="assets/turkey (1).png" alt="" />
                                   </a>
                              </li>
                              <li style="margin-right: 20px; color: #fff">
                                   <a (click)="goToAR()" style="color: #fff">
                                        <img src="assets/saudi-arabia (1).png" alt="" />
                                   </a>
                              </li>
                         </ul>
                    </div>
               </nav>
          </mdb-navbar-brand>
     </mdb-navbar>
     <nav class="navbar navbar-dark fixed-top" *ngIf="fixedNavbar" style="padding-bottom: 0.7rem !important">
          <mdb-navbar-brand style="display: block">
               <nav>
                    <div class="container-fluid">
                         <a class="navbar-brand" style="margin-right: 240px; padding: 0px" routerLink="/">
                              <img src="/assets/halloum logo.png" style="width: 10vw" />
                         </a>
                         <ul class="menu-items mr-auto" style="margin: 1px">
                              <li
                                   class="nav-item"
                                   style="background-color: #ffc30ed3; border-radius: 100px 10px 10px 100px">
                                   <a
                                        class="nav-link poppins"
                                        routerLink="/categories"
                                        style="padding-bottom: 9px; padding-top: 9px; font-weight: 900"
                                        ><i class="fas fa-stream"></i>
                                        Categories
                                   </a>
                              </li>
                         </ul>
                         <app-auto-complete
                              style="
                                   position: absolute;
                                   z-index: 10000;
                                   border-radius: 10px 100px 100px 10px;
                                   right: 20vw;
                                   width: 44vw;
                              "
                              class="auto-complete">
                         </app-auto-complete>
                         <ul class="navbar-nav nav-flex-icons menu-items" style="padding-left: 10px; margin: 0 0">
                              <li *ngIf="!userInfo" class="nav-item" style="height: 30px">
                                   <a
                                        class="poppins nav-link"
                                        routerLink="/cart"
                                        style="color: #005120; margin-top: 5px">
                                        <span
                                             [matBadge]="numOfCartProducts"
                                             matBadgeOverlap="false"
                                             [matBadgeHidden]="numOfCartProducts == 0"
                                             matBadgeColor="warn"
                                             matBadgePosition="before">
                                             <i class="fas fa-shopping-bag"></i>
                                        </span>
                                   </a>
                              </li>

                              <li
                                   class="nav-item dropdown"
                                   dropdown
                                   *ngIf="userInfo"
                                   style="height: 30px; z-index: 100000000000">
                                   <a
                                        dropdownToggle
                                        mdbWavesEffect
                                        type="button"
                                        class="poppins nav-link dropdown-toggle waves-light"
                                        style="color: #005120; margin-top: 5px"
                                        mdbWavesEffect>
                                        <i class="fas fa-user"></i>
                                        <span class="caret"></span
                                   ></a>

                                   <div
                                        *dropdownMenu
                                        class="dropdown-menu dropdown dropdown-primary shadow-lg"
                                        role="menu"
                                        style="left: -160px; top: 30px !important">
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/profile">
                                             <i class="fas fa-user-circle" style="margin-right: 10px"></i>
                                             Profile
                                        </a>
                                        <!-- <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/cart"
                                        >
                                             <span
                                                  [matBadge]="numOfCartProducts"
                                                  matBadgeOverlap="false"
                                                  [matBadgeHidden]="numOfCartProducts == 0"
                                                  matBadgeColor="warn"
                                                  matBadgePosition="after"
                                             >
                                                  <i class="fas fa-shopping-cart" style="margin-right: 10px"></i>
                                                  Cart
                                             </span>
                                        </a> -->
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/my-orders">
                                             <i class="fas fa-cubes" style="margin-right: 10px"></i> My orders
                                        </a>
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/my-addresses">
                                             <i class="fas fa-map-marker-alt" style="margin-right: 10px"></i>
                                             My Address
                                        </a>
                                        <div class="divider dropdown-divider"></div>
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             (click)="signOut()">
                                             <i class="fas fa-sign-out-alt" style="margin-right: 10px"></i>
                                             Sign Out
                                        </a>
                                   </div>
                              </li>
                              <li *ngIf="userInfo" class="nav-item" style="height: 30px">
                                   <a class="poppins nav-link" style="color: #005120; margin-top: 5px" *ngIf="userData">
                                        <p>{{ userData.name | titlecase }}</p>
                                   </a>
                              </li>
                              <li *ngIf="!userInfo">
                                   <a class="poppins" routerLink="/sign-in" style="padding-top: 5px">
                                        <i class="fas fa-sign-in-alt"></i> Sign In</a
                                   >
                              </li>
                         </ul>
                    </div>
               </nav>
          </mdb-navbar-brand>
     </nav>
</div>

<div
     class="nav-div display-lg"
     *ngIf="headerCategory"
     style="
          box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
          padding-top: 20px;
          padding-bottom: 10px;
          width: 100%;
          background-color: #fff;
     ">
     <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light left-logo" [containerInside]="false">
          <mdb-navbar-brand style="display: block">
               <nav>
                    <div class="container-fluid">
                         <a class="navbar-brand" style="padding: 0px" routerLink="/">
                              <img src="/assets/halloum logo.png" style="width: 10vw" />
                         </a>
                         <ul class="menu-items mr-auto" style="margin: 1px">
                              <li
                                   class="nav-item"
                                   style="background-color: #004f1f; border-radius: 100px 10px 10px 100px">
                                   <a
                                        class="nav-link poppins"
                                        routerLink="/categories"
                                        style="color: #fff; padding-bottom: 9px; padding-top: 9px; font-weight: 900"
                                        ><i class="fas fa-list"></i>
                                        Categories
                                   </a>
                              </li>
                         </ul>
                         <app-auto-complete
                              style="
                                   position: absolute;
                                   z-index: 10000;
                                   border-radius: 10px 100px 100px 10px;
                                   right: 20vw;
                                   width: 44vw;
                              "
                              class="auto-complete">
                         </app-auto-complete>
                         <ul class="navbar-nav nav-flex-icons menu-items" style="padding-left: 10px; margin: 0 0">
                              <li class="nav-item" style="height: 30px">
                                   <a
                                        class="poppins nav-link"
                                        routerLink="/cart"
                                        style="color: #005120; margin-top: 5px">
                                        <span
                                             [matBadge]="numOfCartProducts"
                                             matBadgeOverlap="false"
                                             [matBadgeHidden]="numOfCartProducts == 0"
                                             matBadgeColor="warn"
                                             matBadgePosition="before">
                                             <i class="fas fa-shopping-bag"></i>
                                        </span>
                                   </a>
                              </li>

                              <li
                                   class="nav-item dropdown"
                                   dropdown
                                   *ngIf="userInfo"
                                   style="height: 30px; z-index: 100000000000">
                                   <a
                                        dropdownToggle
                                        mdbWavesEffect
                                        type="button"
                                        class="poppins nav-link dropdown-toggle waves-light"
                                        style="color: #005120; margin-top: 5px"
                                        mdbWavesEffect>
                                        <i class="fas fa-user"></i>
                                        <span class="caret"></span
                                   ></a>

                                   <div
                                        *dropdownMenu
                                        class="dropdown-menu dropdown dropdown-primary shadow-lg"
                                        role="menu"
                                        style="left: -160px; top: 30px !important">
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/profile">
                                             <i class="fas fa-user-circle" style="margin-right: 10px"></i>
                                             Profile
                                        </a>
                                        <!-- <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/cart"
                                        >
                                             <span
                                                  [matBadge]="numOfCartProducts"
                                                  matBadgeOverlap="false"
                                                  [matBadgeHidden]="numOfCartProducts == 0"
                                                  matBadgeColor="warn"
                                                  matBadgePosition="after"
                                             >
                                                  <i class="fas fa-shopping-cart" style="margin-right: 10px"></i>
                                                  Cart
                                             </span>
                                        </a> -->
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/my-orders">
                                             <i class="fas fa-cubes" style="margin-right: 10px"></i> My orders
                                        </a>
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             routerLink="/my-addresses">
                                             <i class="fas fa-map-marker-alt" style="margin-right: 10px"></i>
                                             My Address
                                        </a>
                                        <div class="divider dropdown-divider"></div>
                                        <a
                                             class="dropdown-item waves-light"
                                             style="padding: 10px 15px"
                                             mdbWavesEffect
                                             (click)="signOut()">
                                             <i class="fas fa-sign-out-alt" style="margin-right: 10px"></i>
                                             Sign Out
                                        </a>
                                   </div>
                              </li>
                              <li *ngIf="userInfo" class="nav-item" style="height: 30px">
                                   <a
                                        routerLink="/profile"
                                        class="poppins nav-link"
                                        style="color: #005120; margin-top: 5px"
                                        *ngIf="userData">
                                        <p>{{ userData.name | titlecase }}</p>
                                   </a>
                              </li>
                              <li *ngIf="!userInfo">
                                   <a class="poppins" routerLink="/sign-in" style="padding-top: 5px">
                                        <i class="fas fa-sign-in-alt"></i> Sign In</a
                                   >
                              </li>
                         </ul>
                    </div>
               </nav>
          </mdb-navbar-brand>
     </mdb-navbar>
     <nav class="navbar navbar-dark fixed-top" *ngIf="fixedNavbar" style="padding-bottom: 0.5rem !important">
          <div class="container-fluid">
               <a class="navbar-brand" style="margin-right: 8%; padding: 0px" routerLink="/">
                    <img src="/assets/halloum logo.png" style="width: 10vw" />
               </a>
               <ul class="menu-items mr-auto" style="margin: 1px">
                    <li
                         class="nav-item"
                         style="
                              background-color: #ffc30ed3;
                              border-bottom-left-radius: 5px;
                              border-top-left-radius: 5px;
                         ">
                         <a
                              class="nav-link poppins"
                              (click)="toggleSidenav()"
                              style="padding-bottom: 5px; padding-top: 5px; font-weight: 900"
                              ><i class="fas fa-stream"></i>
                              All Categories
                         </a>
                    </li>
                    <li>
                         <a class="poppins" routerLink="/" style="padding-bottom: 5px; padding-top: 5px"
                              ><i class="fas fa-home"></i> Home</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/about" style="padding-bottom: 5px; padding-top: 5px"
                              ><i class="fas fa-info"></i> About</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/gallery" style="padding-bottom: 5px; padding-top: 5px">
                              <i class="fas fa-photo-video"></i> Gallery</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/contact" style="padding-bottom: 5px; padding-top: 5px"
                              ><i class="fas fa-headset"></i> Contact</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/branches" style="padding-bottom: 5px; padding-top: 5px"
                              ><i class="fas fa-code-branch"></i> Branches</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/careers" style="padding-bottom: 5px; padding-top: 5px">
                              <i class="fas fa-search-dollar"></i> Careers</a
                         >
                    </li>
                    <li>
                         <a class="poppins" routerLink="/our-team" style="padding-bottom: 5px; padding-top: 5px">
                              <i class="fas fa-user-friends"></i> Team</a
                         >
                    </li>
               </ul>
               <app-auto-complete
                    style="position: absolute; z-index: 10000; top: 1.75vw; right: 13vw; width: 16vw"
                    class="auto-complete">
               </app-auto-complete>
               <ul class="navbar-nav nav-flex-icons menu-items" style="padding-left: 10px; margin: 0 0">
                    <li class="nav-item" style="height: 30px">
                         <a class="poppins nav-link" routerLink="/cart" style="color: #005120; margin-top: 5px">
                              <span
                                   [matBadge]="numOfCartProducts"
                                   matBadgeOverlap="false"
                                   [matBadgeHidden]="numOfCartProducts == 0"
                                   matBadgeColor="warn"
                                   matBadgePosition="before">
                                   <i class="fas fa-shopping-cart"></i>
                              </span>
                         </a>
                    </li>
                    <li class="nav-item dropdown" dropdown *ngIf="userInfo" style="height: 30px; z-index: 100000000000">
                         <a
                              dropdownToggle
                              mdbWavesEffect
                              type="button"
                              class="poppins nav-link dropdown-toggle waves-light"
                              style="color: #005120; margin-top: 5px"
                              mdbWavesEffect>
                              <i class="fas fa-user"></i>
                              <span class="caret"></span
                         ></a>
                         <div
                              *dropdownMenu
                              class="dropdown-menu dropdown dropdown-primary shadow-lg"
                              role="menu"
                              style="left: -160px; top: 30px !important">
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px"
                                   mdbWavesEffect
                                   routerLink="/profile">
                                   <i class="fas fa-user-circle" style="margin-right: 10px"></i>
                                   Profile
                              </a>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px"
                                   mdbWavesEffect
                                   routerLink="/cart">
                                   <span
                                        [matBadge]="numOfCartProducts"
                                        matBadgeOverlap="false"
                                        [matBadgeHidden]="numOfCartProducts == 0"
                                        matBadgeColor="warn"
                                        matBadgePosition="after">
                                        <i class="fas fa-shopping-cart" style="margin-right: 10px"></i>
                                        Cart
                                   </span>
                              </a>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px"
                                   mdbWavesEffect
                                   routerLink="/my-orders">
                                   <i class="fas fa-cubes" style="margin-right: 10px"></i> My orders
                              </a>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px"
                                   mdbWavesEffect
                                   routerLink="/my-addresses">
                                   <i class="fas fa-map-marker-alt" style="margin-right: 10px"></i>
                                   My Address
                              </a>
                              <div class="divider dropdown-divider"></div>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px"
                                   mdbWavesEffect
                                   (click)="signOut()">
                                   <i class="fas fa-sign-out-alt" style="margin-right: 10px"></i>
                                   Sign Out
                              </a>
                         </div>
                    </li>
                    <li *ngIf="!userInfo">
                         <a class="poppins" routerLink="/sign-in" style="padding-top: 5px">
                              <i class="fas fa-sign-in-alt"></i> Sign In</a
                         >
                    </li>
               </ul>
          </div>
     </nav>
</div>
<div
     class="nav-div display-lg"
     *ngIf="headerCategory"
     style="
          box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 12%);
          width: 100%;
          background-color: #fff;
          padding-top: 10px;
     ">
     <nav class="navbar navbar-dark fixed-top" *ngIf="fixedNavbar" style="padding-bottom: 0.3rem !important">
          <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light left-logo" [containerInside]="false">
               <mdb-navbar-brand style="display: block">
                    <nav>
                         <div class="container-fluid">
                              <a class="navbar-brand" style="margin-right: 13%; padding: 0px" routerLink="/">
                                   <img src="/assets/halloum logo.png" style="width: 10vw" />
                              </a>
                              <ul class="menu-items mr-auto" style="margin: 1px">
                                   <li
                                        class="nav-item"
                                        style="background-color: #004f1f; border-radius: 100px 10px 10px 100px">
                                        <a
                                             class="nav-link poppins"
                                             routerLink="/categories"
                                             style="
                                                  color: #fff;
                                                  padding-bottom: 9px;
                                                  padding-top: 9px;
                                                  font-weight: 900;
                                             "
                                             ><i class="fas fa-list"></i>
                                             Categories
                                        </a>
                                   </li>
                              </ul>
                              <app-auto-complete
                                   style="
                                        position: absolute;
                                        z-index: 10000;
                                        border-radius: 10px 100px 100px 10px;
                                        right: 20vw;
                                        width: 44vw;
                                   "
                                   class="auto-complete">
                              </app-auto-complete>
                              <ul class="navbar-nav nav-flex-icons menu-items" style="padding-left: 10px; margin: 0 0">
                                   <li class="nav-item" style="height: 30px">
                                        <a
                                             class="poppins nav-link"
                                             routerLink="/cart"
                                             style="color: #005120; margin-top: 5px">
                                             <span
                                                  [matBadge]="numOfCartProducts"
                                                  matBadgeOverlap="false"
                                                  [matBadgeHidden]="numOfCartProducts == 0"
                                                  matBadgeColor="warn"
                                                  matBadgePosition="before">
                                                  <i class="fas fa-shopping-bag"></i>
                                             </span>
                                        </a>
                                   </li>
                                   <li
                                        class="nav-item dropdown"
                                        dropdown
                                        *ngIf="userInfo"
                                        style="height: 30px; z-index: 100000000000">
                                        <a
                                             dropdownToggle
                                             mdbWavesEffect
                                             type="button"
                                             class="poppins nav-link dropdown-toggle waves-light"
                                             style="color: #005120; margin-top: 5px"
                                             mdbWavesEffect>
                                             <i class="fas fa-user"></i>
                                             <span class="caret"></span
                                        ></a>
                                        <div
                                             *dropdownMenu
                                             class="dropdown-menu dropdown dropdown-primary shadow-lg"
                                             role="menu"
                                             style="left: -160px; top: 30px !important">
                                             <a
                                                  class="dropdown-item waves-light"
                                                  style="padding: 10px 15px"
                                                  mdbWavesEffect
                                                  routerLink="/profile">
                                                  <i class="fas fa-user-circle" style="margin-right: 10px"></i>
                                                  Profile
                                             </a>

                                             <a
                                                  class="dropdown-item waves-light"
                                                  style="padding: 10px 15px"
                                                  mdbWavesEffect
                                                  routerLink="/my-orders">
                                                  <i class="fas fa-cubes" style="margin-right: 10px"></i> My orders
                                             </a>
                                             <a
                                                  class="dropdown-item waves-light"
                                                  style="padding: 10px 15px"
                                                  mdbWavesEffect
                                                  routerLink="/my-addresses">
                                                  <i class="fas fa-map-marker-alt" style="margin-right: 10px"></i>
                                                  My Address
                                             </a>
                                             <div class="divider dropdown-divider"></div>
                                             <a
                                                  class="dropdown-item waves-light"
                                                  style="padding: 10px 15px"
                                                  mdbWavesEffect
                                                  (click)="signOut()">
                                                  <i class="fas fa-sign-out-alt" style="margin-right: 10px"></i>
                                                  Sign Out
                                             </a>
                                        </div>
                                   </li>

                                   <li *ngIf="userInfo" class="nav-item" style="height: 30px">
                                        <a
                                             routerLink="/profile"
                                             class="poppins nav-link"
                                             style="color: #005120; margin-top: 5px"
                                             *ngIf="userData">
                                             <p>{{ userData.name | titlecase }}</p>
                                        </a>
                                   </li>
                                   <li *ngIf="!userInfo">
                                        <a class="poppins" routerLink="/sign-in" style="padding-top: 5px">
                                             <i class="fas fa-sign-in-alt"></i> Sign In</a
                                        >
                                   </li>
                              </ul>
                         </div>
                    </nav>
               </mdb-navbar-brand>
          </mdb-navbar>
     </nav>
</div>
<!-- mobile header -->

<div style="box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)" *ngIf="mdScreen">
     <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light " [containerInside]="false">
          <mdb-navbar-brand>
               <a class="navbar-brand mx-auto" routerLink="/">
                    <img src="assets/Halloumoglu online logo.png" style="width: 120px" />
               </a>
          </mdb-navbar-brand>

          <div style="margin-top: 5px">
               <app-auto-complete style="width: 100%"> </app-auto-complete>
          </div>
          <links>
               <ul class="navbar-nav ml-auto ceerer">
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-home"></i> Home
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/about"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-info"></i> About
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/gallery"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-photo-video"></i> Gallery
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/contact"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-headset"></i> Contact
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/branches"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-code-branch"></i> Branches
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/careers"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-search-dollar"></i> Careers
                         </a>
                    </li>
                    <li class="nav-item" style="text-align: center; border-radius: 100px 10px 10px 100px">
                         <a
                              class="nav-link poppins"
                              routerLink="/categories"
                              style="color: #fff; padding-bottom: 9px; padding-top: 9px; font-weight: 900"
                              ><i class="fas fa-list"></i>
                              Categories
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              class="nav-link waves-light poppins"
                              routerLink="/our-team"
                              style="font-size: 14px; text-align: center"
                              mdbWavesEffect>
                              <i class="fas fa-user-friends"></i> Team
                         </a>
                    </li>
                    <li class="nav-item" style="height: 30px">
                         <a
                              class="poppins nav-link"
                              routerLink="/cart"
                              style="font-size: 14px; text-align: center; font-weight: 900 !important">
                              <span
                                   [matBadge]="numOfCartProducts"
                                   matBadgeOverlap="false"
                                   [matBadgeHidden]="numOfCartProducts == 0"
                                   matBadgeColor="warn"
                                   matBadgePosition="before">
                                   <i class="fas fa-shopping-cart"></i>
                              </span>
                         </a>
                    </li>
                    <li class="nav-item dropdown" dropdown *ngIf="userInfo" style="height: 30px">
                         <a
                              dropdownToggle
                              mdbWavesEffect
                              type="button"
                              class="poppins nav-link dropdown-toggle waves-light"
                              style="font-size: 14px; text-align: center; font-weight: 900 !important; color: #005121"
                              mdbWavesEffect>
                              <i class="fas fa-user"></i>
                              <span class="caret"></span
                         ></a>
                         <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary shadow-lg" role="menu">
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px; color: #005121"
                                   mdbWavesEffect
                                   routerLink="/profile">
                                   <i class="fas fa-user-circle" style="margin-right: 10px"></i>
                                   Profile
                              </a>
                              <!-- <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px; color: #005121"
                                   mdbWavesEffect
                                   routerLink="/cart"
                              >
                                   <span
                                        [matBadge]="numOfCartProducts"
                                        matBadgeOverlap="false"
                                        [matBadgeHidden]="numOfCartProducts == 0"
                                        matBadgeColor="warn"
                                        matBadgePosition="after"
                                   >
                                        <i class="fas fa-shopping-cart" style="margin-right: 10px"></i>
                                        Cart
                                   </span>
                              </a> -->
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px; color: #005121"
                                   mdbWavesEffect
                                   routerLink="/my-orders">
                                   <i class="fas fa-cubes" style="margin-right: 10px"></i> My Orders
                              </a>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px; color: #005121"
                                   mdbWavesEffect
                                   routerLink="/my-addresses">
                                   <i class="fas fa-map-marker-alt" style="margin-right: 10px"></i>
                                   My Address
                              </a>
                              <div class="divider dropdown-divider"></div>
                              <a
                                   class="dropdown-item waves-light"
                                   style="padding: 10px 15px; color: #005121"
                                   mdbWavesEffect
                                   (click)="signOut()">
                                   <i class="fas fa-sign-out-alt" style="margin-right: 10px"></i>
                                   Sign Out
                              </a>
                         </div>
                    </li>
                    <li class="nav-item" *ngIf="!userInfo">
                         <a
                              class="poppins"
                              routerLink="/sign-in"
                              style="font-size: 14px; text-align: center; font-weight: 900 !important">
                              <i class="fas fa-sign-in-alt"></i> Sign In</a
                         >
                    </li>
                    <li class="nav-item">
                         <a
                              (click)="goToTR()"
                              class="nav-link"
                              style="
                                   border-left: none !important;
                                   color: #005120d3;
                                   font-weight: 900 !important;
                                   text-align: center;
                              ">
                              TR
                         </a>
                    </li>
                    <li class="nav-item">
                         <a
                              (click)="goToAR()"
                              class="nav-link"
                              style="
                                   border-right: none !important;
                                   color: #005120d3;
                                   font-weight: 900 !important;
                                   text-align: center;
                              ">
                              AR
                         </a>
                    </li>
               </ul>
          </links>
     </mdb-navbar>
</div>
