<mat-sidenav-container class="example-container">
     <mat-sidenav #sidenav mode="Push" opened="false" [fixedInViewport]="true" fixedTopGap="0">
          <span class="lemonada center mt-4 mb-4 text-green" style="width: 100%; text-align: center; padding: 0 16px">
               All Categories
               <a style="margin-left: 50px" (click)="sidenav.close()">
                    <i class="fas fa-times"></i>
               </a>
          </span>
          <hr />
          <mat-nav-list>
               <div *ngFor="let category of categories">
                    <a
                         mat-list-item
                         class="lemonada text-green"
                         style="font-size: 14px"
                         [routerLink]="['/products', category.id]"
                         (click)="sidenav.close()">
                         <img class="img-sidnav" src="{{ category.icon }}" target="_blank" />
                         <span style="margin-top: 5px">{{ category.nameEN }} Products</span>
                    </a>
                    <hr class="dropdown-divider" />
               </div>
          </mat-nav-list>
     </mat-sidenav>
     <mat-sidenav-content>
          <div
               mdbModal
               #frame="mdbModal"
               class="modal fade bottom modal-scrolling"
               id="frameModalTop"
               tabindex="-1"
               role="dialog"
               aria-labelledby="myModalLabel"
               aria-hidden="true"
               [config]="{ backdrop: 'static', ignoreBackdropClick: false }"
               style="background: rgba(0, 0, 0, 0.35)">
               <div class="modal-dialog modal-frame modal-bottom modal-notify modal-info" role="document">
                    <div class="modal-content">
                         <div class="modal-body" style="background: #252525">
                              <div class="row d-flex justify-content-center align-items-center">
                                   <div class="col-md-10 col-12">
                                        <p class="text-center" style="margin-bottom: 0px">
                                             We use cookies that comply with legal regulations to improve your shopping
                                             experience. For detailed information, you can review our<a
                                                  class="showMore"
                                                  routerLink="/privacy-policy"
                                                  style="margin-left: 4px"
                                                  [queryParams]="{ openPanel: '1' }">
                                                  Cookie Clarification Text.
                                             </a>
                                        </p>
                                   </div>
                                   <div class="col-md-2 center col-6">
                                        <a
                                             class="btn btn-halloum2 btn-sm btn-block mt-1"
                                             data-dismiss="modal"
                                             (click)="frame.hide(); acceptCookies()"
                                             >Ok, thanks</a
                                        >
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <router-outlet></router-outlet>

          <app-fab></app-fab>
     </mat-sidenav-content>
</mat-sidenav-container>
