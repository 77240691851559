<app-header id="header"></app-header>

<div style="min-height: 900px" class="main-home" *ngIf="spinner">
     <mdb-carousel
          [isControls]="true"
          class="carousel slide carousel-fade"
          [animation]="'fade'"
          *ngIf="sliders && mdScreen">
          <mdb-carousel-item *ngFor="let slider of sliders">
               <div class="view" style="object-fit: fill">
                    <a href="{{ slider.link }}" target="_blank">
                         <img
                              style="object-fit: cover; width: 400px; height: 200px"
                              class="d-block img-sm"
                              src="{{ slider.image }}"
                              alt="Slider" />
                    </a>
               </div>
          </mdb-carousel-item>
     </mdb-carousel>

     <mdb-carousel
          [isControls]="true"
          class="carousel slide carousel-fade"
          [animation]="'fade'"
          style="min-height: 1200px"
          *ngIf="sliders && !mdScreen">
          <mdb-carousel-item *ngFor="let slider of sliders">
               <div class="view w-100" style="min-height: 450px">
                    <a href="{{ slider.link }}" target="_blank">
                         <img class="d-block w-100 img-sm" src="{{ slider.image }}" width="100%" alt="Slider" />
                    </a>
               </div>
          </mdb-carousel-item>
     </mdb-carousel>

     <div *ngIf="headerCategory && mdScreen" class="main-home">
          <div style="margin-top: 3%">
               <swiper
                    [spaceBetween]="3"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [config]="configCategoris">
                    <ng-template swiperSlide *ngFor="let category of sortData">
                         <div style="text-align: center" class="col-md-12 mb-4 mt-3">
                              <div matBadge="NEW" matBadgePosition="after" [matBadgeHidden]="!category.new">
                                   <a [routerLink]="['/products', category.id]">
                                        <img
                                             style="width: 100% !important; aspect-ratio: 1/1 !important"
                                             src="{{ category.vertical }}"
                                             class="img-product" />
                                   </a>

                                   <div style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  [routerLink]="['/products', category.id]"
                                                  class="lemonada"
                                                  style="color: #333333; font-size: 12px">
                                                  {{ category.nameEN }}
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </swiper>
          </div>
     </div>
     <div *ngIf="headerCategory && !mdScreen" class="main-home">
          <div style="margin-top: 3%; min-height: 250px">
               <swiper
                    [spaceBetween]="3"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [config]="configCategoris">
                    <ng-template swiperSlide *ngFor="let category of sortData">
                         <div style="text-align: center" class="col-md-12 mb-4 mt-1">
                              <div
                                   style="min-height: 250px"
                                   matBadge="NEW"
                                   matBadgePosition="after"
                                   [matBadgeHidden]="!category.new">
                                   <a [routerLink]="['/products', category.id]">
                                        <img
                                             style="width: 100% !important; aspect-ratio: 1/1 !important"
                                             src="{{ category.vertical }}"
                                             class="img-product" />
                                   </a>

                                   <div style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  [routerLink]="['/products', category.id]"
                                                  class="lemonada"
                                                  style="color: #333333; font-size: 12px">
                                                  {{ category.nameEN }}
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </swiper>
          </div>
     </div>

     <div>
          <div class="container" style="margin-top: 3%; min-height: 300px">
               <h3 class="lemonada text-center mb-4 h3-responsive">New Products</h3>
               <swiper
                    [spaceBetween]="20"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [config]="configProducts"
                    [loop]="true">
                    <ng-template swiperSlide *ngFor="let product of NewProducts; let i = index">
                         <div
                              style="padding-left: 5px !important; padding-right: 5px !important"
                              class="col-md-12 mb-4 mt-3">
                              <div
                                   class="card rounded-8px shadow-sm border-0"
                                   matBadge="NEW"
                                   matBadgePosition="after"
                                   [matBadgeHidden]="!product.new">
                                   <a [routerLink]="['/product', product.id]">
                                        <img src="{{ product.images[0] }}" class="img-product" />
                                   </a>
                                   <div
                                        class="out-of-stock"
                                        *ngIf="product.stock == 0 || product.stock == null || !product.stock">
                                        Out Of Stock
                                   </div>
                                   <div class="card-body" style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  [routerLink]="['/product', product.id]"
                                                  class="lemonada"
                                                  style="color: #333333; font-size: 12px">
                                                  {{ product.nameEN }}
                                             </a>
                                        </div>

                                        <div class="row" style="align-items: center">
                                             <div class="col-5">
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'noDiscount'">
                                                       {{ product.price }} TRY
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'discount'">
                                                       {{ product.price - product.discount }} TRY
                                                       <small class="dis-price">
                                                            {{ product.price }}
                                                            TRY</small
                                                       >
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'percent'">
                                                       {{ product.price - product.price * (product.discount / 100) }}
                                                       TRY
                                                       <small class="dis-price"> {{ product.price }} TRY</small>
                                                  </h5>
                                             </div>
                                             <div style="text-align: end" class="col-7">
                                                  <a
                                                       (click)="addingNewProducts(i)"
                                                       style="
                                                            text-align: right;
                                                            background: green;
                                                            color: white;
                                                            border: green;
                                                       "
                                                       *ngIf="product.stock > 0 && !product.addingCart"
                                                       class="btn btn-halloum2 btn-sm poppins"
                                                       >Add +</a
                                                  >
                                                  <div class="mt-1" *ngIf="product.addingCart">
                                                       <div class="stepper-input stepper__input">
                                                            <button
                                                                 class="toggle-less"
                                                                 (click)="toggleLessNewProducts(i)">
                                                                 <i class="fas fa-minus"></i>
                                                            </button>
                                                            <div
                                                                 class="stepper-input-field"
                                                                 name="range-value"
                                                                 aria-atomic="true"
                                                                 aria-live="polite"
                                                                 aria-relevant="all">
                                                                 {{ product.counter }}
                                                            </div>
                                                            <button
                                                                 class="toggle-more"
                                                                 (click)="toggleMoreNewProducts(i)"
                                                                 *ngIf="product.stock > product.counter">
                                                                 <i class="fas fa-plus"></i>
                                                            </button>
                                                            <button
                                                                 class="toggle-more"
                                                                 *ngIf="product.stock <= product.counter"
                                                                 [disabled]="true">
                                                                 <i class="fas fa-plus"></i>
                                                            </button>
                                                       </div>
                                                  </div>
                                                  <a
                                                       *ngIf="
                                                            product.stock == 0 ||
                                                            product.stock == null ||
                                                            !product.stock
                                                       "
                                                       class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                                       >Out Of Stock</a
                                                  >
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </swiper>
          </div>

          <!-- wideImage -->
          <div class="container-fluid" style="margin-top: 5%; min-height: 150px" *ngIf="mdScreen">
               <div *ngIf="wideImage.length > 0" style="margin-top: 5%; margin-bottom: 5%">
                    <a [href]="wideImage[0].link">
                         <img
                              style="height: 100px; object-fit: cover"
                              class="center rounded"
                              src="{{ wideImage[0].image }}"
                              width="100%"
                              alt="" />
                    </a>
               </div>
          </div>

          <div class="container-fluid" style="margin-top: 5%; min-height: 300px" *ngIf="!mdScreen">
               <div *ngIf="wideImage.length > 0" style="margin-top: 5%; margin-bottom: 5%">
                    <a [href]="wideImage[0].link">
                         <img
                              style="height: 300px; object-fit: cover"
                              class="center rounded"
                              src="{{ wideImage[0].image }}"
                              width="100%"
                              alt="" />
                    </a>
               </div>
          </div>

          <div class="container categories" style="min-height: 300px">
               <h3 class="lemonada text-center mt-1 mb-4 h3-responsive">Best Selling Products</h3>
               <swiper
                    [spaceBetween]="20"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [config]="configProducts"
                    [loop]="true">
                    <ng-template swiperSlide *ngFor="let product of products; let i = index">
                         <div
                              style="padding-left: 5px !important; padding-right: 5px !important"
                              class="col-md-12 mb-4 mt-1">
                              <div
                                   class="card rounded-8px shadow-sm border-0"
                                   matBadge="NEW"
                                   matBadgePosition="after"
                                   [matBadgeHidden]="!product.new">
                                   <a [routerLink]="['/product', product.id]">
                                        <img src="{{ product.images[0] }}" class="img-product" />
                                   </a>
                                   <div
                                        class="out-of-stock"
                                        *ngIf="product.stock == 0 || product.stock == null || !product.stock">
                                        Out Of Stock
                                   </div>
                                   <div class="card-body" style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  [routerLink]="['/product', product.id]"
                                                  class="lemonada"
                                                  style="color: #333333; font-size: 12px">
                                                  {{ product.nameEN }}
                                             </a>
                                        </div>
                                        <div class="row" style="align-items: center">
                                             <div class="col-5">
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'noDiscount'">
                                                       {{ product.price }} TRY
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'discount'">
                                                       {{ product.price - product.discount }} TRY
                                                       <small class="dis-price">
                                                            {{ product.price }}
                                                            TRY</small
                                                       >
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: center;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'percent'">
                                                       {{ product.price - product.price * (product.discount / 100) }}
                                                       TRY
                                                       <small class="dis-price"> {{ product.price }} TRY</small>
                                                  </h5>
                                             </div>
                                             <div style="text-align: end" class="col-7">
                                                  <a
                                                       (click)="addingBestProducts(i)"
                                                       style="
                                                            text-align: right;
                                                            background: green;
                                                            color: white;
                                                            border: green;
                                                       "
                                                       *ngIf="product.stock > 0 && !product.addingCart"
                                                       class="btn btn-halloum2 btn-sm poppins"
                                                       >Add +</a
                                                  >
                                                  <div class="mt-1" *ngIf="product.addingCart">
                                                       <div class="stepper-input stepper__input">
                                                            <button
                                                                 class="toggle-less"
                                                                 (click)="toggleLessBestProducts(i)">
                                                                 <i class="fas fa-minus"></i>
                                                            </button>
                                                            <div
                                                                 class="stepper-input-field"
                                                                 name="range-value"
                                                                 aria-atomic="true"
                                                                 aria-live="polite"
                                                                 aria-relevant="all">
                                                                 {{ product.counter }}
                                                            </div>
                                                            <button
                                                                 class="toggle-more"
                                                                 (click)="toggleMoreBestProducts(i)"
                                                                 *ngIf="product.stock > product.counter">
                                                                 <i class="fas fa-plus"></i>
                                                            </button>
                                                            <button
                                                                 class="toggle-more"
                                                                 *ngIf="product.stock <= product.counter"
                                                                 [disabled]="true">
                                                                 <i class="fas fa-plus"></i>
                                                            </button>
                                                       </div>
                                                  </div>
                                                  <a
                                                       *ngIf="
                                                            product.stock == 0 ||
                                                            product.stock == null ||
                                                            !product.stock
                                                       "
                                                       class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                                       >Out Of Stock</a
                                                  >
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </swiper>
          </div>

          <!-- video YouTube -->

          <div class="container-fluid" *ngIf="wideVideo && !mdScreen">
               <div class="col-md-11 center">
                    <div class="videowrapper center mb-4" style="position: relative; padding: 3%; bottom: 0; left: 0">
                         <iframe
                              width="100%"
                              height="500vw"
                              style="
                                   border-radius: 10px;
                                   box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
                              "
                              [src]="wideVideo"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen></iframe>
                    </div>
               </div>
          </div>

          <div class="container-fluid" *ngIf="wideVideo && mdScreen">
               <div class="col-md-11 center">
                    <div class="videowrapper center mb-4" style="position: relative; padding: 3%; bottom: 0; left: 0">
                         <iframe
                              width="100%"
                              height="500vw"
                              style="
                                   border-radius: 10px;
                                   box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
                              "
                              [src]="wideVideo"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen></iframe>
                    </div>
               </div>
          </div>

          <!-- Feed Back -->

          <div class="container-fluid" *ngIf="mdScreen">
               <div *ngIf="wideImage.length > 1" style="margin-top: 5%; margin-bottom: 5%">
                    <a [href]="wideImage[1].link">
                         <img
                              style="height: 100px; object-fit: cover"
                              class="center rounded"
                              src="{{ wideImage[1].image }}"
                              width="100%"
                              alt="" />
                    </a>
               </div>
          </div>
          <div class="container-fluid" *ngIf="!mdScreen">
               <div *ngIf="wideImage.length > 1" style="margin-top: 5%; margin-bottom: 5%">
                    <a [href]="wideImage[1].link">
                         <img
                              style="height: 300px; object-fit: cover"
                              class="center rounded"
                              src="{{ wideImage[1].image }}"
                              width="100%"
                              alt="" />
                    </a>
               </div>
          </div>

          <div class="container">
               <p class="text-center" style="font-size: 40px"></p>
               <h3 class="lemonada text-center h3-responsive" style="margin-bottom: 5% !important">All Products</h3>
               <div class="row">
                    <div
                         style="padding-left: 5px !important; padding-right: 5px !important"
                         class="col-xl-2 col-lg-3 col-md-4 col-6 mb-4"
                         *ngFor="let product of allProducts; let i = index">
                         <div
                              class="card rounded-8px shadow-sm border-0"
                              matBadge="NEW"
                              matBadgePosition="after"
                              [matBadgeHidden]="!product.new">
                              <a
                                   style="padding-left: 5px !important; padding-right: 5px !important"
                                   [routerLink]="['/product', product.id]">
                                   <img src="{{ product.images[0] }}" class="img-product" />
                              </a>
                              <div
                                   class="out-of-stock"
                                   *ngIf="product.stock == 0 || product.stock == null || !product.stock">
                                   Out Of Stock
                              </div>
                              <div class="card-body" style="padding: 5%">
                                   <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                        <a
                                             [routerLink]="['/product', product.id]"
                                             class="lemonada"
                                             style="color: #333333; font-size: 11px">
                                             {{ product.nameEN }}
                                        </a>
                                   </div>
                                   <div class="row" style="align-items: center">
                                        <div class="col-5">
                                             <h5
                                                  class="act-price"
                                                  style="
                                                       text-align: center;
                                                       color: #333333;
                                                       font-size: 12px;
                                                       margin-bottom: 0px !important;
                                                  "
                                                  *ngIf="product.discountType == 'noDiscount'">
                                                  {{ product.price }} TRY
                                             </h5>
                                             <h5
                                                  class="act-price"
                                                  style="
                                                       text-align: center;
                                                       color: #333333;
                                                       font-size: 12px;
                                                       margin-bottom: 0px !important;
                                                  "
                                                  *ngIf="product.discountType == 'discount'">
                                                  {{ product.price - product.discount }} TRY
                                                  <small class="dis-price">
                                                       {{ product.price }}
                                                       TRY</small
                                                  >
                                             </h5>
                                             <h5
                                                  class="act-price"
                                                  style="
                                                       text-align: center;
                                                       color: #333333;
                                                       font-size: 12px;
                                                       margin-bottom: 0px !important;
                                                  "
                                                  *ngIf="product.discountType == 'percent'">
                                                  {{ product.price - product.price * (product.discount / 100) }} TRY
                                                  <small class="dis-price"> {{ product.price }} TRY</small>
                                             </h5>
                                        </div>
                                        <div class="col-7" style="text-align: end">
                                             <a
                                                  (click)="addingAllProducts(i)"
                                                  style="
                                                       text-align: right;
                                                       background: green;
                                                       color: white;
                                                       border: green;
                                                  "
                                                  *ngIf="product.stock > 0 && !product.addingCart"
                                                  class="btn btn-halloum2 btn-sm poppins"
                                                  >Add +</a
                                             >
                                             <div *ngIf="product.addingCart">
                                                  <div class="stepper-input stepper__input">
                                                       <button class="toggle-less" (click)="toggleLessAllProducts(i)">
                                                            <i class="fas fa-minus"></i>
                                                       </button>
                                                       <div
                                                            class="stepper-input-field"
                                                            name="range-value"
                                                            aria-atomic="true"
                                                            aria-live="polite"
                                                            aria-relevant="all">
                                                            {{ product.counter }}
                                                       </div>
                                                       <button
                                                            class="toggle-more"
                                                            (click)="toggleMoreAllProducts(i)"
                                                            *ngIf="product.stock > product.counter">
                                                            <i class="fas fa-plus"></i>
                                                       </button>
                                                       <button
                                                            class="toggle-more"
                                                            *ngIf="product.stock <= product.counter"
                                                            [disabled]="true">
                                                            <i class="fas fa-plus"></i>
                                                       </button>
                                                  </div>
                                             </div>
                                             <a
                                                  *ngIf="product.stock == 0 || product.stock == null || !product.stock"
                                                  class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                                  >Out Of Stock</a
                                             >
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="col-lg-2 col-md-5 center truncate" *ngIf="loadMore">
                    <a
                         class="btn btn-halloum2 btn-block btn-sm poppins mt-2"
                         style="
                              text-align: center;
                              background: green;
                              color: white;
                              border: green;
                              font-size: 15px !important;
                         "
                         routerLink="/products/ALL">
                         Show All Products
                    </a>
               </div>
          </div>
     </div>

     <div class="gallery" style="margin-top: 5%" (deferLoad)="showMyElement = true">
          <div class="gallery-section" *ngIf="showMyElement">
               <div class="gallery-item" *ngFor="let image of gallery | slice : 0 : 8">
                    <a routerLink="/gallery">
                         <img src="{{ image.src }}" class="gallery-image" />
                    </a>
               </div>
          </div>
     </div>
</div>

<!-- spinner -->

<div class="container col-12" *ngIf="!spinner">
     <div class="ph-item">
          <div class="ph-col-12">
               <div style="height: 500px" class="ph-picture" width="100%"></div>
          </div>
     </div>
     <div class="ph-item">
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
     </div>
     <div class="ph-item">
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
          <div class="ph-col-3">
               <div style="height: 225px; width: 225" class="ph-picture"></div>
               <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
               </div>
          </div>
     </div>
</div>

<app-footer></app-footer>
