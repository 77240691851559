import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SessionStorageService } from '../services/session-storage.service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {

  myControl = new FormControl('');
  products: any[] = [];
  filteredProducts: Observable<string[]>;
  timeout: any = null;

  constructor(
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.sessionStorageService.getItem('products').subscribe((res: any) => {
      this.products = res;
    })
  }

  searcheFun(query) {
    if (query != '') {
      this.filteredProducts = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? query : value?.name;
          return name ? this._filter(name as string) : this.products.slice();
        })
      );
    }
    else {
      this.filteredProducts = this.myControl.valueChanges.pipe(
        startWith(''), map(asd => {
          return []
        })
      );
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.products.filter(option => option.query.toLowerCase().includes(filterValue));
  }


  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 250);
  }

  private executeListing(value: string) {
    if (this.products) {
      this.searcheFun(value)
    }
    else {
      this.sessionStorageService.getItem('products').subscribe((res: any) => {
        this.products = res;
      })
    }
  }
}
