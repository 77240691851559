import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FacebookService, InitParams } from "ngx-facebook";
import { Globals } from "../global";

@Component({
     selector: "app-fab",
     templateUrl: "./fab.component.html",
     styleUrls: ["./fab.component.scss"],
     // animations: speedDialFabAnimations
})
export class FabComponent implements OnInit {
     panelOpenState = false;
     mdScreen: boolean = false;

     fabButtons = [
          {
               icon: "fa-whatsapp",
               link: "",
          },
          {
               icon: "fa-facebook",
               link: "",
          },
          {
               icon: "fa-instagram",
               link: "",
          },
     ];
     buttons = [];
     fabTogglerState = "inactive";
     HDimages;

     constructor(
          private facebookService: FacebookService,
          public globals: Globals,
          @Inject(DOCUMENT) private _document: Document
     ) {
          if (window.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }

     @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
          if (!this.mdScreen) {
               var currentScrollPos = window.pageYOffset;
               if (currentScrollPos > 150) {
                    document.getElementById("fabButton").classList.replace("hide-fab", "display-fab");
               } else if (currentScrollPos <= 150) {
                    document.getElementById("fabButton").classList.replace("display-fab", "hide-fab");
               }
          }
     }

     @HostListener("window:resize", ["$event"])
     onResize(event) {
          if (event.target.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }

     ngOnInit(): void {
          this.initFacebookService();
          if (this.globals.imagesQuality == "high") {
               this.HDimages = true;
          } else if (this.globals.imagesQuality == "low") {
               this.HDimages = false;
          }
     }

     private initFacebookService(): void {
          const initParams: InitParams = { xfbml: true, version: "v3.2" };
          this.facebookService.init(initParams);
     }

     showItems() {
          this.fabTogglerState = "active";
          this.buttons = this.fabButtons;
     }

     hideItems() {
          this.fabTogglerState = "inactive";
          this.buttons = [];
     }

     onToggleFab() {
          this.buttons.length ? this.hideItems() : this.showItems();
     }

     scrollToTop() {
          (function smoothscroll() {
               var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
               if (currentScroll > 0) {
                    window.requestAnimationFrame(smoothscroll);
                    window.scrollTo(0, currentScroll - currentScroll / 8);
               }
          })();
     }

     refreshPage() {
          this._document.defaultView.location.reload();
     }

     onClick() {
          if (this.HDimages) {
               localStorage.setItem("imagesQuality", "low");
               setTimeout(() => {
                    this.refreshPage();
               }, 500);
          } else {
               localStorage.setItem("imagesQuality", "high");
               setTimeout(() => {
                    this.refreshPage();
               }, 500);
          }
     }
}
