<app-header></app-header>
<div *ngIf="spinner">
     <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
          <mdb-carousel-item>
               <div class="view w-100" *ngIf="contact">
                    <img
                         style="height: 300px; object-fit: cover"
                         class="d-block w-100 img-sm"
                         src="{{ contact.image }}"
                         width="100%"
                         alt="First slide"
                    />
               </div>
          </mdb-carousel-item>
     </mdb-carousel>
     <main class="main">
          <div class="container">
               <div *ngIf="contact">
                    <div class="row">
                         <div class="col-md-5">
                              <h3 class="h3-responsive poppins">Opening Hours</h3>
                              <ul style="padding-top: 20px" class="ltx-hours mt-2">
                                   <li>
                                        <strong> Monday: </strong>
                                        <strong>{{ contact.monday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Tuesday: </strong>
                                        <strong>{{ contact.tuseday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Wednesday: </strong>
                                        <strong>{{ contact.wednesday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Thursday: </strong>
                                        <strong>{{ contact.thursday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Friday: </strong>
                                        <strong>{{ contact.friday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Saturday: </strong>
                                        <strong>{{ contact.saturday }}</strong>
                                   </li>
                                   <li>
                                        <strong> Sunday: </strong>
                                        <strong>{{ contact.sunday }}</strong>
                                   </li>
                              </ul>
                         </div>
                         <div class="col-md-7">
                              <h3 class="h3-responsive poppins">Leave us a Message</h3>
                              <form
                                   class="contact-form mt-4"
                                   novalidate
                                   [formGroup]="feedbackForm"
                                   #fform="ngForm"
                                   (ngSubmit)="onSubmit()"
                              >
                                   <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                             <mat-form-field appearance="outline" color="primary">
                                                  <mat-label>Your name</mat-label>
                                                  <input
                                                       matInput
                                                       formControlName="name"
                                                       type="text"
                                                       placeholder="Name & Surname"
                                                       required
                                                  />
                                                  <mat-error *ngIf="formErrors.name">
                                                       {{ formErrors.name }}
                                                  </mat-error>
                                             </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                             <mat-form-field appearance="outline" color="primary">
                                                  <mat-label>Your Email</mat-label>
                                                  <input
                                                       matInput
                                                       formControlName="email"
                                                       type="email"
                                                       placeholder="Email Address"
                                                       required
                                                  />
                                                  <mat-error *ngIf="formErrors.email">
                                                       {{ formErrors.email }}
                                                  </mat-error>
                                             </mat-form-field>
                                        </div>
                                        <div class="col-md-12">
                                             <mat-form-field appearance="outline" color="primary">
                                                  <mat-label>Subject</mat-label>
                                                  <input
                                                       matInput
                                                       formControlName="subject"
                                                       type="text"
                                                       placeholder="Subject of your message"
                                                       required
                                                  />
                                                  <mat-error *ngIf="formErrors.subject">
                                                       {{ formErrors.subject }}
                                                  </mat-error>
                                             </mat-form-field>
                                        </div>
                                        <div class="col-md-12">
                                             <mat-form-field appearance="outline" color="primary">
                                                  <mat-label>Your message</mat-label>
                                                  <textarea
                                                       matInput
                                                       formControlName="message"
                                                       placeholder="Tell us what is your message and let us know your thoughts."
                                                       rows="3"
                                                  ></textarea>
                                                  <mat-hint align="end"
                                                       >{{ feedbackForm.value.message.length }}/2000</mat-hint
                                                  >
                                                  <mat-error *ngIf="formErrors.message">
                                                       {{ formErrors.message }}
                                                  </mat-error>
                                             </mat-form-field>
                                        </div>
                                   </div>
                                   <div class="col-lg-6 col-md-8">
                                        <button
                                             class="btn btn-primary btn-round btn-block btn-halloum"
                                             [disabled]="feedbackForm.invalid"
                                             mdbWavesEffect
                                             type="submit"
                                        >
                                             Send Message
                                        </button>
                                   </div>
                              </form>
                         </div>
                    </div>
                    <div class="col-md-12 center">
                         <h3 class="h3-responsive poppins mt-4">Contact us via</h3>

                         <div style="padding-top: 5%" class="container">
                              <div class="row">
                                   <div class="col-3">
                                        <i class="fa fa-phone"></i>
                                        <a
                                             href="tel:{{ contact.phoneLinkGaziantep }}"
                                             placement="top"
                                             mdbTooltip="{{ contact.phoneHolderGaziantep }}"
                                             >{{ contact.phoneGaziantep }}</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fa fa-phone"></i>
                                        <a
                                             href="tel:{{ contact.phoneLinkMersin }}"
                                             placement="top"
                                             mdbTooltip="{{ contact.phoneHolderMersin }}"
                                             >{{ contact.phoneMersin }}</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fab fa-whatsapp"></i>
                                        <a
                                             href="https://wa.me/{{ contact.whatsAppNumber }}/?text={{
                                                  contact.whatsAppLinkEN
                                             }}"
                                             target="_blank"
                                             placement="top"
                                             mdbTooltip="{{ contact.whatsAppHolder }}"
                                             >{{ contact.whatsApp }}</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fa fa-phone"></i>
                                        <a
                                             href="tel:{{ contact.phoneLinkMersin }}"
                                             placement="top"
                                             mdbTooltip="{{ contact.phoneHolderMersin }}"
                                             >{{ contact.phoneMersin }}</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fas fa-at"></i>
                                        <a
                                             href="mailto:info@halloumoglu.com"
                                             placement="top"
                                             mdbTooltip="Click to mail us"
                                             >info@halloumoglu.com</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fas fa-envelope"></i>
                                        <a
                                             href="mailto:halloum.taze@gmail.com"
                                             placement="top"
                                             mdbTooltip="Click to mail us at Gmail"
                                             >halloum.taze@gmail.com</a
                                        >
                                   </div>
                                   <div class="col-3">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <a
                                             href="{{ contact.gaziantepMap }}"
                                             placement="top"
                                             mdbTooltip="Gaziantep branch on Google maps"
                                             target="_blank"
                                        >
                                             Türkiye - Gaziantep
                                        </a>
                                   </div>
                                   <div class="col-3">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <a
                                             href="{{ contact.mersinMap }}"
                                             placement="top"
                                             mdbTooltip="Mersin branch on Google maps"
                                             target="_blank"
                                        >
                                             Türkiye - Mersin
                                        </a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </main>
</div>
<div *ngIf="!spinner">
     <div class="view w-100 ph-item">
          <div class="ph-col-12">
               <div style="height: 600px" class="ph-picture" width="100%"></div>
          </div>
     </div>
</div>
<app-footer></app-footer>
