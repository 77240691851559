import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { UserAgreementComponent } from "./user-agreement/user-agreement.component";
import { ContactComponent } from "./contact/contact.component";
import { BranchesComponent } from "./branches/branches.component";
import { ProductsComponent } from "./products/products.component";
import { ProductComponent } from "./product/product.component";
import { CareersComponent } from "./careers/careers.component";
import { TeamComponent } from "./team/team.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { ProductOneComponent } from "./product-one/product-one.component";

import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { AuthComponent } from "./auth/auth.component";
import { ProfileComponent } from "./profile/profile.component";
import { CartComponent } from "./cart/cart.component";
import { MyOrdersComponent } from "./my-orders/my-orders.component";
import { WinWinComponent } from "./win-win/win-win.component";
import { MyAddressesComponent } from "./my-addresses/my-addresses.component";
import { PaymentComponent } from "./payment/payment.component";
import { AuthGuard } from "./guard/auth.guard";
import { BlogComponent } from "./blog/blog.component";
import { VisionMissionComponent } from "./vision-mission/vision-mission.component";
import { CategoriesComponent } from "./categories/categories.component";

const routes: Routes = [
     { path: "home", redirectTo: "", pathMatch: "full" },
     { path: "", component: HomeComponent },
     { path: "about", component: AboutComponent },
     { path: "contact", component: ContactComponent },
     { path: "careers", component: CareersComponent },
     { path: "our-team", component: TeamComponent },
     { path: "gallery", component: GalleryComponent },
     { path: "branches", component: BranchesComponent },
     { path: "products/:name", component: ProductsComponent },
     { path: "product/:id", component: ProductComponent },

     { path: "blog", component: BlogComponent },
     { path: "vision-mission", component: VisionMissionComponent },

     { path: "privacy-policy", component: PrivacyPolicyComponent },
     { path: "membership-agreemant", component: UserAgreementComponent },

     { path: "sign-in", component: SignInComponent },
     { path: "sign-up", component: SignUpComponent },
     { path: "forgot-password", component: ForgetPasswordComponent },
     { path: "auth", component: AuthComponent },
     { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
     { path: "cart", component: CartComponent, canActivate: [AuthGuard] },
     { path: "payment", component: PaymentComponent, canActivate: [AuthGuard] },
     { path: "categories", component: CategoriesComponent },
     { path: "product-one", component: ProductOneComponent },
     {
          path: "loyalty-program",
          component: WinWinComponent,
          canActivate: [AuthGuard],
     },
     { path: "my-orders", component: MyOrdersComponent, canActivate: [AuthGuard] },
     {
          path: "my-addresses",
          component: MyAddressesComponent,
          canActivate: [AuthGuard],
     },

     { path: "**", redirectTo: "" },
];

@NgModule({
     imports: [RouterModule.forRoot(routes)],
     exports: [RouterModule],
})
export class AppRoutingModule {}
