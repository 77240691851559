import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss']
})
export class ProductTableComponent implements OnInit {

  @Input() parent_specifications: any[];

  specifications: any[] = [];

  displayedColumns: string[] = ['position', 'property', 'value'];
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    var position = 0;
    for (let i in this.parent_specifications) {
      position++;
      this.parent_specifications[i].position = position;
      this.specifications.push(this.parent_specifications[i])
    }
    this.dataSource = new MatTableDataSource<any>(this.specifications);
    this.dataSource.paginator = this.paginator;
  }

}
