<app-header></app-header>
<main class="main">
     <div class="container-fluid">
          <div class="col-xl-11 col-md-12 center">
               <!-- <div class="col-md-8">
            <nav aria-label="breadcrumb" style="background-color: #fff">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                     <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                  </li>
                  <li class="breadcrumb-item active poppins" aria-current="page">
                     <i class="fas fa-gift"></i>
                     Loyalty Program
                  </li>
               </ol>
            </nav>
         </div> -->
               <div class="row mb-4">
                    <div class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
                         <app-navigator-list></app-navigator-list>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2">
                         <div class="card card-body">
                              <img
                                   src="assets/winwin.png"
                                   class="center mb-4"
                                   style="width: 25%"
                                   alt="win win loyalty program"
                              />
                              <div class="m-3 text-center poppins" style="font-size: 4rem; color: #000">
                                   <p class="text-center" style="font-family: 'Cabin Sketch', cursive">Coming Soon</p>
                                   <p class="text-center" style="font-family: 'Cabin Sketch', cursive">Stay Tuned...</p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</main>
<app-footer></app-footer>
