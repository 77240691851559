import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(public db: AngularFirestore) { }

  add_document(collection, value) {
    return this.db.collection(collection).add(value);
  }

  get_collection(collection) {
    return this.db.collection(collection).snapshotChanges();
  }

  get_document(collection, id) {
    return this.db.collection(collection).doc(id).snapshotChanges();
  }

  update_user(collection, id, value) {
    return this.db.collection(collection).doc(id).set(value, {
      merge: true
    });
  }

}
