<app-header></app-header>
<mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
     <mdb-carousel-item>
          <div class="view w-100" *ngIf="content">
               <img class="d-block w-100 img-sm" src="{{ content.englishImage }}" width="100%" alt="First slide" />
          </div>
     </mdb-carousel-item>
</mdb-carousel>
<main class="main">
     <div class="container">
          <!-- <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page"><i class="fas fa-eye"></i> Vision & Mission</li>
                    </ol>
               </nav>
          </div> -->
          <div class="blog-card shadow">
               <div class="meta">
                    <div class="photo" [ngStyle]="{ 'background-image': 'url(' + image1 + ')' }"></div>
               </div>
               <div class="description">
                    <h1>Our Vision</h1>
                    <h2>Opening a door to the future</h2>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
               </div>
          </div>
          <div class="blog-card alt shadow">
               <div class="meta">
                    <div class="photo" [ngStyle]="{ 'background-image': 'url(' + image2 + ')' }"></div>
               </div>
               <div class="description">
                    <h1>Our Mission</h1>
                    <h2>Java is not the same as JavaScript</h2>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati
                         enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
                    </p>
               </div>
          </div>
     </div>
</main>
<app-footer></app-footer>
