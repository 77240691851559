import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT } from "ngx-lightbox";
import { Subscription } from "rxjs";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { Globals } from "../global";

@Component({
     selector: "app-gallery",
     templateUrl: "./gallery.component.html",
     styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
     private _subscription: Subscription;

     _albums: any[] = [];

     HDquality: boolean = false;
     constructor(
          private _lightbox: Lightbox,
          private router: Router,
          public globals: Globals,
          private _lightboxEvent: LightboxEvent,
          private _lightboxConfig: LightboxConfig,
          public db: AngularFirestore,
          private title: Title,
          private sanitizer: DomSanitizer
     ) {
          this.title.setTitle("Halloumoğlu - Gallery");
     }

     images: any[] = [];
     video1: any;
     video2: any;
     video3: any;
     video4: any;
     video5: any;

     ngOnInit(): void {
          this.router.events.subscribe((evt) => {
               if (!(evt instanceof NavigationEnd)) {
                    return;
               }
               window.scrollTo(0, 0);
          });

          this._lightboxConfig.alwaysShowNavOnTouchDevices = true;
          this._lightboxConfig.showRotate = false;
          this._lightboxConfig.showZoom = true;
          this._lightboxConfig.disableScrolling = true;

          this.db.firestore
               .collection("gallery")
               .doc("video")
               .get()
               .then((documentSnapshots: any) => {
                    this.video1 = this.getSafeUrl(documentSnapshots.data().id1);
                    this.video2 = this.getSafeUrl(documentSnapshots.data().id2);
                    this.video3 = this.getSafeUrl(documentSnapshots.data().id3 + "?autoplay=1");
                    this.video4 = this.getSafeUrl(documentSnapshots.data().id4);
                    this.video5 = this.getSafeUrl(documentSnapshots.data().id5);
               });

          var first = this.db.firestore.collection("gallery").doc("images");
          first.get().then((documentSnapshots: any) => {
               if (this.globals.imagesQuality == "low") {
                    this._albums = documentSnapshots.data().images;
                    this.images = this.chunkArray(this._albums);
               } else if (this.globals.imagesQuality == "high") {
                    this._albums = documentSnapshots.data().HDimages;
                    this.images = this.chunkArray(this._albums);
               }
          });
     }

     getSafeUrl(url) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }

     open(column: number, index: number): void {
          this._lightbox.open(this.images[column], index);
          this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) => this._onReceivedEvent(event));
     }

     close(): void {
          this._lightbox.close();
     }

     private _onReceivedEvent(event: any): void {
          if (event.id === LIGHTBOX_EVENT.CLOSE) {
               console.log("CLOSED");
               this._subscription.unsubscribe();
          }

          if (event.id === LIGHTBOX_EVENT.OPEN) {
               console.log("OPEN");
          }

          if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
               console.log(event.data);
          }
     }

     chunkArray(arr) {
          let n = 4;
          var chunkLength = Math.max(arr.length / n, 1);
          var chunks = [];
          for (var i = 0; i < n; i++) {
               if (chunkLength * (i + 1) <= arr.length) {
                    chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
               }
          }
          return chunks;
     }
}
