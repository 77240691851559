<div *ngIf="!mdScreen" class="hide-fab" id="fabButton" (scroll)="onScrollEvent($event)">
     <div style="margin-bottom: 60px" class="fab-container-wa">
          <a
               href="https://wa.me/+905300888034/?text=I+am+looking+for+product+or+service+in+your+website%2C+could+you+please+help+me...%3F&app_absent=0"
               target="_blank">
               <img
                    style="width: 65%"
                    class="fab-image"
                    src="assets/used/whatsapp.png"
                    alt=""
                    [matTooltip]="'Send us a message'"
                    matTooltipPosition="right" />
          </a>
     </div>
     <div style="padding-bottom: 50px" class="fab-container-arw">
          <a (click)="scrollToTop()" [matTooltip]="'Scroll to top'" matTooltipPosition="above">
               <i class="fas fa-level-up-alt text-gold" style="font-size: 35px"></i>
          </a>
     </div>

     <div class="fab-container-setting">
          <mat-expansion-panel [expanded]="panelOpenState">
               <mat-expansion-panel-header>
                    <mat-panel-title>
                         <a [matTooltip]="'Set images quality'" matTooltipPosition="above">
                              <i class="fas fa-cog fa-spin" style="font-size: 25px; color: #fff"></i>
                         </a>
                    </mat-panel-title>
               </mat-expansion-panel-header>
               <div class="sidenav" style="position: relative" dir="ltr">
                    <div class="container-fluid">
                         <div class="mt-4">
                              <div class="row">
                                   <section>
                                        <mat-checkbox
                                             [checked]="HDimages"
                                             (click)="onClick()"
                                             class="example-margin"
                                             style="color: #fff"
                                             [color]="'primary'"
                                             ><b>HD</b>
                                        </mat-checkbox>
                                   </section>
                              </div>
                         </div>
                    </div>
               </div>
          </mat-expansion-panel>
     </div>
</div>
<div *ngIf="mdScreen"></div>
