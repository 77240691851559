<div style="padding: 20px !important;">
    <h1 class="mat-dialog-title poppins text-center">Add Billing Address</h1>
    <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs>
        <mat-tab label="Individual">
            <form class="contact-form mt-1" novalidate [formGroup]="individualForm" #fform="ngForm"
                (ngSubmit)="saveIndividualForm()">
                <div class="mat-dialog-content">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" type="text" placeholder="Your Firstname"
                                    required>
                                <mat-error *ngIf="formErrors.name">
                                    {{ formErrors.name }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Lastname</mat-label>
                                <input matInput formControlName="lastname" type="text" placeholder="Your Lastname"
                                    required>
                                <mat-error *ngIf="formErrors.lastname">
                                    {{ formErrors.lastname }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Identity No</mat-label>
                                <input matInput formControlName="tc" type="text" placeholder="Your Identity No">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="phone" type="tel" placeholder="Ex. 90 531 234 56 78"
                                    pattern="[0-9]*" required>
                                <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                                    {{ formErrors.phone }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="city" type="text" placeholder="Ex. İSTANBUL" required>
                                <mat-error *ngIf="formErrors.city">
                                    {{ formErrors.city }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Country</mat-label>
                                <input matInput formControlName="country" type="text" placeholder="Ex. Turkey" required>
                                <mat-error *ngIf="formErrors.country">
                                    {{ formErrors.country }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Address Title</mat-label>
                                <input matInput formControlName="addressTitle" type="text"
                                    placeholder="Ex. Address Title" required>
                                <mat-error *ngIf="formErrors.addressTitle">
                                    {{ formErrors.addressTitle }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="address" placeholder="Your Address in Detail"
                                    rows="2" required></textarea>
                                <mat-error *ngIf="formErrors.address">
                                    {{ formErrors.address }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
                    <div class="container-fluid">
                        <div class="row poppins" style="color: #fff;">
                            <div class="col-md-6 col-6">
                                <button class="btn btn-success btn-block" type="submit"
                                    [disabled]="individualForm.invalid" cdkFocusInitial>Save</button>
                            </div>
                            <div class="col-md-6 col-6">
                                <button class="btn btn-secondary btn-block" type="button"
                                    (click)="onNoClick()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="Corporate">
            <form class="contact-form mt-1" novalidate [formGroup]="corporateForm" #fform="ngForm"
                (ngSubmit)="saveCorporateForm()">
                <div class="mat-dialog-content">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" type="text" placeholder="Your Firstname"
                                    required>
                                <mat-error *ngIf="formErrors.name">
                                    {{ formErrors.name }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Lastname</mat-label>
                                <input matInput formControlName="lastname" type="text" placeholder="Your Lastname"
                                    required>
                                <mat-error *ngIf="formErrors.lastname">
                                    {{ formErrors.lastname }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>TIN</mat-label>
                                <input matInput formControlName="TIN" type="text"
                                    placeholder="Tax Identification Number" required>
                                <mat-error *ngIf="formErrors.TIN">
                                    {{ formErrors.TIN }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Tax Administration</mat-label>
                                <input matInput formControlName="taxAdministration" type="text"
                                    placeholder="Tax Administration" required>
                                <mat-error *ngIf="formErrors.taxAdministration">
                                    {{ formErrors.taxAdministration }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Company Name</mat-label>
                                <input matInput formControlName="companyName" type="text" placeholder="Company name"
                                    required>
                                <mat-error *ngIf="formErrors.companyName">
                                    {{ formErrors.companyName }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="phone" type="tel" placeholder="Ex. 90 531 234 56 78"
                                    pattern="[0-9]*" required>
                                <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                                    {{ formErrors.phone }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="city" type="text" placeholder="Ex. İSTANBUL" required>
                                <mat-error *ngIf="formErrors.city">
                                    {{ formErrors.city }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Country</mat-label>
                                <input matInput formControlName="country" type="text" placeholder="Ex. Turkey" required>
                                <mat-error *ngIf="formErrors.country">
                                    {{ formErrors.country }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Address Title</mat-label>
                                <input matInput formControlName="addressTitle" type="text"
                                    placeholder="Ex. Address Title" required>
                                <mat-error *ngIf="formErrors.addressTitle">
                                    {{ formErrors.addressTitle }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="address" placeholder="Your Address in Detail"
                                    rows="2" required></textarea>
                                <mat-error *ngIf="formErrors.address">
                                    {{ formErrors.address }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
                    <div class="container-fluid">
                        <div class="row poppins" style="color: #fff;">
                            <div class="col-md-6 col-6">
                                <button class="btn btn-success btn-block" type="submit"
                                    [disabled]="corporateForm.invalid" cdkFocusInitial>Save</button>
                            </div>
                            <div class="col-md-6 col-6">
                                <button class="btn btn-secondary btn-block" type="button"
                                    (click)="onNoClick()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>