import { Component, HostListener, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DomSanitizer, Title } from "@angular/platform-browser";
import SwiperCore, {
     Navigation,
     Pagination,
     Scrollbar,
     A11y,
     Autoplay,
     Thumbs,
     Controller,
     Virtual,
     Zoom,
     EffectCoverflow,
} from "swiper/core";
import { SwiperOptions } from "swiper";
import "swiper/swiper-bundle.css";
import { SidenavService } from "../services/sidenav.service";
import { NavigationEnd, Router } from "@angular/router";
import { Globals } from "../global";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../services/auth.service";
import { SessionStorageService } from "../services/session-storage.service";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Zoom, Autoplay, Thumbs, Controller, EffectCoverflow]);

@Component({
     selector: "app-home",
     templateUrl: "./home.component.html",
     styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
     showResult = false;
     showFiller = false;
     product: any;
     mdScreen: boolean = false;

     adding: boolean = false;
     configProducts: SwiperOptions = {
          height: 300,
          breakpoints: {
               1024: {
                    slidesPerView: 5,
                    spaceBetween: 2,
               },
               768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
               },
               640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
               },
               320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
               },
          },
          navigation: {
               nextEl: ".swiper-button-next",
               prevEl: ".swiper-button-prev",
          },
          pagination: {
               el: ".swiper-pagination",
               type: "bullets",
          },
          autoplay: {
               delay: 3000,
               disableOnInteraction: false,
          },
          autoHeight: true,
          loop: true,
          speed: 500,
          observer: true,
          observeParents: true,
     };

     configCategoris: SwiperOptions = {
          height: 130,
          breakpoints: {
               1024: {
                    slidesPerView: 9,
                    spaceBetween: 2,
               },
               768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
               },
               640: {
                    slidesPerView: 3,
                    spaceBetween: 2,
               },
               320: {
                    slidesPerView: 3,
                    spaceBetween: 2,
               },
          },
          navigation: {
               nextEl: ".swiper-button-next",
               prevEl: ".swiper-button-prev",
          },
          pagination: {
               el: ".swiper-pagination",
               type: "bullets",
          },
          autoplay: {
               delay: 3000,
               disableOnInteraction: false,
          },
          autoHeight: true,
          loop: true,
          speed: 500,
          observer: true,
          observeParents: true,
     };

     categories: any[] = [];
     video: any;
     introduction: any;
     public userInfo: any = undefined;
     quote: any;
     leftImage: any;
     rightImage: any;
     wideImage: any[] = [];
     middleText: any;
     sliders: any;
     gallery = [];
     headerCategory: any[] = [];
     wideVideo: any;

     products: any[] = [];
     allProducts: any[] = [];
     NewProducts: any[] = [];
     BestProducts: any[] = [];
     lastDocument: any;
     loadMore: boolean = true;
     numOfCartProducts: number = 0;
     spinner: boolean = false;
     showMyElement;
     @HostListener("window:resize", ["$event"])
     onResize(event) {
          if (event.target.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }
     constructor(
          public db: AngularFirestore,
          private router: Router,
          private title: Title,
          public globals: Globals,
          private sidenav: SidenavService,
          public authServices: AuthService,
          private sessionStorageService: SessionStorageService,
          public snackBar: MatSnackBar,
          private sanitizer: DomSanitizer
     ) {
          this.title.setTitle("Halloumoğlu - Home");
          if (window.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }

     ngOnInit(): void {
          this.getSliders();

          if (JSON.parse(localStorage.getItem("user")) == null) {
               if (sessionStorage.getItem("cart")) {
                    this.sessionStorageService.getItem("cart").subscribe((res: any) => {
                         this.numOfCartProducts = res.length;
                    });
               }
          } else {
               this.userInfo = JSON.parse(localStorage.getItem("user"));
               this.db
                    .collection("users")
                    .doc(this.userInfo.uid)
                    .snapshotChanges()
                    .subscribe((res: any) => {
                         this.numOfCartProducts = res.payload.data().cart?.length;
                    });
          }

          this.router.events.subscribe((evt) => {
               if (!(evt instanceof NavigationEnd)) {
                    return;
               }
               window.scrollTo(0, 0);
          });

          var video = this.db.firestore.collection("content").doc("video");
          video.get().then((documentSnapshots: any) => {
               this.video = documentSnapshots.data();
          });

          var introduction = this.db.firestore.collection("content").doc("introduction");
          introduction.get().then((documentSnapshots: any) => {
               this.introduction = documentSnapshots.data();
          });

          var middleText = this.db.firestore.collection("content").doc("middleText");
          middleText.get().then((documentSnapshots: any) => {
               this.middleText = documentSnapshots.data();
          });

          var leftImage = this.db.firestore.collection("content").doc("leftImage");
          leftImage.get().then((documentSnapshots: any) => {
               this.leftImage = documentSnapshots.data();
          });

          var rightImage = this.db.firestore.collection("content").doc("rightImage");
          rightImage.get().then((documentSnapshots: any) => {
               this.rightImage = documentSnapshots.data();
          });

          this.getSomeproducts();
     }

     getSliders() {
          var carousel = this.db.firestore.collection("content").doc("carousel");
          carousel.get().then((documentSnapshots: any) => {
               this.sliders = documentSnapshots.data().englishSlider;
               this.getCategories();
          });
     }

     // START NEW PRODUCTS
     toggleMoreNewProducts(i) {
          this.NewProducts[i].counter++;
          this.addToCart(this.NewProducts[i]);
     }

     toggleLessNewProducts(i) {
          if (this.NewProducts[i].counter > 1) {
               this.NewProducts[i].counter--;
               this.addToCart(this.NewProducts[i]);
          }
     }

     addingNewProducts(i) {
          this.NewProducts[i].addingCart = true;
          this.NewProducts[i].counter++;
          this.addToCart(this.NewProducts[i]);
     }
     // END NEW PRODUCTS

     // START BEST SELLING 1 PRODUCTS
     toggleMoreBestProducts(i) {
          this.products[i].counter++;
          this.addToCart(this.products[i]);
     }

     toggleLessBestProducts(i) {
          if (this.products[i].counter > 1) {
               this.products[i].counter--;
               this.addToCart(this.products[i]);
          }
     }

     addingBestProducts(i) {
          this.products[i].addingCart = true;
          this.products[i].counter++;
          this.addToCart(this.products[i]);
     }
     // END BEST SELLING 1 PRODUCTS

     // START ALL PRODUCTS
     toggleMoreAllProducts(i) {
          this.allProducts[i].counter++;
          this.addToCart(this.allProducts[i]);
     }

     toggleLessAllProducts(i) {
          if (this.allProducts[i].counter > 1) {
               this.allProducts[i].counter--;
               this.addToCart(this.allProducts[i]);
          }
     }

     addingAllProducts(i) {
          this.allProducts[i].addingCart = true;
          this.allProducts[i].counter++;
          this.addToCart(this.allProducts[i]);
     }
     // END ALL PRODUCTS

     getCategories() {
          this.db.firestore
               .collection("categories")
               .where("hidden", "==", false)
               .get()
               .then((documentSnapshots: any) => {
                    for (var i in documentSnapshots.docs) {
                         this.headerCategory[i] = documentSnapshots.docs[i].data();
                         this.headerCategory[i].id = documentSnapshots.docs[i].id;
                    }
                    this.getNewProducts();
               });
     }

     getGallery() {
          this.db.firestore
               .collection("gallery")
               .doc("images")
               .get()
               .then((documentSnapshots: any) => {
                    if (this.globals.imagesQuality == "low") {
                         var homeImages = documentSnapshots.data().images;
                         for (let i = 0; i < homeImages.length; i++) {
                              if (homeImages[i].home == true) {
                                   this.gallery.push(homeImages[i]);
                              }
                         }
                    } else if (this.globals.imagesQuality == "high") {
                         var homeImages = documentSnapshots.data().HDimages;
                         for (let i = 0; i < homeImages.length; i++) {
                              if (homeImages[i].home == true) {
                                   this.gallery.push(homeImages[i]);
                              }
                         }
                    }
               });
     }

     get sortData() {
          return this.headerCategory.sort((a, b) => {
               return a.headerSequence - b.headerSequence;
          });
     }

     async addToCart(_product) {
          let cart = [];
          if (this.authServices.isLoggedIn) {
               let uid = JSON.parse(localStorage.getItem("user")).uid;
               await this.db.firestore
                    .collection("users")
                    .doc(uid)
                    .get()
                    .then((res) => {
                         if (res.data().cart) {
                              cart = res.data().cart;
                         }
                    });
               const index = cart.findIndex((item) => {
                    return item.productId === _product.id;
               });
               if (index < 0) {
                    let product = {
                         productId: _product.id,
                         count: _product.counter,
                    };
                    cart.push(product);
               } else {
                    cart[index] = {
                         productId: cart[index].productId,
                         count: _product.counter,
                    };
               }
               await this.db.firestore
                    .collection("users")
                    .doc(uid)
                    .set(
                         {
                              cart: cart,
                         },
                         { merge: true }
                    )
                    .then((res) => {
                         this.openSnackBar("Product added to your cart", "View Cart");
                    });
          } else {
               if (sessionStorage.getItem("cart")) {
                    let storageCart = JSON.parse(sessionStorage.getItem("cart"));
                    const index = storageCart.findIndex((item) => {
                         return item.productId === _product.id;
                    });
                    if (index < 0) {
                         let product = {
                              productId: _product.id,
                              count: 1,
                         };
                         storageCart.push(product);
                    } else {
                         storageCart[index] = {
                              productId: storageCart[index].productId,
                              count: 1 + storageCart[index].count,
                         };
                    }
                    this.sessionStorageService.setItem("cart", storageCart).subscribe((res) => {
                         this.openSnackBar("Product added to your cart", "View Cart");
                    });
               } else {
                    let product = {
                         productId: _product.id,
                         count: 1,
                    };
                    cart.push(product);
                    this.sessionStorageService.setItem("cart", cart).subscribe((res) => {
                         this.openSnackBar("Product added to your cart", "View Cart");
                    });
               }
          }
     }

     getSafeUrl(url) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }

     gotoTop(id) {
          let el = document.getElementById(id);
          el.scrollIntoView({ behavior: "smooth" });
     }

     getBestSelling() {
          this.db.firestore
               .collection("products")
               .where("hidden", "==", false)
               .orderBy("stock", "desc")
               .orderBy("createdAt", "desc")
               .limit(32)
               .get()
               .then((documentSnapshots: any) => {
                    for (var i in documentSnapshots.docs) {
                         this.products[i] = documentSnapshots.docs[i].data();
                         this.products[i].id = documentSnapshots.docs[i].id;
                         this.products[i].addingCart = false;
                         this.products[i].counter = 0;
                    }
                    this.getGallery();
               });
     }

     getwideImage() {
          var wideImage = this.db.firestore.collection("content").doc("wideImage");
          wideImage.get().then((documentSnapshots: any) => {
               this.wideImage = documentSnapshots.data().english;
               this.getBestSelling();
          });
     }

     getNewProducts() {
          // Fetch all new, visible, in-stock product IDs
          this.db.firestore
               .collection("products")
               .where("hidden", "==", false)
               .where("new", "==", true)
               .where("stock", ">", 0)
               .get()
               .then((documentSnapshots: any) => {
                    let allProducts = documentSnapshots.docs.map((doc) => {
                         let product = doc.data();
                         product.id = doc.id;
                         product.addingCart = false;
                         product.counter = 0;
                         return product;
                    });

                    // Shuffle products
                    this.NewProducts = this.shuffleArray(allProducts);

                    this.getwideImage();
                    this.spinner = true;
               });
     }

     shuffleArray(array) {
          for (let i = array.length - 1; i > 0; i--) {
               let j = Math.floor(Math.random() * (i + 1));
               [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
     }

     getSomeproducts() {
          // Fetch all product IDs
          this.db.firestore
               .collection("products")
               .where("hidden", "==", false)
               .where("stock", ">", 0)
               .get()
               .then((documentSnapshots) => {
                    let allIds = documentSnapshots.docs.map((doc) => doc.id);

                    // Generate 30 unique random indexes
                    let randomIndexes = this.getRandomIndexes(allIds.length, 30);

                    // Fetch and store the random products
                    randomIndexes.forEach((index: number, i: number) => {
                         let id = allIds[index];
                         var product = documentSnapshots.docs.find((el) => el.id == id);
                         this.spinner = true;
                         this.allProducts[i] = product.data();
                         this.allProducts[i].id = product.id;
                         this.allProducts[i].addingCart = false;
                         this.allProducts[i].counter = 0;
                    });
               });
     }

     getRandomIndexes(max: number, n: number): number[] {
          let indexes = new Set<number>();

          while (indexes.size < n) {
               indexes.add(Math.floor(Math.random() * max));
          }

          return Array.from(indexes);
     }

     toggleSidenav() {
          this.sidenav.toggle();
     }

     openSnackBar(message, action) {
          let snackBarRef = this.snackBar.open(message, action, {
               duration: 10000,
               horizontalPosition: "center",
               direction: "ltr",
               panelClass: "snackbar",
          });
          snackBarRef.onAction().subscribe(() => {
               this.router.navigate(["cart"]);
          });
     }
}
