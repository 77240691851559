<main class="main">
     <div class="container">
          <!-- <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page">
                              <i class="fas fa-key"></i>
                              Password Reset
                         </li>
                    </ol>
               </nav>
          </div> -->
          <div class="row">
               <div class="col-lg-8 col-md-12 mb-4">
                    <div class="card card-body shadow" style="background-color: #fff; border: none">
                         <h3 class="h3-responsive poppins text-center">Reset Your Password</h3>
                         <h5 class="text-center poppins mt-4" *ngIf="errMess" style="color: red">
                              {{ errMess }}
                         </h5>
                         <h5 class="text-center poppins mt-4" *ngIf="resMess" style="color: #00b74a">
                              {{ resMess }}
                         </h5>
                         <form class="contact-form mt-4" novalidate [formGroup]="resetPassForm" #fform="ngForm">
                              <div class="row">
                                   <div class="col-md-12 mb-2">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Your Email</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="email"
                                                  #userName
                                                  type="email"
                                                  placeholder="Please Enter Your Email Address"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.email">
                                                  {{ formErrors.email }}
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                              </div>
                              <div class="col-md-12">
                                   <button
                                        [hidden]="loading"
                                        class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                                        (click)="reset(userName.value)"
                                        [disabled]="resetPassForm.invalid"
                                        mdbWavesEffect
                                        type="submit"
                                   >
                                        Send
                                   </button>

                                   <button
                                        *ngIf="loading"
                                        class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                                        [disabled]="true"
                                        mdbWavesEffect
                                        type="submit"
                                   >
                                        Sending <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                                   </button>

                                   <div class="row">
                                        <div class="col-md-6 col-12 poppins mb-2">
                                             Or
                                             <a routerLink="/sign-in"> Sign in </a>
                                             with your credentials.
                                        </div>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
               <div class="col-lg-4 col-md-8 center">
                    <div class="card card-body shadow" style="background-color: #fff; border: none">
                         <h3 class="h3-responsive poppins text-center">OR</h3>
                         <hr />
                         <a
                              class="btn shadow mt-4 mb-4"
                              style="background-color: #4285f4; color: #fff"
                              (click)="authService.GoogleAuth(returnUrl)"
                         >
                              <i class="fab fa-google" style="margin-right: 10px; font-size: 19px"></i> Sign In with
                              Google
                         </a>
                         <a
                              class="btn shadow mb-4"
                              style="background-color: #3b5998; color: #fff"
                              (click)="authService.FacebookAuth(returnUrl)"
                         >
                              <i class="fab fa-facebook-square" style="margin-right: 10px; font-size: 20px"></i> Login
                              with Facebook
                         </a>
                         <hr />
                         <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
                    </div>
               </div>
               <div class="col-md-12 center mt-4">
                    <app-follow-us></app-follow-us>
               </div>
          </div>
     </div>
</main>
