<main class="main">
     <div class="container">
          <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page">
                              <i class="fas fa-sign-in-alt"></i>
                              Authentication
                         </li>
                    </ol>
               </nav>
          </div>
          <div class="animated bounceInDown row">
               <div class="col-md-9 center" style="margin-top: 5%" *ngIf="verifyEmail">
                    <div class="card card-body shadow mb-2" style="background-color: #fff; border: none">
                         <div *ngIf="!spinner && status == 'verified'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-check-circle" style="color: #00c851"></i>
                              </p>
                              <h2 class="h3-responsive poppins text-center">We have successfully verified your account</h2>
                              <hr />
                              <p class="text-center mt-4 poppins" style="font-size: 18px">
                                   You will be redirected to <a routerLink="/sign-in">sign in <i class="fas fa-sign-in-alt"></i></a> page in
                                   {{ ellapsedTime }} seconds.
                              </p>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div *ngIf="!spinner && status == 'notVerified'">
                              <p *ngIf="errMessVerify" class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-times-circle" style="color: #f93154"></i>
                              </p>
                              <h2 *ngIf="errMessVerify" style="color: #f93154" class="h3-responsive poppins text-center">
                                   {{ errMessVerify }}
                              </h2>
                              <hr />
                              <p class="text-center mt-4 poppins" style="font-size: 18px">
                                   To resend email verification again, click <a style="color: #1266f1" (click)="onClick()">here</a>
                              </p>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div *ngIf="!spinner && status == 'SignInForm'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-times-circle" style="color: #f93154"></i>
                              </p>
                              <h2 style="color: #f93154" class="h3-responsive poppins text-center">The link has expired</h2>
                              <hr />
                              <form class="text-center p-4" [formGroup]="signInForm" #fform="ngForm" (ngSubmit)="SignIn()">
                                   <h5 class="poppins mb-4">Please enter your account information to resend email verification</h5>
                                   <h5 class="poppins mb-4" *ngIf="errMess" style="color: red">{{ errMess }}</h5>
                                   <mat-form-field class="poppins" appearance="outline" color="primary">
                                        <mat-label>Your Email</mat-label>
                                        <input matInput formControlName="email" type="email" placeholder="Email Address" required />
                                        <mat-error *ngIf="formErrors.email">
                                             {{ formErrors.email }}
                                        </mat-error>
                                   </mat-form-field>

                                   <mat-form-field class="poppins" appearance="outline" color="primary">
                                        <mat-label>Password</mat-label>
                                        <input
                                             matInput
                                             formControlName="password"
                                             #userPassword
                                             type="password"
                                             placeholder="Your Password"
                                             required />
                                        <mat-error *ngIf="formErrors.password">
                                             {{ formErrors.password }}
                                        </mat-error>
                                   </mat-form-field>

                                   <button
                                        [hidden]="loading"
                                        class="btn btn-primary btn-block my-4 poppins"
                                        type="submit"
                                        [disabled]="signInForm.invalid">
                                        Resend email verification
                                   </button>
                                   <div class="row">
                                        <div class="col-md-6 poppins">
                                             Don't have an account?
                                             <a routerLink="/sign-up"> Create one </a>
                                        </div>
                                        <div class="col-md-6 poppins" style="text-align: right">
                                             <a routerLink="/forgot-password" style="color: #005120d3"> Forgot your password? </a>
                                        </div>
                                   </div>
                              </form>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div *ngIf="!spinner && status == 'emailSent'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-check-circle" style="color: #1266f1"></i>
                              </p>
                              <h2 class="h3-responsive poppins text-center">We have sent you the verification email again,</h2>
                              <hr />
                              <p class="text-center mt-4 poppins" style="font-size: 18px">Please check your email.</p>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div class="col-md-12 center mt-4">
                              <app-follow-us></app-follow-us>
                         </div>
                    </div>
               </div>
               <div class="col-md-9 center" style="margin-top: 5%" *ngIf="restPassword">
                    <div class="card card-body shadow mb-2" style="background-color: #fff; border: none">
                         <div *ngIf="!spinner && status == 'passUpdated'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-check-circle" style="color: #00c851"></i>
                              </p>
                              <h2 class="h3-responsive poppins text-center">We have successfully reset your password.</h2>
                              <hr />
                              <p class="text-center mt-4 poppins" style="font-size: 18px">
                                   You will be redirected to <a routerLink="/sign-in">sign in <i class="fas fa-sign-in-alt"></i></a> page in
                                   {{ ellapsedTime }} seconds.
                              </p>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div *ngIf="!spinner && status == 'notVerified'">
                              <p *ngIf="errMessVerify" class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-times-circle" style="color: #f93154"></i>
                              </p>
                              <h2 *ngIf="errMessVerify" style="color: #f93154" class="h3-responsive poppins text-center">
                                   {{ errMessVerify }}
                              </h2>
                              <hr />
                              <p class="text-center mt-4 poppins" style="font-size: 18px">
                                   To re-send password reset email again, click <a routerLink="/forget-password">here</a>
                              </p>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div *ngIf="!spinner && status == 'resetPassForm'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-key" style="color: #000"></i>
                              </p>
                              <h3 style="color: #000" class="h3-responsive poppins text-center">Set a new password for your account</h3>
                              <p class="text-center" style="font-weight: 600">
                                   {{ restPasswordEmail }}
                              </p>
                              <hr />
                              <form class="text-center" [formGroup]="resetPassForm" #fform="ngForm" (ngSubmit)="resetPassword()">
                                   <h5 class="poppins mb-4" *ngIf="errMess" style="color: red">{{ errMess }}</h5>
                                   <mat-form-field class="poppins" appearance="outline" color="primary">
                                        <mat-label>New Password</mat-label>
                                        <input
                                             matInput
                                             [type]="hide ? 'password' : 'text'"
                                             formControlName="newPassword"
                                             type="password"
                                             placeholder="Type the new password"
                                             required />
                                        <button
                                             style="top: -7px"
                                             mat-icon-button
                                             matSuffix
                                             (click)="hide = !hide"
                                             type="button"
                                             [attr.aria-label]="'Hide password'"
                                             [attr.aria-pressed]="hide">
                                             <i *ngIf="hide" class="fas fa-eye-slash"></i>
                                             <i *ngIf="!hide" class="fas fa-eye"></i>
                                        </button>
                                        <mat-error *ngIf="formErrors.newPassword">
                                             {{ formErrors.newPassword }}
                                        </mat-error>
                                   </mat-form-field>
                                   <button
                                        [hidden]="loading"
                                        class="btn btn-primary btn-block my-4 poppins"
                                        type="submit"
                                        [disabled]="resetPassForm.invalid">
                                        Save changes
                                   </button>
                                   <button *ngIf="loading" class="btn btn-primary btn-block my-4 poppins" type="button" disabled>
                                        Saving changes <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                                   </button>
                              </form>
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div class="col-md-12 center mt-4">
                              <app-follow-us></app-follow-us>
                         </div>
                    </div>
               </div>
               <div class="col-md-9 center" style="margin-top: 5%" *ngIf="status == 'invalidURL'">
                    <div class="card card-body shadow mb-2" style="background-color: #fff; border: none">
                         <div *ngIf="!spinner && status == 'invalidURL'">
                              <p class="text-center" style="font-size: 3rem">
                                   <i class="fas fa-exclamation-circle" style="color: #fb3"></i>
                              </p>
                              <h2 class="h3-responsive poppins text-center">Invalid URL!</h2>
                              <br />
                              <p class="poppins mt-4 text-center">
                                   Go to
                                   <a routerLink="/">Home page</a>
                              </p>
                         </div>
                         <div class="col-md-12 center mt-4">
                              <app-follow-us></app-follow-us>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</main>
