import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Globals } from "../global";
import { SidenavService } from "../services/sidenav.service";
import { AuthService } from "../services/auth.service";
import { SessionStorageService } from "../services/session-storage.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
     selector: "app-categories",
     templateUrl: "./categories.component.html",
     styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
     headerCategory: any[] = [];
     categories: any[] = [];
     spinner: boolean = false;

     constructor(
          public db: AngularFirestore,
          private router: Router,
          private title: Title,
          public globals: Globals,
          private sidenav: SidenavService,
          public authServices: AuthService,
          private sessionStorageService: SessionStorageService,
          public snackBar: MatSnackBar,
          private sanitizer: DomSanitizer
     ) {}

     ngOnInit(): void {
          this.getCategories();
          setTimeout(() => {
               this.spinner = true;
          }, 2000);
     }

     get sortData() {
          return this.headerCategory.sort((a, b) => {
               return a.headerSequence - b.headerSequence;
          });
     }

     getCategories() {
          this.db.firestore
               .collection("categories")
               .where("hidden", "==", false)
               .get()
               .then((documentSnapshots: any) => {
                    for (var i in documentSnapshots.docs) {
                         this.headerCategory[i] = documentSnapshots.docs[i].data();
                         this.headerCategory[i].id = documentSnapshots.docs[i].id;
                    }
               });
     }
}
