import { Component, ViewChild, Inject, OnDestroy } from "@angular/core";
import { SidenavService } from "./services/sidenav.service";
import { MatSidenav } from "@angular/material/sidenav";
import { AngularFirestore } from "@angular/fire/firestore";
import { Globals } from "./global";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ModalDirective } from "angular-bootstrap-md";
import { SessionStorageService } from "./services/session-storage.service";
import { Subscription } from "rxjs";

export interface DialogData {
     image: string;
     link: string;
     uploadedAt: string;
}
@Component({
     selector: "app-root",
     templateUrl: "./app.component.html",
     styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
     categories: any[] = [];
     popUp: any;
     shownCategoriesIds: any[] = [];
     products: any[] = [];
     subscription: Subscription;

     @ViewChild("sidenav") public sidenav: MatSidenav;
     @ViewChild("frame") basicModal: ModalDirective;

     constructor(
          public globals: Globals,
          private sidenavService: SidenavService,
          public db: AngularFirestore,
          public dialog: MatDialog,
          private router: Router,
          private sessionStorageService: SessionStorageService
     ) {
          db.firestore
               .collection("categories")
               .where("hidden", "==", false)
               .get()
               .then((documentSnapshots: any) => {
                    for (var i in documentSnapshots.docs) {
                         this.categories[i] = documentSnapshots.docs[i].data();
                         this.categories[i].id = documentSnapshots.docs[i].id;
                         this.shownCategoriesIds.push(this.categories[i].id);
                    }
                    if (sessionStorage.getItem("products")) {
                         if (JSON.parse(sessionStorage.getItem("products")).length <= 0) {
                              this.getAllProducts();
                         } else {
                              console.log("Nothing");
                         }
                    } else {
                         this.getAllProducts();
                    }
               });
          var popUp = this.db.firestore.collection("content").doc("popup");
          popUp.get().then((documentSnapshots: any) => {
               this.popUp = documentSnapshots.data();
               // if (this.router.url == "/") {
               //      this.openDialog();
               // }
          });

          if (localStorage.getItem("imagesQuality")) {
               this.globals.imagesQuality = localStorage.getItem("imagesQuality");
          } else {
               localStorage.setItem("imagesQuality", "low");
          }
     }

     getAllProducts() {
          this.db.firestore
               .collection("content")
               .doc("productsSearchQueryList")
               .get()
               .then((res) => {
                    this.products = res.data().productsSearchQueryList;
                    this.subscription = this.sessionStorageService
                         .setItem("products", this.products)
                         .subscribe((res) => {
                              console.log("Done");
                         });
               });
     }
     name = "Angular";
     ngOnDestroy() {
          this.subscription.unsubscribe();
     }

     ngAfterViewInit(): void {
          this.sidenavService.setSidenav(this.sidenav);
          if (
               localStorage.getItem("cookies") == null ||
               localStorage.getItem("cookies") == undefined ||
               !localStorage.getItem("cookies")
          ) {
               setTimeout(() => {
                    this.basicModal.show();
               }, 5000);
          } else if (localStorage.getItem("cookies")) {
               var date = new Date();
               if (JSON.parse(localStorage.getItem("cookies")).expirationDate < date.toISOString()) {
                    setTimeout(() => {
                         this.basicModal.show();
                    }, 5000);
               }
          }
     }

     acceptCookies() {
          var date = new Date();
          date.setDate(date.getDate() + 1);
          localStorage.setItem(
               "cookies",
               JSON.stringify({
                    accepted: true,
                    expirationDate: date,
               })
          );
     }

     openDialog() {
          const dialogRef = this.dialog.open(DialogContentExampleDialog, {
               width: "500px",
               data: {
                    image: this.popUp.image,
                    link: this.popUp.link,
                    uploadedAt: this.popUp.uploadedAt,
               },
          });
          dialogRef.afterClosed().subscribe((result) => {
               console.log(`Dialog result: ${result}`);
          });
     }
}
@Component({
     selector: "dialog-content-example-dialog",
     templateUrl: "dialog-content.html",
})
export class DialogContentExampleDialog {
     constructor(
          public dialogRef: MatDialogRef<DialogContentExampleDialog>,
          @Inject(MAT_DIALOG_DATA) public data: DialogData
     ) {}

     closeDialog() {
          this.dialogRef.close();
     }
}
