import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { baseURL } from '../shared/baseurl';
import { ProcessHttpmsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class IyzicoService {

  constructor(private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService) { }

  getUserCards(data: any) {
    return this.http.post<any>(baseURL + 'cards/get-card/by-cardUserKey', data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }

  createUserAndCard(data: any) {
    return this.http.post(baseURL + 'cards', data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }

  createCardForAUser(data: any, cardUserKey) {
    return this.http.post(baseURL + 'cards/' + cardUserKey, data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }

  deleteCardByCardToken(data: any) {
    return this.http.post<any>(baseURL + 'cards/delete-card/by-token', data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }

  initialize3DSPayment(data: any, basketId: any) {
    return this.http.post<any>(baseURL + 'threeds/payments/' + basketId + '/initializePayment', data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }

  createPayment(data: any, basketId: any) {
    return this.http.post<any>(baseURL + 'payments/' + basketId, data)
      .pipe(catchError(this.processHTTPMsgService.handlError));
  }
}
