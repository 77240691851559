<app-header></app-header>
<main class="main">
     <div class="container-fluid">
          <div class="col-xl-11 col-md-12 center">
               <!-- <div class="col-md-8">
                <nav aria-label="breadcrumb" style="background-color: #fff;">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="poppins" routerLink="/"><i class="fas fa-home"></i>
                                Home</a>
                        </li>
                        <li class="breadcrumb-item active poppins" aria-current="page"><i
                                class="fas fa-check-circle"></i>
                            Transactions
                        </li>
                    </ol>
                </nav>
            </div> -->
               <div class="row mb-4">
                    <div class="col-xl-2 col-lg-2 col-md-4 mt-2">
                         <app-navigator-list></app-navigator-list>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2" *ngIf="!spinner && payment">
                         <div class="text-center poppins" style="font-size: 1.75rem">
                              <p class="poppins" style="color: #00c851">
                                   Transaction Completed Successfully
                                   <i class="fas fa-check-circle" style="color: #00c851"></i>
                              </p>
                         </div>
                         <div class="alert alert-primary poppins" role="alert" data-mdb-color="primary">
                              <i class="fab fa-envira me-3"></i>
                              Since we always support environmentally friendly tools, a payment receipt will be sent
                              along with the shipped order, or an invoice will be sent to your email. Note: If you do
                              not find your billing email, please check in your email junk/other mails box.
                         </div>
                         <div class="card mt-4 mb-4">
                              <div class="card-header" style="background-color: rgba(0, 0, 0, 0.03) !important">
                                   <div class="row">
                                        <div class="col-lg-3 col-md-6 col-6 text-center poppins">
                                             <p
                                                  style="
                                                       font-size: 13px;
                                                       margin-bottom: 3px !important;
                                                       color: #00000085;
                                                  "
                                             >
                                                  Order date
                                             </p>
                                             <p style="font-size: 13px; margin-bottom: 0px !important; color: #000">
                                                  {{ payment.createdAt | date : "dd/MM/yyyy, h:mm:ss a" }}
                                             </p>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-6 text-center poppins">
                                             <p
                                                  style="
                                                       font-size: 13px;
                                                       margin-bottom: 3px !important;
                                                       color: #00000085;
                                                  "
                                             >
                                                  Buyer
                                             </p>
                                             <p style="font-size: 13px; margin-bottom: 0px !important; color: #000">
                                                  {{ user.name | uppercase }} {{ user.lastname | uppercase }}
                                             </p>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                                             <p
                                                  style="
                                                       font-size: 13px;
                                                       margin-bottom: 3px !important;
                                                       color: #00000085;
                                                  "
                                             >
                                                  Order No
                                             </p>
                                             <p style="font-size: 13px; margin-bottom: 0px !important; color: #000">
                                                  {{ payment.cartId }}
                                             </p>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                                             <p
                                                  style="
                                                       font-size: 13px;
                                                       margin-bottom: 3px !important;
                                                       color: #00000085;
                                                  "
                                             >
                                                  Total
                                             </p>
                                             <p
                                                  class="text-gold"
                                                  style="
                                                       font-size: 15px;
                                                       margin-bottom: 0px !important;
                                                       font-weight: 500;
                                                  "
                                             >
                                                  {{ payment.price | currency : " " + payment.currency + " " }}
                                             </p>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                                             <p
                                                  style="
                                                       font-size: 13px;
                                                       margin-bottom: 3px !important;
                                                       color: #00000085;
                                                  "
                                             >
                                                  Details
                                             </p>
                                             <p style="font-size: 13px; margin-bottom: 0px !important; color: #000">
                                                  {{ payment.itemTransactions.length }} Products
                                             </p>
                                        </div>
                                   </div>
                              </div>
                              <div class="card-body">
                                   <div *ngFor="let product of myOrders">
                                        <div class="row" style="align-items: center">
                                             <div class="col-lg-2 col-md-4 col-6 center">
                                                  <img
                                                       [src]="product.image"
                                                       class="center swiper-image"
                                                       style="border-radius: 4px !important; width: 55%"
                                                  />
                                             </div>
                                             <div class="col-lg-5 col-md-4 col-6 center">
                                                  <span class="text-uppercase text-muted brand">
                                                       {{ product.markaEN | uppercase }}
                                                  </span>
                                                  <a [routerLink]="'/product/' + product.id">
                                                       <h6
                                                            class="text-uppercase mt-1 text-green"
                                                            style="font-size: 13px"
                                                       >
                                                            <b>{{ product.nameEN }}</b>
                                                       </h6>
                                                  </a>
                                             </div>
                                             <div class="col-lg-3 col-md-4 center">
                                                  <div
                                                       class="price d-flex flex-row align-items-center"
                                                       style="font-size: 15px"
                                                  >
                                                       <span class="act-price">
                                                            {{
                                                                 product.price | currency : " " + payment.currency + " "
                                                            }}
                                                            x {{ product.count }} =
                                                            {{
                                                                 product.price * product.count
                                                                      | currency : " " + payment.currency + " "
                                                            }}</span
                                                       >
                                                  </div>
                                             </div>
                                        </div>
                                        <hr style="width: 75%" class="center" />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2" *ngIf="!spinner && !payment && errMess">
                         <div class="card card-body">
                              <h1 class="text-center poppins">
                                   <i class="far fa-frown"></i>
                                   <br />
                                   {{ errMess.status }}
                                   <br />
                                   {{ errMess.errorMessage }}
                                   <br />
                                   <br />
                                   <a routerLink="/">Back to Home Page <i class="fas fa-external-link-alt"></i></a>
                              </h1>
                         </div>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2" *ngIf="spinner">
                         <mat-spinner style="margin-top: 15px" class="center"> </mat-spinner>
                    </div>
               </div>
          </div>
     </div>
</main>
<app-footer></app-footer>
