<main class="main">
     <div class="container">
          <!-- <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page">
                              <i class="fas fa-sign-in-alt"></i>
                              Sign Up
                         </li>
                    </ol>
               </nav>
          </div> -->
          <div class="row" *ngIf="status == 'notDone'">
               <div class="col-lg-8 col-md-12 mb-4">
                    <div class="card card-body shadow" style="background-color: #fff; border: none">
                         <h3 class="h3-responsive poppins text-center">Create a new account</h3>
                         <h5 class="text-center poppins mt-4" *ngIf="errMess" style="color: red; font-weight: 600">
                              {{ errMess }}
                         </h5>
                         <form
                              class="contact-form mt-4"
                              novalidate
                              [formGroup]="signUpForm"
                              #fform="ngForm"
                              (ngSubmit)="onSubmit()"
                         >
                              <div class="row">
                                   <div class="col-md-6">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Name</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="name"
                                                  type="text"
                                                  placeholder="Your Name"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.name">
                                                  {{ formErrors.name }}
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                                   <div class="col-md-6">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Lastname</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="lastname"
                                                  type="text"
                                                  placeholder="Your Lastname"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.lastname">
                                                  {{ formErrors.lastname }}
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                                   <div class="col-md-12">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Email</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="email"
                                                  type="email"
                                                  placeholder="Your Email Address"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.email">
                                                  {{ formErrors.email }}
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                                   <div class="col-md-6">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Password</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="password"
                                                  type="password"
                                                  placeholder="Your Password"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.password">
                                                  {{ formErrors.password }}
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                                   <div class="col-md-6">
                                        <mat-form-field class="poppins" appearance="outline" color="primary">
                                             <mat-label>Confirm Password</mat-label>
                                             <input
                                                  matInput
                                                  formControlName="confirmPass"
                                                  type="password"
                                                  placeholder="Confirm Your Password"
                                                  required
                                             />
                                             <mat-error *ngIf="formErrors.confirmPass">
                                                  Passwords do not match
                                             </mat-error>
                                        </mat-form-field>
                                   </div>
                                   <div class="col-md-12 col-12 mb-2">
                                        <section class="example-section" style="margin-left: 5px">
                                             <mat-checkbox
                                                  formControlName="checkbox"
                                                  color="primary"
                                                  class="example-margin"
                                             >
                                             </mat-checkbox>
                                             <span class="poppins" style="margin-left: 5px">
                                                  I have read and accept the
                                                  <a routerLink="/membership-agreemant" target="_blank"
                                                       >Membership Agreement</a
                                                  >
                                                  and
                                                  <a routerLink="/privacy-policy" target="_blank">Privacy Policy.</a>
                                             </span>
                                        </section>
                                   </div>
                                   <div class="col-md-12 col-12 mb-2">
                                        <section class="example-section" style="margin-left: 5px">
                                             <mat-checkbox
                                                  formControlName="emailSubscription"
                                                  color="primary"
                                                  class="example-margin poppins"
                                             ></mat-checkbox>
                                             <span class="poppins" style="margin-left: 5px">
                                                  Get our latest offers & discounts by emails.
                                             </span>
                                        </section>
                                   </div>
                              </div>
                              <div class="col-md-12">
                                   <button
                                        [hidden]="loading"
                                        class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                                        [disabled]="!signUpForm.value.checkbox || signUpForm.invalid"
                                        mdbWavesEffect
                                        type="submit"
                                   >
                                        Create account
                                   </button>

                                   <button
                                        *ngIf="loading"
                                        class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                                        [disabled]="true"
                                        mdbWavesEffect
                                        type="submit"
                                   >
                                        Creating your account
                                        <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                                   </button>
                                   <div class="poppins">
                                        Already have an account?
                                        <a routerLink="/sign-in"> Sign in </a>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
               <div class="col-lg-4 col-md-8 center">
                    <div class="card card-body shadow" style="background-color: #fff; border: none">
                         <h3 class="h3-responsive poppins text-center">OR</h3>
                         <hr />
                         <a
                              class="btn shadow mt-4 mb-4"
                              style="background-color: #4285f4; color: #fff"
                              (click)="authService.GoogleAuth(returnUrl)"
                         >
                              <i class="fab fa-google" style="margin-right: 10px; font-size: 19px"></i> Sign Up with
                              Google
                         </a>
                         <a
                              class="btn shadow mb-4"
                              style="background-color: #3b5998; color: #fff"
                              (click)="authService.FacebookAuth(returnUrl)"
                         >
                              <i class="fab fa-facebook-square" style="margin-right: 10px; font-size: 20px"></i> Sign Up
                              with Facebook
                         </a>
                         <hr />
                         <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
                    </div>
               </div>
               <div class="col-md-12 center">
                    <app-follow-us></app-follow-us>
               </div>
          </div>
          <div class="animated bounceInDown row" *ngIf="status == 'done'">
               <div class="col-md-9 center" style="margin-top: 10%">
                    <div class="card card-body shadow mb-2" style="background-color: #fff; border: none">
                         <h3 class="h3-responsive poppins text-center">
                              <i class="fas fa-check-circle" style="color: #00c851"></i> A verification link has been
                              sent to your email account
                         </h3>
                         <hr />
                         <p class="poppins">
                              Please click on the link that has just been sent to your email account to verify your
                              email and continue the registration process.
                         </p>
                         <p class="poppins mt-4" style="margin-left: 5px">
                              Go to
                              <a routerLink="/">Home page</a>
                         </p>
                    </div>
               </div>
          </div>
     </div>
</main>
