<style>
     .close {
          padding: 1rem 1rem;
          position: absolute;
          background-color: transparent;
          border: 0;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          opacity: 0.5;
     }
</style>
<a class="close" (click)="closeDialog()">
     <i class="far fa-times-circle"></i>
</a>
<a href="{{data.link}}">
     <img width="100%" src="{{data.image}}" style="border-top-left-radius: 4px; border-top-right-radius: 4px" />
</a>
<div class="card card-body" style="border-top-left-radius: 0rem; border-top-right-radius: 0rem">
     <p style="font-size: 13px">Select The Language:</p>
     <div class="row">
          <div class="col-md-3 center">
               <div class="card card-body text-center p-2 mb-1" style="border: 2px solid #ffc10e !important">
                    <a style="color: #000; font-size: 11px" href="https://en.halloumoglu.com/"> ENGLISH </a>
               </div>
          </div>
          <div class="col-md-3 center">
               <div class="card card-body text-center p-2 mb-1" style="border: 2px solid #ffc10e !important">
                    <a style="color: #000; font-size: 11px" href="https://tr.halloumoglu.com/"> TÜRKÇE </a>
               </div>
          </div>
          <div class="col-md-3 center">
               <div class="card card-body text-center p-2 mb-1" style="border: 2px solid #ffc10e !important">
                    <a class="arabic" style="color: #000; font-size: 11px" href="https://ar.halloumoglu.com/">
                         العربية
                    </a>
               </div>
          </div>
     </div>
</div>
