<app-header></app-header>
<div *ngIf="spinner">
     <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
          <mdb-carousel-item>
               <div class="view w-100" *ngIf="team">
                    <img
                         style="height: 300px; object-fit: cover"
                         class="d-block w-100 img-sm"
                         src="{{ team.englishImage }}"
                         width="100%"
                         alt="First slide"
                    />
               </div>
          </mdb-carousel-item>
     </mdb-carousel>
     <main class="main">
          <div class="container">
               <!-- <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/" style="color: #007bff"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page">
                              <i class="fas fa-user-friends"></i>
                              Our Team
                         </li>
                    </ol>
               </nav>
          </div> -->
               <div>
                    <h3
                         class="h4-responsive cabin-sketch mb-4"
                         style="text-align: left; color: #005121; text-transform: uppercase"
                    >
                         <span style="font-weight: 900">“HALLOUMOGLU TEAM”</span>..
                    </h3>
                    <div class="row">
                         <div class="col-md-12 mt-4" *ngIf="team">
                              <p>
                                   <span [innerHTML]="team.englishText"></span>
                              </p>
                         </div>
                    </div>
                    <!-- <div class="col-md-8 center">
                         <app-follow-us></app-follow-us>
                    </div> -->
               </div>
          </div>
     </main>
</div>
<div *ngIf="!spinner">
     <div class="view w-100 ph-item">
          <div class="ph-col-12">
               <div style="height: 600px" class="ph-picture" width="100%"></div>
          </div>
     </div>
</div>

<app-footer></app-footer>
