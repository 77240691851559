import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Feedback } from "../shared/feedback";
import { FirebaseService } from "../services/firebase.service";
import { Router, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import {
     MAT_MOMENT_DATE_FORMATS,
     MomentDateAdapter,
     MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AngularFirestore } from "@angular/fire/firestore";

export const MY_FORMATS = {
     parse: {
          dateInput: "D/MM/YYYY",
     },
     display: {
          dateInput: "DD/MM/YYYY",
          monthYearLabel: "MMMM Y",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM Y",
     },
};

@Component({
     selector: "app-careers",
     templateUrl: "./careers.component.html",
     styleUrls: ["./careers.component.scss"],
     providers: [
          { provide: MAT_DATE_LOCALE, useValue: "en" },
          {
               provide: DateAdapter,
               useClass: MomentDateAdapter,
               deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
          },
          { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
     ],
})
export class CareersComponent implements OnInit {
     spinner: boolean = false;
     jobForm: FormGroup;
     feedback: Feedback;
     career: any;

     files: File[] = [];
     file: File;
     opportunities: any[] = [];

     task: AngularFireUploadTask;
     percentage: Observable<number>;
     snapshot: Observable<any>;
     downloadURL: string = null;

     task2: AngularFireUploadTask;
     percentage2: Observable<number>;
     snapshot2: Observable<any>;
     downloadURL2: string = null;

     @ViewChild("fform") jobFormDirective;

     formErrors = {
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          dateBirth: "",
          address: "",
          contactType: "",
          desiredEmployment: "",
          workExperience: "",
          message: "",
     };

     validationMessages = {
          firstname: {
               required: "Firstname is required",
          },
          lastname: {
               required: "Lastname is required",
          },
          phone: {
               required: "Phone Number is required",
               pattern: "Phone must contain only numbers.",
          },
          email: {
               required: "Email is required",
               email: "Email not in valid format.",
          },
          dateBirth: {
               required: "Date of Birth is required",
          },
          address: {
               required: "Address is required",
          },
          contactType: {
               required: "Subject is required",
          },
          desiredEmployment: {
               required: "Subject is required",
          },
          workExperience: {
               required: "Work Experience is required",
          },
          message: {
               required: "Message is required",
          },
     };

     constructor(
          private fb: FormBuilder,
          private firebaseService: FirebaseService,
          private router: Router,
          private title: Title,
          private storage: AngularFireStorage,
          public snackBar: MatSnackBar,
          public db: AngularFirestore
     ) {
          this.title.setTitle("Halloumoğlu - Careers");
     }

     ngOnInit(): void {
          this.router.events.subscribe((evt) => {
               if (!(evt instanceof NavigationEnd)) {
                    return;
               }
               window.scrollTo(0, 0);
          });

          this.db.firestore
               .collection("content")
               .doc("career")
               .get()
               .then((documentSnapshots: any) => {
                    this.career = documentSnapshots.data();
               });

          var first = this.db.firestore.collection("opportunities").orderBy("createdAt", "asc");
          first.get().then((documentSnapshots: any) => {
               for (var i in documentSnapshots.docs) {
                    this.opportunities[i] = documentSnapshots.docs[i].data();
                    this.opportunities[i].id = documentSnapshots.docs[i].id;
               }
          });

          this.createForm();
          setTimeout(() => {
               this.spinner = true;
          }, 2000);
     }

     createForm() {
          this.jobForm = this.fb.group({
               firstname: ["", [Validators.required]],
               lastname: ["", [Validators.required]],
               jobName: ["Not Exists", [Validators.required]],
               phone: ["", [Validators.required, Validators.pattern]],
               email: ["", [Validators.required, Validators.email]],
               dateBirth: ["", [Validators.required]],
               address: ["", [Validators.required]],
               contactType: "email",
               desiredEmployment: "fullTime",
               workExperience: ["", [Validators.required]],
               message: ["", [Validators.required, Validators.maxLength(2000)]],
          });

          this.jobForm.valueChanges.subscribe((data) => this.onValueChanged(data));

          this.onValueChanged(); //(re)set form validation messages
     }

     onValueChanged(data?: any) {
          if (!this.jobForm) {
               return;
          }
          const form = this.jobForm;
          for (const field in this.formErrors) {
               if (this.formErrors.hasOwnProperty(field)) {
                    // clear previous erroe message (if any)
                    this.formErrors[field] = "";
                    const control = form.get(field);
                    if (control && control.dirty && !control.valid) {
                         const messages = this.validationMessages[field];
                         for (const key in control.errors) {
                              if (control.errors.hasOwnProperty(key)) {
                                   this.formErrors[field] += messages[key] + " ";
                              }
                         }
                    }
               }
          }
     }

     onDrop(files: FileList) {
          for (let i = 0; i < files.length; i++) {
               this.files.push(files.item(i));
               this.file = this.files[i];
               this.startUpload(this.file);
          }
     }

     onDrop2(files: FileList) {
          for (let i = 0; i < files.length; i++) {
               this.files.push(files.item(i));
               this.file = this.files[i];
               this.startUpload2(this.file);
          }
     }

     startUpload(file) {
          const path = `files/${Date.now()}_${file.name}`;
          const ref = this.storage.ref(path);
          this.task = this.storage.upload(path, file);
          this.percentage = this.task.percentageChanges();
          this.snapshot = this.task.snapshotChanges().pipe(
               tap(console.log),
               finalize(async () => {
                    this.downloadURL = await ref.getDownloadURL().toPromise();
                    this.files = [];
               })
          );
     }

     startUpload2(file) {
          const path = `files/${Date.now()}_${file.name}`;
          const ref = this.storage.ref(path);
          this.task2 = this.storage.upload(path, file);
          this.percentage2 = this.task2.percentageChanges();
          this.snapshot2 = this.task2.snapshotChanges().pipe(
               tap(console.log),
               finalize(async () => {
                    this.downloadURL2 = await ref.getDownloadURL().toPromise();
                    this.files = [];
               })
          );
     }

     onSubmit() {
          var date = new Date().toISOString();
          const formData = {
               ...this.jobForm.value,
               cv: this.downloadURL,
               coverLetter: this.downloadURL2,
               submissionDate: date,
          };
          this.feedback = formData;
          this.firebaseService.add_document("feedback", this.feedback).then((res) => {
               this.openSnackBar("Thank you for your application, we will review it as soon as possible", "");
               setTimeout(() => {
                    this.router.navigate(["/home"]);
               }, 2000);
          });
          this.jobForm.reset({
               firstname: "",
               lastname: "",
               jobName: "",
               dateBirth: "",
               address: "",
               contactType: "",
               desiredEmployment: "",
               workExperience: "",
               phone: "",
               email: "",
               message: "",
          });
          this.jobFormDirective.resetForm();
     }

     openSnackBar(message, action) {
          this.snackBar.open(message, action, {
               duration: 3000,
               horizontalPosition: "left",
               direction: "ltr",
          });
     }
}
