<app-header></app-header>
<main class="main">
     <div class="container" *ngIf="!spinner">
          <!-- <div class="col-md-8">
               <nav aria-label="breadcrumb" style="background-color: #fff">
                    <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page">
                              <i class="fas fa-cubes"></i> {{ category.nameEN }} Products
                         </li>
                    </ol>
               </nav>
          </div> -->
          <div class="row">
               <!-- <div class="col-md-3 mb-2">
                    <div class="card card-body categories-div">
                         <div class="pb-2" *ngFor="let category of categories">
                              <a mat-list-item class="lemonada text-green" style="font-size: 13px" [routerLink]="['/products', category.id]">
                                   <span style="margin-top: 5px">{{ category.nameEN }} Products</span>
                              </a>
                              <hr class="mt-3 mb-3 dropdown-divider" />
                         </div>
                    </div>
               </div> -->
               <div class="col-md-12">
                    <img src="{{ horizontal }}" class="center swiper-imgae mb-4" width="100%" height="300px" />
               </div>
               <div class="col-md-12">
                    <div class="card card-body" style="background-color: #fff">
                         <div class="row">
                              <div class="col-lg-3 col-md-4 col-6 mb-4" *ngFor="let product of products; let i = index">
                                   <div
                                        class="card rounded-8px shadow-sm border-0 item"
                                        matBadge="NEW"
                                        matBadgePosition="after"
                                        [matBadgeHidden]="!product.new"
                                   >
                                        <a [routerLink]="['/product', product.id]">
                                             <img src="{{ product.images[0] }}" class="img-product" />
                                        </a>
                                        <div
                                             class="out-of-stock"
                                             *ngIf="product.stock == 0 || product.stock == null || !product.stock"
                                        >
                                             Out Of Stock
                                        </div>
                                        <div class="card-body" style="padding: 0.75rem">
                                             <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                                  <a
                                                       [routerLink]="['/product', product.id]"
                                                       class="lemonada"
                                                       style="font-size: 15px; color: #333333"
                                                  >
                                                       {{ product.nameEN }}
                                                  </a>
                                             </div>
                                             <div class="row" style="align-items: center">
                                                  <div style="text-align: inherit" class="col-6">
                                                       <h5
                                                            class="act-price"
                                                            style="
                                                                 text-align: inherit;
                                                                 color: #333333;
                                                                 font-size: 15px;
                                                                 margin-bottom: 0px !important;
                                                            "
                                                            *ngIf="product.discountType == 'noDiscount'"
                                                       >
                                                            {{ product.price }} TRY
                                                       </h5>
                                                       <h5
                                                            class="act-price"
                                                            style="
                                                                 text-align: inherit;
                                                                 color: #333333;
                                                                 font-size: 12px;
                                                                 margin-bottom: 0px !important;
                                                            "
                                                            *ngIf="product.discountType == 'discount'"
                                                       >
                                                            {{ product.price - product.discount }} TRY
                                                            <small class="dis-price">
                                                                 {{ product.price }}
                                                                 TRY</small
                                                            >
                                                       </h5>
                                                       <h5
                                                            class="act-price"
                                                            style="
                                                                 text-align: inherit;
                                                                 color: #333333;
                                                                 font-size: 15px;
                                                                 margin-bottom: 0px !important;
                                                            "
                                                            *ngIf="product.discountType == 'percent'"
                                                       >
                                                            {{
                                                                 product.price -
                                                                      product.price * (product.discount / 100)
                                                            }}
                                                            TRY
                                                            <small class="dis-price"> {{ product.price }} TRY</small>
                                                       </h5>
                                                  </div>
                                                  <div style="text-align: end" class="col-6">
                                                       <a
                                                            (click)="addToCart(product.id)"
                                                            style="
                                                                 margin-left: 15px;
                                                                 text-align: right;
                                                                 background: green;
                                                                 color: white;
                                                                 border: green;
                                                            "
                                                            *ngIf="product.stock > 0"
                                                            class="btn btn-halloum2 btn-sm poppins"
                                                            >Add+</a
                                                       >
                                                       <a
                                                            *ngIf="
                                                                 product.stock == 0 ||
                                                                 product.stock == null ||
                                                                 !product.stock
                                                            "
                                                            class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                                            >Out Of Stock</a
                                                       >
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="row" *ngIf="products.length == 0">
                              <div class="col-md-12">
                                   <h3 class="text-center poppins">No Products added yet!</h3>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>

     <!-- spinner -->

     <div class="container" *ngIf="spinner">
          <div class="ph-item">
               <div class="ph-col-12">
                    <div style="height: 270px" class="ph-picture"></div>
               </div>
          </div>
          <div class="ph-item">
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
          </div>
          <div class="ph-item">
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
               <div class="ph-col-3">
                    <div style="height: 225px; width: 225" class="ph-picture"></div>
                    <div class="ph-row">
                         <div class="ph-col-6 big"></div>
                         <div class="ph-col-4 empty big"></div>
                         <div class="ph-col-2 big"></div>
                         <div class="ph-col-4"></div>
                         <div class="ph-col-8 empty"></div>
                         <div class="ph-col-6"></div>
                         <div class="ph-col-6 empty"></div>
                         <div class="ph-col-12"></div>
                    </div>
               </div>
          </div>
     </div>
</main>
<app-footer></app-footer>
