<app-header id="header"></app-header>
<div *ngIf="spinner">
     <div style="padding-top: 5%; padding-bottom: 5%" class="col-8 center">
          <div class="row">
               <div style="text-align: -webkit-center" class="col-md-2" *ngFor="let category of sortData">
                    <a>
                         <img
                              style="border-radius: 2px; height: 125px; text-align: center; object-fit: cover"
                              class="center mt-4"
                              width="100%"
                              [routerLink]="['/products', category.id]"
                              src="{{ category.vertical }}" />
                    </a>
                    <a style="margin-top: 8px; color: black" type="button" [routerLink]="['/products', category.id]">
                         {{ category.nameEN }}
                    </a>
               </div>
          </div>
     </div>
</div>

<div class="container col-12" *ngIf="!spinner">
     <div class="view w-100 ph-item">
          <div class="ph-col-12">
               <div style="height: 600px" class="ph-picture" width="100%"></div>
          </div>
     </div>
</div>
<app-footer></app-footer>
