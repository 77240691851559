import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
     selector: "app-blog",
     templateUrl: "./blog.component.html",
     styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
     about: any;
     spinner: boolean = false;
     constructor(private router: Router, public db: AngularFirestore, private title: Title) {
          this.title.setTitle("Halloumoğlu - Blog");
     }

     ngOnInit(): void {
          this.router.events.subscribe((evt) => {
               if (!(evt instanceof NavigationEnd)) {
                    return;
               }
               window.scrollTo(0, 0);
          });

          var first = this.db.firestore.collection("content").doc("about");
          first.get().then((documentSnapshots: any) => {
               this.about = documentSnapshots.data();
               setTimeout(() => {
                    this.spinner = true;
               }, 2000);
          });
     }
}
