<app-header></app-header>
<div *ngIf="spinner">
     <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
          <mdb-carousel-item>
               <div class="view w-100" *ngIf="about">
                    <img
                         style="height: 300px; object-fit: cover"
                         class="d-block w-100 img-sm"
                         src="{{ about.englishImage }}"
                         width="100%"
                         alt="First slide" />
               </div>
          </mdb-carousel-item>
     </mdb-carousel>
     <main class="main">
          <div class="container">
               <div class="col-md-8">
                    <nav aria-label="breadcrumb" style="background-color: #fff">
                         <!-- <ol class="breadcrumb">
                         <li class="breadcrumb-item">
                              <a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                         </li>
                         <li class="breadcrumb-item active poppins" aria-current="page"><i class="fas fa-blog"></i> Blog</li>
                    </ol> -->
                    </nav>
               </div>
               <div class="row">
                    <div class="col-md-6">
                         <div class="card shadow">
                              <figure class="card__thumb">
                                   <img
                                        src="https://blog.korayspor.com/wp-content/uploads/2023/05/Sok-Diyetler-Sagligi-Bozar-Mi-630x517.jpg"
                                        alt="Picture by Kyle Cottrell"
                                        class="card__image" />
                                   <figcaption class="card__caption">
                                        <h2 class="card__title">NASA Has Found Hundreds Of Potential New Planets</h2>
                                        <p class="card__snippet">
                                             NASA released a list of 219 new “planet candidates” discovered by the
                                             Kepler space telescope, 10 of which are similar to Earth’s size and may be
                                             habitable by other life forms.
                                        </p>
                                        <div class="col-md-6 center">
                                             <a class="btn btn-halloum2 btn-block btn-sm poppins">Read more</a>
                                        </div>
                                   </figcaption>
                              </figure>
                         </div>
                    </div>
                    <div class="col-md-6">
                         <div class="card shadow">
                              <figure class="card__thumb">
                                   <img
                                        src="https://blog.korayspor.com/wp-content/uploads/2023/05/Kadinlara-Ozel-Ust-Vucut-Egzersizleri-630x517.jpg"
                                        alt="Picture by Nathan Dumlao"
                                        class="card__image" />
                                   <figcaption class="card__caption">
                                        <h2 class="card__title">This Is Your Body And Brain On Coffee</h2>
                                        <p class="card__snippet">
                                             Drinking more caffeine during the coronavirus lockdown? Here's how it can
                                             affect you over time and advice on making it better for you.
                                        </p>
                                        <div class="col-md-6 center">
                                             <a class="btn btn-halloum2 btn-block btn-sm poppins">Read more</a>
                                        </div>
                                   </figcaption>
                              </figure>
                         </div>
                    </div>
                    <div class="col-md-6">
                         <div class="card shadow">
                              <figure class="card__thumb">
                                   <img
                                        src="https://blog.korayspor.com/wp-content/uploads/2023/05/Ambalajli-Yiyeceklerden-Neden-Kacinmali-630x517.jpg"
                                        alt="Picture by Daniel Lincoln"
                                        class="card__image" />
                                   <figcaption class="card__caption">
                                        <h2 class="card__title">Why You Should Bring Your Dog To Work</h2>
                                        <p class="card__snippet">
                                             On Friday, offices around the country celebrated the 15th annual Take Your
                                             Dog to Work Day. Though the event's primary goal is to raise awareness for
                                             pet adoption, the unanticipated impact may be a slightly more relaxing work
                                             environment for any office choosing to participate.
                                        </p>
                                        <div class="col-md-6 center">
                                             <a class="btn btn-halloum2 btn-block btn-sm poppins">Read more</a>
                                        </div>
                                   </figcaption>
                              </figure>
                         </div>
                    </div>
               </div>
          </div>
     </main>
</div>

<div class="container col-12" *ngIf="!spinner">
     <div class="view w-100 ph-item">
          <div class="ph-col-12">
               <div style="height: 600px" class="ph-picture" width="100%"></div>
          </div>
     </div>
</div>
<app-footer></app-footer>
