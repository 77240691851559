import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-vision-mission',
  templateUrl: './vision-mission.component.html',
  styleUrls: ['./vision-mission.component.scss']
})
export class VisionMissionComponent implements OnInit {

  content: any;
  image1 = 'https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg';
  image2 = 'https://storage.googleapis.com/chydlx/codepen/blog-cards/image-2.jpg';

  constructor(
    private router: Router,
    public db: AngularFirestore,
    private title: Title) {
    this.title.setTitle("Halloumoğlu - Vision & Mission");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.db.firestore.collection("content").doc("visionMission").get().then((documentSnapshots) => {
      this.content = documentSnapshots.data();
    });
  }

}
