import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-win-win",
  templateUrl: "./win-win.component.html",
  styleUrls: ["./win-win.component.scss"],
})
export class WinWinComponent implements OnInit {
  constructor(
    public db: AngularFirestore,
    public authServices: AuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    private title: Title
  ) {
    this.title.setTitle("Halloumoğlu - Loyalty Program");
  }

  ngOnInit(): void {
    // this.db.firestore.collection("products")
    //     .where('hidden', '==', false)
    //     .where('stock', '>', 0)
    //     .get().then((documentSnapshots) => {
    //         documentSnapshots.docs.forEach((el) => {
    //             if (el.data().stock == 0) {
    //                 console.log(el.data().stock);
    //             }
    //         })
    //     });
  }

  signOut() {
    this.authServices.SignOut();
  }
}
