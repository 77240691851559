<div style="padding: 24px !important;">
    <h1 class="mat-dialog-title poppins text-center">Edit Your Address</h1>
    <form class="contact-form mt-1" novalidate [formGroup]="addressForm" #fform="ngForm" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" [(ngModel)]="data.name" type="text"
                            placeholder="Your Firstname" required>
                        <mat-error *ngIf="formErrors.name">
                            {{ formErrors.name }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Lastname</mat-label>
                        <input matInput formControlName="lastname" [(ngModel)]="data.lastname" type="text"
                            placeholder="Your Lastname" required>
                        <mat-error *ngIf="formErrors.lastname">
                            {{ formErrors.lastname }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone" [(ngModel)]="data.phone" type="tel"
                            placeholder="Ex. 90 531 234 56 78" pattern="[0-9]*" required>
                        <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                            {{ formErrors.phone }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Address Title</mat-label>
                        <input matInput formControlName="addressTitle" [(ngModel)]="data.addressTitle" type="text"
                            placeholder="Ex. My home address" required>
                        <mat-error *ngIf="formErrors.addressTitle">
                            {{ formErrors.addressTitle }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>City</mat-label>
                        <mat-select class="poppins" formControlName="city" [(ngModel)]="data.city">
                            <mat-option class="poppins" *ngFor="let city of citites; let i = index"
                                value="{{city['name']}}" (click)="cityChosen(i)">
                                <span style="margin-right: 10px;">{{i+1 | number:'2.0'}}</span> {{city["name"] |
                                titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>District</mat-label>
                        <mat-select class="poppins" formControlName="district" [(ngModel)]="data.district">
                            <mat-option class="poppins" *ngFor="let district of districts;" value="{{district}}">
                                {{district | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Address</mat-label>
                        <textarea matInput formControlName="address" [(ngModel)]="data.address"
                            placeholder="Your Address in Detail" rows="2" required></textarea>
                        <mat-error *ngIf="formErrors.address">
                            {{ formErrors.address }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
            <div class="container-fluid">
                <div class="row poppins" style="color: #fff;">
                    <div class="col-md-6 col-6">
                        <button class="btn btn-success btn-block" type="submit" [disabled]="addressForm.invalid"
                            cdkFocusInitial>Save</button>
                    </div>
                    <div class="col-md-6 col-6">
                        <button class="btn btn-secondary btn-block" type="button" (click)="onNoClick()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>