<app-header></app-header>

<main class="main">
     <div class="container" *ngIf="!spinner && category">
          <div class="col-md-12"></div>
          <div class="row">
               <div class="col-md-12">
                    <div class="card card-body" style="background-color: #fff">
                         <div class="row">
                              <div class="col-md-6">
                                   <div class="row">
                                        <div class="col-md-3 col-3 small-img-div">
                                             <a *ngFor="let image of images; let i = index" (click)="selectImage(i)">
                                                  <img
                                                       src="{{ image }}"
                                                       class="center small-image mb-2"
                                                       width="100%"
                                                       (click)="open(i)" />
                                             </a>
                                        </div>
                                        <div class="col-md-9 col-9 swiper-container" #container>
                                             <img
                                                  src="{{ selectedImage }}"
                                                  class="center swiper-image mb-4 display-lg"
                                                  width="100%"
                                                  (click)="openWithSrc(selectedImage)"
                                                  style="
                                                       cursor: zoom-in;
                                                       width: 100%;
                                                       transition: all 0.3s;
                                                       position: absolute;
                                                  " />
                                             <img
                                                  src="{{ selectedImage }}"
                                                  class="center swiper-image mb-4 display-sm"
                                                  width="100%"
                                                  (click)="openWithSrc(selectedImage)" />
                                        </div>
                                   </div>
                              </div>

                              <div class="col-md-6">
                                   <div class="product">
                                        <div class="d-flex justify-content-between align-items-center">
                                             <div class="d-flex align-items-center">
                                                  <i class="fa fa-long-arrow-left"></i>
                                             </div>
                                             <mat-slide-toggle
                                                  [color]="'primary'"
                                                  [checked]="HDimages"
                                                  (click)="onClick()"
                                                  >HD
                                             </mat-slide-toggle>
                                        </div>
                                        <div class="mb-3">
                                             <span class="text-uppercase text-muted brand">
                                                  {{ product.markaEN | uppercase }}
                                             </span>
                                             <h5 class="text-uppercase mt-3">
                                                  {{ product.nameEN }}
                                             </h5>
                                             <div
                                                  class="price d-flex flex-row align-items-center"
                                                  *ngIf="product.discountType == 'noDiscount'">
                                                  <span class="act-price"> {{ product.price }} TRY</span>
                                             </div>
                                             <div
                                                  class="price d-flex flex-row align-items-center"
                                                  *ngIf="product.discountType == 'discount'">
                                                  <span class="act-price" style="color: #ff1100 !important">
                                                       {{ product.price - product.discount }} TRY</span
                                                  >
                                                  <div class="ml-2">
                                                       <small class="dis-price"> {{ product.price }} TRY</small>
                                                  </div>
                                             </div>
                                             <div
                                                  class="price d-flex flex-row align-items-center"
                                                  *ngIf="product.discountType == 'percent'">
                                                  <span class="act-price" style="color: #ff1100 !important">
                                                       {{ product.price - product.price * (product.discount / 100) }}
                                                       TRY</span
                                                  >
                                                  <div class="ml-2">
                                                       <small class="dis-price"> {{ product.price }} TRY</small>
                                                       <span> {{ product.discount }}% OFF</span>
                                                  </div>
                                             </div>
                                        </div>
                                        <p class="about">
                                             {{ product.descriptionEN }}
                                        </p>
                                        <div class="mt-4">
                                             <div class="stepper-input stepper__input">
                                                  <button class="toggle-less" (click)="toggleLess()">
                                                       <i class="fas fa-minus"></i>
                                                  </button>
                                                  <div
                                                       class="stepper-input-field"
                                                       name="range-value"
                                                       aria-atomic="true"
                                                       aria-live="polite"
                                                       aria-relevant="all">
                                                       {{ counter }}
                                                  </div>
                                                  <button
                                                       class="toggle-more"
                                                       (click)="toggleMore()"
                                                       *ngIf="product.stock > counter">
                                                       <i class="fas fa-plus"></i>
                                                  </button>
                                                  <button
                                                       class="toggle-more"
                                                       *ngIf="product.stock <= counter"
                                                       [disabled]="true">
                                                       <i class="fas fa-plus"></i>
                                                  </button>
                                             </div>
                                        </div>
                                        <div
                                             class="row mt-4"
                                             *ngIf="
                                                  (category.hidden == false || category.hidden == null) &&
                                                  !(product.stock <= 0 || product.stock == null || !product.stock)
                                             ">
                                             <div class="col-lg-6 col-md-6 col-6 mb-2">
                                                  <div class="cart align-items-center">
                                                       <button
                                                            class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                                                            style="
                                                                 font-size: 12px;
                                                                 background: green;
                                                                 color: white;
                                                                 border: green;
                                                            "
                                                            (click)="addToCart()"
                                                            type="button"
                                                            [disabled]="adding">
                                                            Add to cart
                                                       </button>
                                                  </div>
                                             </div>
                                             <div class="col-lg-6 col-md-6 col-6 mb-2">
                                                  <div class="cart align-items-center">
                                                       <button
                                                            class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                                                            style="
                                                                 font-size: 11px;
                                                                 background: green;
                                                                 color: white;
                                                                 border: green;
                                                            "
                                                            (click)="buyNow()"
                                                            type="button">
                                                            Buy Now
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                        <div
                                             class="row"
                                             *ngIf="
                                                  category.hidden == true ||
                                                  product.stock <= 0 ||
                                                  product.stock == null ||
                                                  !product.stock
                                             ">
                                             <div class="col-lg-6 col-md-12 mt-4 mb-4 center">
                                                  <div class="cart mt-4 align-items-center">
                                                       <button
                                                            class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                                                            style="font-size: 13px"
                                                            type="button"
                                                            [disabled]="true">
                                                            Out of Stock
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                        <a
                                             class="whatsApp-button"
                                             style="color: #fff !important"
                                             [href]="[
                                                  'https://wa.me/+905300888034/?text=I want to order this product, Could you help me! ' +
                                                       productURL
                                             ]"
                                             target="_blank">
                                             <i class="fab fa-whatsapp px-2" style="font-size: 18px"></i>
                                             <span class="pr-4">Order By WhatsApp</span>
                                        </a>
                                   </div>
                                   <div class="col-md-12 mt-4" *ngIf="shippingImage">
                                        <img class="w-100 rounded" src="{{ shippingImage }}" alt="" />
                                   </div>
                              </div>
                              <div class="col-md-12">
                                   <hr class="center" style="width: 75%" />
                                   <span [innerHTML]="product.extraDescriptionEN"></span>
                                   <hr class="center" style="width: 75%" />
                              </div>
                              <div class="col-md-7 mb-4">
                                   <app-product-table
                                        *ngIf="product.specificationsEN.length > 0"
                                        [parent_specifications]="product.specificationsEN">
                                   </app-product-table>
                              </div>
                              <div class="col-md-5">
                                   <div
                                        class="videowrapper center mb-4"
                                        *ngIf="product.youtubeLink"
                                        style="position: relative; bottom: 0; left: 0">
                                        <iframe
                                             [src]="product.youtubeLink"
                                             title="YouTube video player"
                                             frameborder="0"
                                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                             allowfullscreen></iframe>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div class="container" style="margin-top: 5%" *ngIf="category.hidden == false">
               <h3 class="lemonada text-center mb-4 text-green">Similar Products</h3>
               <swiper
                    [autoHeight]="true"
                    [spaceBetween]="50"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [autoplay]="{ delay: 1000, disableOnInteraction: false }"
                    [config]="configProducts">
                    <ng-template swiperSlide *ngFor="let product of products">
                         <div
                              style="padding-left: 5px !important; padding-right: 5px !important"
                              class="col-md-12 mb-4 mt-4">
                              <div
                                   class="card rounded-8px shadow-sm border-0"
                                   matBadge="NEW"
                                   matBadgePosition="after"
                                   [matBadgeHidden]="!product.new">
                                   <a [routerLink]="['/product', product.id]">
                                        <img src="{{ product.images[0] }}" class="img-product" />
                                   </a>
                                   <div
                                        class="out-of-stock"
                                        *ngIf="product.stock <= 0 || product.stock == null || !product.stock">
                                        Out Of Stock
                                   </div>
                                   <div class="card-body" style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  [routerLink]="['/product', product.id]"
                                                  class="text-green lemonada"
                                                  style="font-size: 12px">
                                                  {{ product.nameEN }}
                                             </a>
                                        </div>
                                        <div class="row" style="align-items: center">
                                             <div style="text-align: inherit" class="col-6">
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: inherit;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'noDiscount'">
                                                       {{ product.price }} TRY
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: inherit;
                                                            color: #333333;
                                                            font-size: 12px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'discount'">
                                                       {{ product.price - product.discount }} TRY
                                                       <small class="dis-price">
                                                            {{ product.price }}
                                                            TRY</small
                                                       >
                                                  </h5>
                                                  <h5
                                                       class="act-price"
                                                       style="
                                                            text-align: inherit;
                                                            color: #333333;
                                                            font-size: 15px;
                                                            margin-bottom: 0px !important;
                                                       "
                                                       *ngIf="product.discountType == 'percent'">
                                                       {{ product.price - product.price * (product.discount / 100) }}
                                                       TRY
                                                       <small class="dis-price"> {{ product.price }} TRY</small>
                                                  </h5>
                                             </div>
                                             <div class="col-6">
                                                  <a
                                                       (click)="addToCart2(product.id)"
                                                       *ngIf="product.stock > 0"
                                                       class="btn btn-halloum2 btn-block btn-sm poppins"
                                                       style="background: green; color: white; border: green"
                                                       >Add+</a
                                                  >
                                                  <a
                                                       *ngIf="
                                                            product.stock <= 0 ||
                                                            product.stock == null ||
                                                            !product.stock
                                                       "
                                                       class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                                       >Out Of Stock</a
                                                  >
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
               </swiper>
          </div>
          <div class="container" *ngIf="!product">
               <h1 class="text-center poppins">
                    404
                    <br />
                    <br />
                    Oops! The page you are looking for does not exist.
                    <br />
                    <br />
                    <a routerLink="/">Back to Home Page <i class="fas fa-external-link-alt"></i></a>
               </h1>
          </div>
     </div>

     <!-- spinner -->

     <div class="container" *ngIf="spinner && !category">
          <div class="col-md-12">
               <div class="card card-body" style="background-color: #fff">
                    <div class="row">
                         <div class="col-md-6">
                              <div class="row">
                                   <div class="col-md-3 col-3 small-img-div">
                                        <a>
                                             <img
                                                  style="height: 125px; width: 90px"
                                                  class="center small-image mb-2 ph-picture"
                                                  width="100%" />
                                        </a>
                                        <a>
                                             <img
                                                  style="height: 125px; width: 90px"
                                                  class="center small-image mb-2 ph-picture"
                                                  width="100%" />
                                        </a>
                                        <a>
                                             <img
                                                  style="height: 125px; width: 90px"
                                                  class="center small-image mb-2 ph-picture"
                                                  width="100%" />
                                        </a>
                                        <a>
                                             <img
                                                  style="height: 125px; width: 90px"
                                                  class="center small-image mb-2 ph-picture"
                                                  width="100%" />
                                        </a>
                                   </div>
                                   <div class="col-md-9 col-9 swiper-container" #container>
                                        <img
                                             style="height: 400px"
                                             class="center swiper-image mb-4 display-lg ph-picture" />
                                   </div>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="d-flex justify-content-between align-items-center">
                                   <div class="d-flex align-items-center ph-col-6 big"></div>
                              </div>
                              <div class="ph-col-3">
                                   <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                   </div>
                                   <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                   </div>
                                   <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                   </div>
                                   <div class="ph-row">
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-4 empty big"></div>
                                        <div class="ph-col-2 big"></div>
                                        <div class="ph-col-4"></div>
                                        <div class="ph-col-8 empty"></div>
                                        <div class="ph-col-6"></div>
                                        <div class="ph-col-6 empty"></div>
                                        <div class="ph-col-12"></div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</main>
<app-footer></app-footer>
