<div class="poppins" style="padding: 24px !important;">
    <h1 class="mat-dialog-title poppins text-center">Contracts and Forms</h1>
    <div style="margin-top: 1.5rem;">
        <h5 style="font-size: 15px;">
            DISTANCE SALES AGREEMENT PRELIMINARY INFORMATION FORM
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="preliminaryInformationForm"></span>
        </div>
    </div>

    <div style="margin-top: 2.5rem">
        <h5 style="font-size: 15px;">
            DISTANCE SALES AGREEMENT
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="distanceSalesAgreement"></span>
        </div>
    </div>
</div>