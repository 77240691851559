import { Component, OnInit } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
     selector: "app-about",
     templateUrl: "./about.component.html",
     styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
     about: any;
     spinner: boolean = false;
     wideVideo: any;

     constructor(
          private sanitizer: DomSanitizer,
          private router: Router,
          public db: AngularFirestore,
          private title: Title
     ) {
          this.title.setTitle("Halloumoğlu - About");
     }

     ngOnInit(): void {
          this.getAbout();
          setTimeout(() => {
               this.spinner = true;
          }, 2000);
     }

     getAbout() {
          this.router.events.subscribe((evt) => {
               if (!(evt instanceof NavigationEnd)) {
                    return;
               }
               window.scrollTo(0, 0);
          });
          this.getFirst();
     }

     getSafeUrl(url) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }

     getwideImage() {
          var wideVideo = this.db.firestore.collection("content").doc("wideVideo");
          wideVideo.get().then((documentSnapshots: any) => {
               this.wideVideo = this.getSafeUrl(documentSnapshots.data().video);
          });
     }

     getFirst() {
          var first = this.db.firestore.collection("content").doc("about");
          first.get().then((documentSnapshots: any) => {
               this.about = documentSnapshots.data();
               this.getwideImage();
          });
     }
}
