<app-header></app-header>
<div *ngIf="!showSpinner">
     <main class="main">
          <div class="container-fluid">
               <div class="alert alert-danger" role="alert" *ngIf="!emailVerified">
                    Your account is not verified, we have already sent you an account verification email, please check
                    your E-mail.
                    <a (click)="SendVerificationMess()" class="alert-link alert-a">Click here </a>to send account
                    verification email again.
               </div>
               <div class="alert alert-success" role="alert" *ngIf="verificationSent">
                    We have sent you the verification email again—check your email inbox!
               </div>
               <div class="col-xl-11 col-md-12 center">
                    <div class="row mb-4">
                         <div class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
                              <app-navigator-list></app-navigator-list>
                         </div>
                         <div
                              class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2"
                              *ngIf="cartProducts.length > 0 && !showSpinner">
                              <div class="row">
                                   <div class="col-xl-4 col-lg-4 col-md-12 col-12 display-sm mb-2">
                                        <div class="card card-body" style="padding: 0.65rem !important">
                                             <div class="row mt-1" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Items ({{ cartProducts.length }}):
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price">{{
                                                                 totalBeforeDiscount | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div
                                                  class="row"
                                                  style="align-items: center"
                                                  *ngIf="totalBeforeDiscount != total">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Discounts:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price" style="color: #dc3545">{{
                                                                 totalBeforeDiscount - total | currency : " TRY - "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row mb-1" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Total:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price">{{
                                                                 total | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="font-size: 12px; margin-bottom: 0px">
                                                            Shipping Cost:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 13px">
                                                            <span class="act-price">{{
                                                                 shipping.shippingCost | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div
                                                  class="row mt-2"
                                                  style="align-items: center"
                                                  *ngIf="total >= shipping.freeShippingLimit">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="color: #de3d4c; font-size: 12px; margin-bottom: 0px">
                                                            Free shipping on purchases of
                                                            {{ shipping.freeShippingLimit | currency : " TRY" }}
                                                            & more!
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 13px">
                                                            <span class="act-price" style="color: #dc3545">{{
                                                                 shipping.shippingCost | currency : " TRY -"
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row mb-2">
                                                  <div class="col-7">
                                                       <h6 class="poppins">
                                                            Subtotal <span style="font-size: 0.8rem">(TRY)</span>
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" *ngIf="total >= shipping.freeShippingLimit">
                                                       <h6 class="text-gold text-bold">
                                                            {{ total | currency : " TRY " }}
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" *ngIf="total < shipping.freeShippingLimit">
                                                       <h6 class="text-gold text-bold">
                                                            {{ total + shipping.shippingCost | currency : " TRY " }}
                                                       </h6>
                                                  </div>
                                             </div>
                                             <div class="col-lg-12 col-md-8 center" *ngIf="getStepper">
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                                                       *ngIf="checkboxAgreement && !startPayment"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button"
                                                       (click)="checkoutPayment()">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                  </button>
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                                                       *ngIf="checkboxAgreement && startPayment"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                       <i
                                                            style="margin-left: 3px"
                                                            class="fa fa-spinner fa-spin fa-lg"></i>
                                                  </button>
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                                                       *ngIf="!checkboxAgreement"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                  </button>
                                             </div>
                                             <div class="col-lg-12 col-md-8 center" *ngIf="!getStepper">
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button"
                                                       [disabled]="disabledButton"
                                                       (click)="loadUserAddresses()">
                                                       I'm Ready to Pay
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="col-xl-8 col-lg-8 col-md-12 col-12" *ngIf="!getStepper">
                                        <div class="card mb-2" *ngFor="let product of cartProducts; let i = index">
                                             <div class="card-body" style="padding: 0.65rem" *ngIf="product">
                                                  <div class="row" style="align-items: center">
                                                       <div class="col-xl-1 col-lg-1 col-md-1 col-3 text-center">
                                                            <button
                                                                 (click)="deleteCartProduct(i)"
                                                                 mat-icon-button
                                                                 aria-label="Example icon button with a home icon"
                                                                 style="color: #000; font-size: 15px; width: 100%">
                                                                 <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                       </div>
                                                       <div class="col-xl-2 col-lg-2 col-md-2 col-5">
                                                            <a [routerLink]="'/product/' + product.id">
                                                                 <img
                                                                      *ngIf="product.images"
                                                                      [src]="product.images[0]"
                                                                      class="center swiper-image"
                                                                      style="
                                                                           border-radius: 4px !important;
                                                                           width: 100%;
                                                                      " />
                                                            </a>
                                                       </div>
                                                       <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                                                            <span class="text-uppercase text-muted brand w-100">
                                                                 {{ product.markaEN | uppercase }}
                                                            </span>
                                                            <a [routerLink]="'/product/' + product.id">
                                                                 <p
                                                                      class="text-uppercase mt-1 text-green"
                                                                      style="font-size: 13px; margin-bottom: 0.25rem">
                                                                      <b>{{ product.nameEN }}</b>
                                                                 </p>
                                                            </a>
                                                            <div
                                                                 class="price d-flex flex-row align-items-center"
                                                                 *ngIf="product.discountType == 'noDiscount'">
                                                                 <span class="act-price">
                                                                      {{ product.price | currency : " TRY " }} x
                                                                      {{ product.count }} =
                                                                      {{
                                                                           product.price * product.count
                                                                                | currency : " TRY "
                                                                      }}</span
                                                                 >
                                                            </div>
                                                            <div
                                                                 class="price align-items-center"
                                                                 *ngIf="product.discountType == 'discount'">
                                                                 <p style="margin-bottom: 0.25rem">
                                                                      <small class="dis-price" style="color: #dc3545">
                                                                           {{ product.price | currency : " TRY " }} x
                                                                           {{ product.count }} =
                                                                           {{
                                                                                product.price * product.count
                                                                                     | currency : " TRY "
                                                                           }}</small
                                                                      >
                                                                 </p>
                                                                 <span class="act-price" style="color: #28a745">
                                                                      {{
                                                                           product.price - product.discount
                                                                                | currency : " TRY "
                                                                      }}
                                                                      x {{ product.count }} =
                                                                      {{
                                                                           (product.price - product.discount) *
                                                                                product.count | currency : " TRY "
                                                                      }}</span
                                                                 >
                                                            </div>
                                                            <div
                                                                 class="price align-items-center"
                                                                 *ngIf="product.discountType == 'percent'">
                                                                 <span class="act-price" style="color: #28a745">
                                                                      {{
                                                                           product.price -
                                                                                product.price * (product.discount / 100)
                                                                                | currency : " TRY "
                                                                      }}
                                                                      x {{ product.count }} =
                                                                      {{
                                                                           (product.price -
                                                                                product.price *
                                                                                     (product.discount / 100)) *
                                                                                product.count | currency : " TRY  "
                                                                      }}</span
                                                                 >
                                                                 <div>
                                                                      <small class="dis-price" style="color: #dc3545">
                                                                           {{
                                                                                product.price * product.count
                                                                                     | currency : " TRY "
                                                                           }}</small
                                                                      >
                                                                      <span> {{ product.discount }}% OFF</span>
                                                                 </div>
                                                            </div>
                                                            <div
                                                                 *ngIf="product.count > product.stock"
                                                                 style="color: #dc3545">
                                                                 There is no enough stock for this product.
                                                                 <br />
                                                                 <b>Available Stock {{ product.stock }}</b>
                                                            </div>
                                                       </div>
                                                       <div class="col-xl-3 col-lg-3 col-md-3 col-6 ml-auto mt-2">
                                                            <div class="stepper-input stepper__input center" dir="ltr">
                                                                 <button
                                                                      class="toggle-less"
                                                                      (click)="countIncrement(product, -1, i)">
                                                                      <i class="fas fa-minus"></i>
                                                                 </button>
                                                                 <div
                                                                      class="stepper-input-field"
                                                                      name="range-value"
                                                                      aria-atomic="true"
                                                                      aria-live="polite"
                                                                      aria-relevant="all">
                                                                      {{ product.count }}
                                                                 </div>
                                                                 <button
                                                                      class="toggle-more"
                                                                      *ngIf="product.stock > product.count"
                                                                      (click)="countIncrement(product, 1, i)">
                                                                      <i class="fas fa-plus"></i>
                                                                 </button>
                                                                 <button
                                                                      class="toggle-more"
                                                                      *ngIf="product.stock <= product.count"
                                                                      [disabled]="true">
                                                                      <i class="fas fa-plus"></i>
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="col-xl-8 col-lg-8 col-md-12 col-12" *ngIf="getStepper">
                                        <div class="row" style="align-items: center">
                                             <button
                                                  mat-icon-button
                                                  aria-label="Example icon button with a vertical three dot icon"
                                                  (click)="getStepper = !getStepper">
                                                  <mat-icon>keyboard_backspace</mat-icon>
                                             </button>
                                             <div class="col-md-2 col-4 ml-auto">
                                                  <img
                                                       src="https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com/o/privacy%2Fkindpng_3669285.png?alt=media&token=3390e7aa-f44e-4dd3-99d0-7aeb787c3859"
                                                       width="100%" />
                                             </div>
                                        </div>
                                        <mat-horizontal-stepper
                                             [linear]="true"
                                             #stepper
                                             (selectionChange)="selectionChange($event)">
                                             <mat-step [completed]="addressSelected">
                                                  <ng-template matStepLabel class="poppins">
                                                       <span class="display-lg"> Select your delivery address </span>
                                                  </ng-template>
                                                  <mat-spinner
                                                       style="margin-top: 15px"
                                                       class="center"
                                                       *ngIf="addresses.length == 0 && showSpinner">
                                                  </mat-spinner>
                                                  <div class="display-sm">
                                                       <p class="text-center poppins">Select your delivery address</p>
                                                       <hr />
                                                  </div>
                                                  <div
                                                       class="chooseAddress mat-typography"
                                                       *ngIf="addresses.length > 0 && !showSpinner"
                                                       style="padding: 5px !important; margin: 0 !important">
                                                       <section class="example-section mb-2">
                                                            <mat-checkbox
                                                                 class="example-margin poppins"
                                                                 style="font-size: 11px"
                                                                 [(ngModel)]="checkboxAddBillingAddress">
                                                                 My billing & shipping address are the same.
                                                            </mat-checkbox>
                                                       </section>
                                                       <div class="row mt-2 mb-2" *ngIf="!checkboxAddBillingAddress">
                                                            <div class="col-md-4 mb-2">
                                                                 <button
                                                                      class="btn btn-outline-warning btn-block btn-sm mt-1"
                                                                      (click)="openDialog()">
                                                                      <i class="fas fa-plus"></i> Add Billing Address
                                                                 </button>
                                                            </div>
                                                            <div class="col-md-4" *ngIf="billingAddress">
                                                                 <div class="row">
                                                                      <div
                                                                           class="col-lg-10 col-md-10 col-10 text-center"
                                                                           style="display: flex; align-items: center">
                                                                           <p
                                                                                class="poppins"
                                                                                style="margin-bottom: 0px !important">
                                                                                {{ billingAddress.addressTitle }}
                                                                           </p>
                                                                      </div>
                                                                      <div class="col-lg-1 col-md-1 col-2 text-center">
                                                                           <button
                                                                                (click)="deleteBillingAddress()"
                                                                                mat-icon-button
                                                                                aria-label="Example icon button with a home icon"
                                                                                style="
                                                                                     color: #000;
                                                                                     font-size: 15px;
                                                                                     width: 100%;
                                                                                ">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                           </button>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div class="row">
                                                            <div class="col-xl-4 col-lg-6 col-md-6 mb-2">
                                                                 <div
                                                                      class="card shadow-sm poppins"
                                                                      style="height: 100%">
                                                                      <div
                                                                           class="card-header"
                                                                           style="
                                                                                background-color: rgba(
                                                                                     0,
                                                                                     0,
                                                                                     0,
                                                                                     0.03
                                                                                ) !important;
                                                                           ">
                                                                           <p
                                                                                style="
                                                                                     margin-bottom: 0px;
                                                                                     display: flex;
                                                                                     justify-content: space-between;
                                                                                     align-items: center;
                                                                                ">
                                                                                <a
                                                                                     style="color: #000"
                                                                                     routerLink="/my-addresses"
                                                                                     [queryParams]="{
                                                                                          returnUrl: '/cart'
                                                                                     }">
                                                                                     Add A New Address
                                                                                </a>
                                                                           </p>
                                                                      </div>
                                                                      <div
                                                                           class="card-body"
                                                                           style="
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: space-around;
                                                                                font-size: 35px;
                                                                           ">
                                                                           <a
                                                                                routerLink="/my-addresses"
                                                                                [queryParams]="{ returnUrl: '/cart' }">
                                                                                <i
                                                                                     class="fas fa-plus"
                                                                                     style="color: #ffc10e"></i>
                                                                           </a>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <mat-radio-group
                                                                 class="col-xl-4 col-lg-6 col-md-6 mb-2"
                                                                 *ngFor="let address of addresses; let i = index">
                                                                 <a (click)="selectAddress(i)">
                                                                      <div
                                                                           class="card shadow-sm poppins"
                                                                           [ngClass]="{
                                                                                selectedCard: address.selected
                                                                           }">
                                                                           <div
                                                                                class="card-header"
                                                                                style="
                                                                                     background-color: rgba(
                                                                                          0,
                                                                                          0,
                                                                                          0,
                                                                                          0.03
                                                                                     ) !important;
                                                                                ">
                                                                                <p
                                                                                     style="
                                                                                          margin-bottom: 0px;
                                                                                          display: flex;
                                                                                          justify-content: space-between;
                                                                                          align-items: center;
                                                                                     ">
                                                                                     {{ address.addressTitle }}
                                                                                     <mat-radio-button
                                                                                          value="{{ address.id }}"
                                                                                          [checked]="address.selected">
                                                                                     </mat-radio-button>
                                                                                </p>
                                                                           </div>
                                                                           <div
                                                                                class="card-body"
                                                                                style="
                                                                                     font-size: 13px;
                                                                                     padding: 0.75rem;
                                                                                ">
                                                                                <p
                                                                                     style="
                                                                                          margin-bottom: 0px;
                                                                                          font-family: monospace;
                                                                                          letter-spacing: 1px;
                                                                                          overflow: hidden;
                                                                                          text-overflow: ellipsis;
                                                                                     ">
                                                                                     {{ address.name | uppercase }}
                                                                                     {{ address.lastname | uppercase }}
                                                                                     <br />
                                                                                     <span
                                                                                          style="
                                                                                               text-overflow: ellipsis;
                                                                                               overflow: hidden;
                                                                                               white-space: nowrap;
                                                                                          ">
                                                                                          {{
                                                                                               address.address
                                                                                                    | uppercase
                                                                                          }}
                                                                                     </span>
                                                                                     <br />
                                                                                     {{
                                                                                          address.district | titlecase
                                                                                     }}/{{ address.city | titlecase }}
                                                                                     <br />
                                                                                     {{ address.phone }}
                                                                                </p>
                                                                           </div>
                                                                           <div class="container-fluid">
                                                                                <div class="row">
                                                                                     <div
                                                                                          class="col-md-9 col-9 center mb-3">
                                                                                          <a
                                                                                               routerLink="/my-addresses"
                                                                                               [queryParams]="{
                                                                                                    returnUrl: '/cart'
                                                                                               }"
                                                                                               class="btn btn-outline-warning btn-block btn-sm mt-1">
                                                                                               <i
                                                                                                    class="fas fa-edit"></i>
                                                                                               Edit
                                                                                          </a>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </a>
                                                            </mat-radio-group>
                                                       </div>
                                                  </div>
                                                  <div
                                                       class="poppins text-center"
                                                       *ngIf="addresses.length == 0 && !showSpinner">
                                                       <p style="margin-bottom: 5px !important">
                                                            Sorry!.. you don't have any added address
                                                            <i class="fas fa-heart-broken"></i>
                                                       </p>
                                                       <div class="nav-link waves-light position-relative center">
                                                            <a
                                                                 class="center"
                                                                 routerLink="/my-addresses"
                                                                 [queryParams]="{ returnUrl: '/cart' }">
                                                                 Add Address <i class="fas fa-external-link-alt"></i>
                                                            </a>
                                                       </div>
                                                  </div>
                                                  <div class="col-md-6 mt-4 center" *ngIf="addressSelected">
                                                       <button
                                                            matStepperNext
                                                            *ngIf="checkboxAddBillingAddress"
                                                            class="btn btn-success btn-rounded btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button">
                                                            Next Step
                                                       </button>
                                                       <button
                                                            *ngIf="!checkboxAddBillingAddress && !billingAddress"
                                                            class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button">
                                                            Next Step
                                                       </button>
                                                       <button
                                                            matStepperNext
                                                            *ngIf="!checkboxAddBillingAddress && billingAddress"
                                                            class="btn btn-success text-uppercase btn-block mr-2 px-4 poppins"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button">
                                                            Next Step
                                                       </button>
                                                  </div>
                                                  <div class="col-md-6 mt-4 center" *ngIf="!addressSelected">
                                                       <button
                                                            class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button">
                                                            Next Step
                                                       </button>
                                                  </div>
                                             </mat-step>
                                             <mat-step [completed]="!cardForm.invalid || cardSelected">
                                                  <ng-template matStepLabel>
                                                       <span class="display-lg"> Select Your Payment Method </span>
                                                  </ng-template>
                                                  <div class="display-sm">
                                                       <p class="text-center poppins">Select Your Payment Method</p>
                                                       <hr />
                                                  </div>
                                                  <div
                                                       class="chooseAddress mat-typography"
                                                       *ngIf="!showSpinner"
                                                       style="padding: 5px !important; margin: 0 !important">
                                                       <div class="row">
                                                            <div class="col-md-12 mt-4">
                                                                 <a
                                                                      class="poppins link-hover"
                                                                      *ngIf="!addCardForm"
                                                                      (click)="addCardForm = true; cardSelected = false"
                                                                      style="color: #000">
                                                                      <i
                                                                           class="fas fa-plus"
                                                                           style="
                                                                                color: #ffc10e;
                                                                                margin-right: 5px;
                                                                           "></i>
                                                                      Add New Payment Method
                                                                 </a>
                                                                 <a
                                                                      class="poppins link-hover"
                                                                      *ngIf="addCardForm"
                                                                      (click)="addCardForm = false; resetCardForm()"
                                                                      style="color: #000">
                                                                      <i
                                                                           class="fas fa-save"
                                                                           style="
                                                                                color: #ffc10e;
                                                                                margin-right: 5px;
                                                                           "></i>
                                                                      Or select from saved cards
                                                                 </a>
                                                            </div>
                                                            <div class="col-md-12 mb-2 mt-4">
                                                                 <div
                                                                      class="card shadow-sm poppins"
                                                                      style="height: 100%"
                                                                      *ngIf="addCardForm">
                                                                      <div
                                                                           class="card-header"
                                                                           style="
                                                                                background-color: rgba(
                                                                                     0,
                                                                                     0,
                                                                                     0,
                                                                                     0.03
                                                                                ) !important;
                                                                           ">
                                                                           <p
                                                                                style="
                                                                                     margin-bottom: 0px;
                                                                                     display: flex;
                                                                                     justify-content: space-between;
                                                                                     align-items: center;
                                                                                ">
                                                                                <a style="color: #000">
                                                                                     Add New Payment Method
                                                                                </a>
                                                                           </p>
                                                                      </div>
                                                                      <div
                                                                           class="card-body"
                                                                           style="
                                                                                display: flex;
                                                                                align-items: center;
                                                                                justify-content: space-around;
                                                                           ">
                                                                           <form
                                                                                class="contact-form mt-1"
                                                                                novalidate
                                                                                [formGroup]="cardForm"
                                                                                #fform="ngForm"
                                                                                [style.fontSize.px]="13"
                                                                                autocomplete="off">
                                                                                <div class="mat-dialog-content">
                                                                                     <div class="row">
                                                                                          <div class="col-md-6">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >Card
                                                                                                         Alias</mat-label
                                                                                                    >
                                                                                                    <input
                                                                                                         matInput
                                                                                                         formControlName="cardAlias"
                                                                                                         type="text"
                                                                                                         placeholder="EX. My Visa Card"
                                                                                                         required />
                                                                                                    <mat-error
                                                                                                         *ngIf="
                                                                                                              formErrors.cardAlias
                                                                                                         ">
                                                                                                         {{
                                                                                                              formErrors.cardAlias
                                                                                                         }}
                                                                                                    </mat-error>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div class="col-md-6">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >Card Holder
                                                                                                         Name</mat-label
                                                                                                    >
                                                                                                    <input
                                                                                                         matInput
                                                                                                         formControlName="cardHolderName"
                                                                                                         type="text"
                                                                                                         placeholder="Ex. John Doe"
                                                                                                         required />
                                                                                                    <mat-error
                                                                                                         *ngIf="
                                                                                                              formErrors.cardHolderName
                                                                                                         ">
                                                                                                         {{
                                                                                                              formErrors.cardHolderName
                                                                                                         }}
                                                                                                    </mat-error>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div class="col-md-10 col-12">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >Card
                                                                                                         Number</mat-label
                                                                                                    >
                                                                                                    <input
                                                                                                         matInput
                                                                                                         formControlName="cardNumber"
                                                                                                         type="tel"
                                                                                                         name="cc_number"
                                                                                                         placeholder="Your Card Number"
                                                                                                         ccNumber
                                                                                                         #ccNumber="ccNumber"
                                                                                                         required />
                                                                                                    <mat-error
                                                                                                         *ngIf="
                                                                                                              formErrors.cardNumber
                                                                                                         "
                                                                                                         style="
                                                                                                              font-size: 11px;
                                                                                                         ">
                                                                                                         {{
                                                                                                              formErrors.cardNumber
                                                                                                         }}
                                                                                                    </mat-error>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div
                                                                                               class="col-md-2 col-2 display-lg">
                                                                                               <img
                                                                                                    class="w-100"
                                                                                                    src="assets/creditCard/visa.svg"
                                                                                                    style="
                                                                                                         object-fit: contain;
                                                                                                    "
                                                                                                    *ngIf="
                                                                                                         (ccNumber.resolvedScheme$
                                                                                                              | async) ==
                                                                                                         'visa'
                                                                                                    " />
                                                                                               <img
                                                                                                    class="w-100"
                                                                                                    src="assets/creditCard/mastercard.svg"
                                                                                                    style="
                                                                                                         object-fit: contain;
                                                                                                    "
                                                                                                    *ngIf="
                                                                                                         (ccNumber.resolvedScheme$
                                                                                                              | async) ==
                                                                                                         'mastercard'
                                                                                                    " />
                                                                                               <img
                                                                                                    class="w-100"
                                                                                                    src="assets/creditCard/ae.png"
                                                                                                    style="
                                                                                                         object-fit: contain;
                                                                                                    "
                                                                                                    *ngIf="
                                                                                                         (ccNumber.resolvedScheme$
                                                                                                              | async) ==
                                                                                                         'amex'
                                                                                                    " />
                                                                                          </div>
                                                                                          <div class="col-md-4">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >Month</mat-label
                                                                                                    >
                                                                                                    <mat-select
                                                                                                         class="poppins"
                                                                                                         formControlName="expireMonth">
                                                                                                         <mat-option
                                                                                                              class="poppins"
                                                                                                              *ngFor="
                                                                                                                   let month of months
                                                                                                              "
                                                                                                              [value]="
                                                                                                                   month
                                                                                                              ">
                                                                                                              {{
                                                                                                                   month
                                                                                                              }}
                                                                                                         </mat-option>
                                                                                                    </mat-select>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div class="col-md-4">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >Year</mat-label
                                                                                                    >
                                                                                                    <mat-select
                                                                                                         class="poppins"
                                                                                                         formControlName="expireYear">
                                                                                                         <mat-option
                                                                                                              class="poppins"
                                                                                                              *ngFor="
                                                                                                                   let year of years
                                                                                                              "
                                                                                                              [value]="
                                                                                                                   year
                                                                                                              ">
                                                                                                              {{ year }}
                                                                                                         </mat-option>
                                                                                                    </mat-select>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div class="col-md-4">
                                                                                               <mat-form-field
                                                                                                    class="poppins w-100"
                                                                                                    appearance="outline"
                                                                                                    color="primary">
                                                                                                    <mat-label
                                                                                                         >CVC</mat-label
                                                                                                    >
                                                                                                    <input
                                                                                                         matInput
                                                                                                         formControlName="cvc"
                                                                                                         type="tel"
                                                                                                         placeholder="CVC"
                                                                                                         pattern="[0-9]+"
                                                                                                         minlength="{{
                                                                                                              (ccNumber.resolvedScheme$
                                                                                                                   | async) ==
                                                                                                              'amex'
                                                                                                                   ? 4
                                                                                                                   : 3
                                                                                                         }}"
                                                                                                         maxlength="{{
                                                                                                              (ccNumber.resolvedScheme$
                                                                                                                   | async) ==
                                                                                                              'amex'
                                                                                                                   ? 4
                                                                                                                   : 3
                                                                                                         }}"
                                                                                                         required />
                                                                                                    <mat-error
                                                                                                         *ngIf="
                                                                                                              formErrors.cvc
                                                                                                         "
                                                                                                         style="
                                                                                                              font-size: 11px;
                                                                                                         ">
                                                                                                         <span
                                                                                                              *ngIf="
                                                                                                                   formErrors.cvc ==
                                                                                                                   'minlength '
                                                                                                              ">
                                                                                                              <span
                                                                                                                   *ngIf="
                                                                                                                        (ccNumber.resolvedScheme$
                                                                                                                             | async) ==
                                                                                                                        'amex'
                                                                                                                   ">
                                                                                                                   CVC
                                                                                                                   Code
                                                                                                                   should
                                                                                                                   be at
                                                                                                                   least
                                                                                                                   4
                                                                                                                   numbers
                                                                                                              </span>
                                                                                                              <span
                                                                                                                   *ngIf="
                                                                                                                        (ccNumber.resolvedScheme$
                                                                                                                             | async) !=
                                                                                                                        'amex'
                                                                                                                   ">
                                                                                                                   CVC
                                                                                                                   Code
                                                                                                                   should
                                                                                                                   be at
                                                                                                                   least
                                                                                                                   3
                                                                                                                   numbers
                                                                                                              </span>
                                                                                                         </span>
                                                                                                         <span
                                                                                                              *ngIf="
                                                                                                                   formErrors.cvc ==
                                                                                                                   'maxlength '
                                                                                                              ">
                                                                                                              <span
                                                                                                                   *ngIf="
                                                                                                                        (ccNumber.resolvedScheme$
                                                                                                                             | async) ==
                                                                                                                        'amex'
                                                                                                                   ">
                                                                                                                   CVC
                                                                                                                   Code
                                                                                                                   should
                                                                                                                   be
                                                                                                                   less
                                                                                                                   than
                                                                                                                   4
                                                                                                                   numbers
                                                                                                              </span>
                                                                                                              <span
                                                                                                                   *ngIf="
                                                                                                                        (ccNumber.resolvedScheme$
                                                                                                                             | async) !=
                                                                                                                        'amex'
                                                                                                                   ">
                                                                                                                   CVC
                                                                                                                   Code
                                                                                                                   should
                                                                                                                   be
                                                                                                                   less
                                                                                                                   than
                                                                                                                   3
                                                                                                                   numbers
                                                                                                              </span>
                                                                                                         </span>
                                                                                                         <span
                                                                                                              *ngIf="
                                                                                                                   formErrors.cvc ==
                                                                                                                   'CVC Code is required. '
                                                                                                              ">
                                                                                                              CVC Code
                                                                                                              is
                                                                                                              required.
                                                                                                         </span>
                                                                                                    </mat-error>
                                                                                               </mat-form-field>
                                                                                          </div>
                                                                                          <div class="col-md-12">
                                                                                               <section
                                                                                                    class="poppins w-100"
                                                                                                    style="
                                                                                                         margin-top: 10px;
                                                                                                    ">
                                                                                                    <mat-checkbox
                                                                                                         style="
                                                                                                              margin-top: 0.5rem;
                                                                                                         "
                                                                                                         formControlName="registerCard"
                                                                                                         color="primary"
                                                                                                         class="example-margin poppins"
                                                                                                         >Save this
                                                                                                         card<i
                                                                                                              style="
                                                                                                                   font-size: 10px;
                                                                                                                   margin-left: 5px;
                                                                                                              "
                                                                                                              placement="right"
                                                                                                              mdbTooltip="Payment Cards will be saved on Iyzico"
                                                                                                              class="fas fa-info-circle"></i>
                                                                                                    </mat-checkbox>
                                                                                               </section>
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </form>
                                                                      </div>
                                                                      <div class="px-4">
                                                                           <div
                                                                                class="alert alert-danger mt-1"
                                                                                role="alert">
                                                                                <i class="fas fa-credit-card"></i>
                                                                                Payment Cards will be saved on
                                                                                <a
                                                                                     href="https://www.iyzico.com/"
                                                                                     target="_blank"
                                                                                     style="
                                                                                          color: #1e64ff;
                                                                                          font-size: 15px;
                                                                                     "
                                                                                     ><img
                                                                                          src="https://www.iyzico.com/assets/images/content/favicon.png?v=v4.0.281"
                                                                                          width="20px"
                                                                                          alt="" />
                                                                                     Iyzico</a
                                                                                >.
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <mat-radio-group
                                                                 class="col-md-4 mb-2 mt-2"
                                                                 *ngFor="let card of cards; let i = index">
                                                                 <div
                                                                      class="card shadow-sm poppins"
                                                                      *ngIf="!addCardForm"
                                                                      style="height: 100%"
                                                                      [ngClass]="{ selectedCard: card.selected }">
                                                                      <a (click)="selectCard(i)">
                                                                           <div
                                                                                class="card-header"
                                                                                style="
                                                                                     background-color: rgba(
                                                                                          0,
                                                                                          0,
                                                                                          0,
                                                                                          0.03
                                                                                     ) !important;
                                                                                     display: flex;
                                                                                ">
                                                                                <a
                                                                                     class="cancel-link center"
                                                                                     style="
                                                                                          margin-right: 10px;
                                                                                          margin-left: -5px;
                                                                                     "
                                                                                     (click)="
                                                                                          deleteCardByCardToken(
                                                                                               card.cardToken
                                                                                          )
                                                                                     ">
                                                                                     <i class="fas fa-trash-alt"></i>
                                                                                </a>
                                                                                <div style="width: 100%">
                                                                                     <p
                                                                                          style="
                                                                                               margin-bottom: 0px;
                                                                                               display: flex;
                                                                                               justify-content: space-between;
                                                                                               align-items: center;
                                                                                               font-size: 13px;
                                                                                          ">
                                                                                          {{ card.cardAlias }}
                                                                                          <mat-radio-button
                                                                                               value="{{ card.id }}"
                                                                                               [checked]="
                                                                                                    card.selected
                                                                                               ">
                                                                                          </mat-radio-button>
                                                                                     </p>
                                                                                </div>
                                                                           </div>
                                                                           <div
                                                                                class="card-body"
                                                                                style="
                                                                                     font-size: 13px;
                                                                                     padding-bottom: 0px;
                                                                                     padding: 0.65rem;
                                                                                ">
                                                                                <div
                                                                                     style="
                                                                                          justify-content: space-between;
                                                                                          align-items: center;
                                                                                          display: flex;
                                                                                          flex-wrap: wrap;
                                                                                     ">
                                                                                     <p
                                                                                          class="poppins"
                                                                                          style="
                                                                                               font-size: 11px;
                                                                                               margin-bottom: 5px !important;
                                                                                          ">
                                                                                          {{ card.cardBankName }}
                                                                                     </p>
                                                                                     <img
                                                                                          src="{{
                                                                                               card.cardAssociationImg
                                                                                          }}"
                                                                                          style="
                                                                                               width: 45px;
                                                                                               height: 30px;
                                                                                          " />
                                                                                </div>
                                                                                <p
                                                                                     style="
                                                                                          font-size: 10px;
                                                                                          text-align: left;
                                                                                          margin-bottom: 0px;
                                                                                     ">
                                                                                     {{ card.binNumber }} ****
                                                                                     {{ card.lastFourDigits }}
                                                                                </p>
                                                                           </div>
                                                                      </a>
                                                                 </div>
                                                            </mat-radio-group>
                                                            <p
                                                                 *ngIf="cards.length <= 0 && !addCardForm"
                                                                 class="text-center w-100 center poppins text-bold"
                                                                 style="font-size: 15px">
                                                                 You do not have any previously saved credit cards!
                                                            </p>
                                                       </div>
                                                  </div>
                                                  <div class="col-md-6 mt-4 center">
                                                       <button
                                                            *ngIf="!cardSelected && cardForm.invalid"
                                                            class="cardSelected cardForminvalid btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button"
                                                            disabled>
                                                            Proceed to Checkout
                                                       </button>
                                                       <button
                                                            matStepperNext
                                                            *ngIf="cardSelected || !cardForm.invalid"
                                                            class="cardSelected btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                                                            style="font-size: 13px; color: #fff !important"
                                                            type="button"
                                                            (click)="addPaymentMethod(); getSelectedCard()">
                                                            Proceed to Checkout
                                                       </button>
                                                  </div>
                                             </mat-step>
                                             <mat-step>
                                                  <ng-template matStepLabel>
                                                       <span class="display-lg"> Review & Pay </span>
                                                  </ng-template>
                                                  <div class="col-md-12 mt-2 mb-2">
                                                       <div class="display-sm">
                                                            <p class="text-center poppins">Review & Pay</p>
                                                            <hr />
                                                       </div>
                                                       <div class="card card-body" style="padding: 0.65rem">
                                                            <p class="poppins" style="font-size: 20px">
                                                                 Order Summary:
                                                            </p>
                                                            <div class="row">
                                                                 <div class="col-7">
                                                                      <h6
                                                                           class="text-uppercase text-green poppins"
                                                                           style="
                                                                                font-size: 16px;
                                                                                margin-bottom: 0px;
                                                                                padding-left: 5px;
                                                                           ">
                                                                           Items ({{ cartProducts.length }}):
                                                                      </h6>
                                                                 </div>
                                                                 <div class="col-5" dir="ltr">
                                                                      <div
                                                                           class="price d-flex flex-row align-items-center"
                                                                           style="font-size: 15px">
                                                                           <span class="act-price">{{
                                                                                totalBeforeDiscount | currency : " TRY "
                                                                           }}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div
                                                                 class="row mt-1"
                                                                 style="align-items: center"
                                                                 *ngIf="totalBeforeDiscount != total">
                                                                 <div class="col-7">
                                                                      <h6
                                                                           class="text-uppercase text-green poppins"
                                                                           style="
                                                                                font-size: 16px;
                                                                                margin-bottom: 0px;
                                                                                padding-left: 5px;
                                                                           ">
                                                                           Discounts:
                                                                      </h6>
                                                                 </div>
                                                                 <div class="col-5" dir="ltr">
                                                                      <div
                                                                           class="price d-flex flex-row align-items-center"
                                                                           style="font-size: 15px">
                                                                           <span
                                                                                class="act-price"
                                                                                style="color: #dc3545"
                                                                                >{{
                                                                                     totalBeforeDiscount - total
                                                                                          | currency : " TRY- "
                                                                                }}</span
                                                                           >
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <hr />
                                                            <div class="row mb-2" style="align-items: center">
                                                                 <div class="col-7">
                                                                      <h6
                                                                           class="text-uppercase text-green poppins"
                                                                           style="
                                                                                font-size: 16px;
                                                                                margin-bottom: 0px;
                                                                                padding-left: 5px;
                                                                           ">
                                                                           Total:
                                                                      </h6>
                                                                 </div>
                                                                 <div class="col-5" dir="ltr">
                                                                      <div
                                                                           class="price d-flex flex-row align-items-center"
                                                                           style="font-size: 15px">
                                                                           <span class="act-price">{{
                                                                                total | currency : " TRY "
                                                                           }}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <hr />
                                                            <div class="row" style="align-items: center">
                                                                 <div class="col-7">
                                                                      <h6
                                                                           class="text-uppercase text-green poppins"
                                                                           style="font-size: 12px; margin-bottom: 0px">
                                                                           Shipping Cost:
                                                                      </h6>
                                                                 </div>
                                                                 <div class="col-5" dir="ltr">
                                                                      <div
                                                                           class="price d-flex flex-row align-items-center"
                                                                           style="font-size: 13px">
                                                                           <span class="act-price">{{
                                                                                shipping.shippingCost
                                                                                     | currency
                                                                                          : "
                                                            TRY "
                                                                           }}</span>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div
                                                                 class="row mt-2"
                                                                 style="align-items: center"
                                                                 *ngIf="total >= shipping.freeShippingLimit">
                                                                 <div class="col-7">
                                                                      <h6
                                                                           class="text-uppercase text-green poppins"
                                                                           style="
                                                                                color: #de3d4c;
                                                                                font-size: 12px;
                                                                                margin-bottom: 0px;
                                                                           ">
                                                                           Free shipping on purchases of
                                                                           {{
                                                                                shipping.freeShippingLimit
                                                                                     | currency : " TRY"
                                                                           }}
                                                                           & more!
                                                                      </h6>
                                                                 </div>
                                                                 <div class="col-5" dir="ltr">
                                                                      <div
                                                                           class="price d-flex flex-row align-items-center"
                                                                           style="font-size: 13px">
                                                                           <span
                                                                                class="act-price"
                                                                                style="color: #dc3545"
                                                                                >{{
                                                                                     shipping.shippingCost
                                                                                          | currency : " TRY -"
                                                                                }}</span
                                                                           >
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <hr />
                                                            <div class="row mb-4">
                                                                 <div class="col-7">
                                                                      <h6 class="poppins" style="font-size: 14px">
                                                                           Subtotal
                                                                           <span style="font-size: 0.8rem">(TRY)</span>
                                                                      </h6>
                                                                 </div>
                                                                 <div
                                                                      class="col-5"
                                                                      *ngIf="total >= shipping.freeShippingLimit">
                                                                      <h6
                                                                           class="text-gold text-bold"
                                                                           style="font-size: 14px">
                                                                           {{ total | currency : " TRY " }}
                                                                      </h6>
                                                                 </div>
                                                                 <div
                                                                      class="col-5"
                                                                      *ngIf="total < shipping.freeShippingLimit">
                                                                      <h6
                                                                           class="text-gold text-bold"
                                                                           style="font-size: 14px">
                                                                           {{
                                                                                total + shipping.shippingCost
                                                                                     | currency : " TRY "
                                                                           }}
                                                                      </h6>
                                                                 </div>
                                                            </div>
                                                            <div class="row payment-checkbox">
                                                                 <div class="col-md-12 col-12 mb-2">
                                                                      <section
                                                                           class="example-section"
                                                                           style="margin-left: 5px">
                                                                           <mat-checkbox
                                                                                [checked]="true"
                                                                                [disabled]="true"
                                                                                color="primary"
                                                                                class="example-margin poppins"></mat-checkbox>
                                                                           <span
                                                                                class="poppins"
                                                                                style="
                                                                                     margin-left: 5px;
                                                                                     font-size: 12px;
                                                                                ">
                                                                                I want to pay with 3d secure.
                                                                                <i class="fas fa-shield-alt"></i>
                                                                           </span>
                                                                      </section>
                                                                 </div>
                                                                 <div class="col-md-12 col-12 mb-2">
                                                                      <section
                                                                           class="example-section"
                                                                           style="margin-left: 5px">
                                                                           <mat-checkbox
                                                                                [(ngModel)]="checkboxAgreement"
                                                                                color="primary"
                                                                                class="example-margin">
                                                                           </mat-checkbox>
                                                                           <span
                                                                                class="poppins"
                                                                                style="
                                                                                     margin-left: 5px;
                                                                                     font-size: 12px;
                                                                                ">
                                                                                I have read and accept the
                                                                                <a
                                                                                     class="link-primary"
                                                                                     (click)="openAgreementDialog()"
                                                                                     >PRELIMINARY INFORMATION FORM</a
                                                                                >
                                                                                and
                                                                                <a
                                                                                     class="link-primary"
                                                                                     (click)="openAgreementDialog()"
                                                                                     >DISTANCE SALES AGREEMENT.</a
                                                                                >
                                                                           </span>
                                                                      </section>
                                                                 </div>
                                                                 <div
                                                                      class="col-md-12 col-12 mb-2"
                                                                      *ngIf="!checkboxAgreement">
                                                                      <button
                                                                           class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                                                                           style="
                                                                                font-size: 13px;
                                                                                color: #fff !important;
                                                                           "
                                                                           type="button">
                                                                           Pay
                                                                           {{
                                                                                getTotalWithShipping()
                                                                                     | currency : " TRY "
                                                                           }}
                                                                      </button>
                                                                 </div>
                                                                 <div
                                                                      class="col-md-12 col-12 mb-2"
                                                                      *ngIf="checkboxAgreement">
                                                                      <button
                                                                           *ngIf="!startPayment"
                                                                           class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                                                                           style="
                                                                                font-size: 13px;
                                                                                color: #fff !important;
                                                                           "
                                                                           type="button"
                                                                           (click)="checkoutPayment()">
                                                                           Pay
                                                                           {{
                                                                                getTotalWithShipping()
                                                                                     | currency : " TRY "
                                                                           }}
                                                                      </button>
                                                                      <!-- <button *ngIf="!startPayment"
                                                        class="btn btn-success text-uppercase btn-block mr-2 px-4 poppins"
                                                        style="font-size: 13px;color: #fff !important;"
                                                        type="button">
                                                        Pay {{getTotalWithShipping() | currency:' TRY '}}
                                                    </button> -->
                                                                      <button
                                                                           *ngIf="startPayment"
                                                                           class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                                                                           style="
                                                                                font-size: 13px;
                                                                                color: #fff !important;
                                                                           "
                                                                           type="button">
                                                                           Pay
                                                                           {{
                                                                                getTotalWithShipping()
                                                                                     | currency : " TRY "
                                                                           }}
                                                                           <i
                                                                                style="margin-left: 3px"
                                                                                class="fa fa-spinner fa-spin fa-lg"></i>
                                                                      </button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </mat-step>
                                        </mat-horizontal-stepper>
                                   </div>
                                   <div class="col-xl-4 col-lg-4 col-md-4 col-12 display-lg">
                                        <p
                                             class="bold text-uppercase poppins"
                                             style="
                                                  color: #de3d4c;
                                                  font-size: 13px;
                                                  margin-bottom: 5px;
                                                  padding-left: 5px;
                                             ">
                                             Free shipping on purchases of
                                             {{ shipping.freeShippingLimit | currency : " TRY " }} & more!
                                        </p>
                                        <div class="card card-body" style="padding: 0.65rem !important">
                                             <div class="row mt-3 mb-2" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Items ({{ cartProducts.length }}):
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price">{{
                                                                 totalBeforeDiscount | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div
                                                  class="row mt-1"
                                                  style="align-items: center"
                                                  *ngIf="totalBeforeDiscount != total">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Discounts:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price" style="color: #dc3545">{{
                                                                 totalBeforeDiscount - total | currency : " TRY- "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row mb-2" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="
                                                                 font-size: 16px;
                                                                 margin-bottom: 0px;
                                                                 padding-left: 5px;
                                                            ">
                                                            Total:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 15px">
                                                            <span class="act-price">{{
                                                                 total | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row" style="align-items: center">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="font-size: 12px; margin-bottom: 0px">
                                                            Shipping Cost:
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 13px">
                                                            <span class="act-price">{{
                                                                 shipping.shippingCost | currency : " TRY "
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div
                                                  class="row mt-2"
                                                  style="align-items: center"
                                                  *ngIf="total >= shipping.freeShippingLimit">
                                                  <div class="col-7">
                                                       <h6
                                                            class="text-uppercase text-green poppins"
                                                            style="color: #de3d4c; font-size: 12px; margin-bottom: 0px">
                                                            Free shipping on purchases of
                                                            {{ shipping.freeShippingLimit | currency : " TRY " }}
                                                            & more!
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" dir="ltr">
                                                       <div
                                                            class="price d-flex flex-row align-items-center"
                                                            style="font-size: 13px">
                                                            <span class="act-price" style="color: #dc3545">{{
                                                                 shipping.shippingCost | currency : " TRY -"
                                                            }}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <hr />
                                             <div class="row mb-4">
                                                  <div class="col-7">
                                                       <h6 class="poppins">
                                                            Subtotal <span style="font-size: 0.8rem">(TRY)</span>
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" *ngIf="total >= shipping.freeShippingLimit">
                                                       <h6 class="text-gold text-bold">
                                                            {{ total | currency : " TRY " }}
                                                       </h6>
                                                  </div>
                                                  <div class="col-5" *ngIf="total < shipping.freeShippingLimit">
                                                       <h6 class="text-gold text-bold">
                                                            {{ total + shipping.shippingCost | currency : " TRY " }}
                                                       </h6>
                                                  </div>
                                             </div>
                                             <div class="col-lg-12 col-md-8 center" *ngIf="getStepper">
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                                                       *ngIf="checkboxAgreement && !startPayment"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button"
                                                       (click)="checkoutPayment()">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                  </button>
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                                                       *ngIf="checkboxAgreement && startPayment"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                       <i
                                                            style="margin-left: 3px"
                                                            class="fa fa-spinner fa-spin fa-lg"></i>
                                                  </button>
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                                                       *ngIf="!checkboxAgreement"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button">
                                                       Pay {{ getTotalWithShipping() | currency : " TRY " }}
                                                  </button>
                                             </div>
                                             <div class="col-lg-12 col-md-8 center" *ngIf="!getStepper">
                                                  <button
                                                       class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                                                       style="font-size: 13px; color: #fff !important"
                                                       type="button"
                                                       [disabled]="disabledButton"
                                                       (click)="loadUserAddresses()">
                                                       I'm Ready to Pay
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div
                              class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2"
                              *ngIf="cartProducts.length == 0 && !showSpinner">
                              <div class="card card-body">
                                   <div class="m-2 text-center poppins h4 h4-responsive" style="color: #000">
                                        <p class="poppins">Cart is empty <i class="fas fa-heart-broken"></i></p>
                                        <div
                                             style="width: 100px"
                                             class="nav-link waves-light position-relative center"
                                             routerLink="/cart">
                                             <i class="fas fa fa-shopping-cart fa-lg"></i>
                                        </div>
                                        <hr />
                                        <p>Start Shopping Now! <br /></p>
                                        <a class="mt-4" routerLink="/products/ALL" target="_blank"
                                             >Check Our All Products <i class="fas fa-external-link-alt"></i
                                        ></a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div class="container" style="margin-top: 5%">
               <h4 class="lemonada text-center mb-4 text-green">Suggested Products</h4>
               <swiper
                    [autoHeight]="true"
                    [spaceBetween]="50"
                    [navigation]="true"
                    [pagination]="{ clickable: true }"
                    [scrollbar]="{ draggable: true }"
                    [autoplay]="{ delay: 1000, disableOnInteraction: false }"
                    [config]="configProducts">
                    <ng-template swiperSlide *ngFor="let product of suggestedProducts">
                         <div class="col-md-12 mb-4 mt-4">
                              <div
                                   class="card rounded shadow border-0"
                                   matBadge="NEW"
                                   matBadgePosition="after"
                                   [matBadgeHidden]="!product.new">
                                   <a href="/product/{{ product.id }}">
                                        <img
                                             src="{{ product.images[0] }}"
                                             class="img-product"
                                             [ngStyle]="{ 'background-color': product.dominantColor }" />
                                   </a>
                                   <div
                                        class="out-of-stock"
                                        *ngIf="product.stock <= 0 || product.stock == null || !product.stock">
                                        Out Of Stock
                                   </div>
                                   <div class="card-body" style="padding: 0.75rem">
                                        <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                                             <a
                                                  href="/product/{{ product.id }}"
                                                  class="text-green lemonada"
                                                  style="font-size: 12px">
                                                  {{ product.nameEN }}
                                             </a>
                                        </div>
                                        <h5
                                             class="act-price"
                                             style="font-size: 100%"
                                             *ngIf="product.discountType == 'noDiscount'">
                                             {{ product.price }} TRY
                                        </h5>
                                        <h5
                                             class="act-price"
                                             style="font-size: 100%"
                                             *ngIf="product.discountType == 'discount'">
                                             {{ product.price - product.discount }} TRY
                                             <small class="dis-price">
                                                  {{ product.price }}
                                                  TRY</small
                                             >
                                        </h5>
                                        <h5
                                             class="act-price"
                                             style="font-size: 100%"
                                             *ngIf="product.discountType == 'percent'">
                                             {{ product.price - product.price * (product.discount / 100) }}
                                             TRY
                                             <small class="dis-price"> {{ product.price }} TRY</small>
                                        </h5>
                                        <!-- <ul class="list-inline small">
                                <li class="list-inline-item m-0" [matTooltip]="'Bad'"
                                    matTooltipPosition="above"><i class="fas fa-star text-gold"></i></li>
                                <li class="list-inline-item m-0" [matTooltip]="'Not Bad'"
                                    matTooltipPosition="above"><i class="fas fa-star text-gold"></i></li>
                                <li class="list-inline-item m-0" [matTooltip]="'Good'"
                                    matTooltipPosition="above"><i class="fas fa-star text-gold"></i></li>
                                <li class="list-inline-item m-0" [matTooltip]="'Very Good'"
                                    matTooltipPosition="above">
                                    <i class="fas fa-star text-gold"></i>
                                </li>
                                <li class="list-inline-item m-0" [matTooltip]="'Awesome'"
                                    matTooltipPosition="above"><i class="far fa-star text-gold"></i></li>
                            </ul> -->
                                        <a
                                             (click)="addToCart2(product.id)"
                                             (mouseover)="product.hover = true"
                                             *ngIf="product.stock > 0"
                                             (mouseleave)="product.hover = false"
                                             [ngStyle]="{
                                                  'border-color': product.dominantColor,
                                                  color: product.hover == true ? 'white' : product.dominantColor,
                                                  backgroundColor:
                                                       product.hover == true ? product.dominantColor : 'transparent'
                                             }"
                                             class="btn btn-halloum2 btn-block btn-sm poppins"
                                             >Add to Cart</a
                                        >
                                        <a
                                             (mouseover)="product.hover = true"
                                             *ngIf="product.stock <= 0 || product.stock == null || !product.stock"
                                             (mouseleave)="product.hover = false"
                                             [ngStyle]="{
                                                  'border-color': product.dominantColor,
                                                  color: product.hover == true ? 'white' : product.dominantColor,
                                                  backgroundColor:
                                                       product.hover == true ? product.dominantColor : 'transparent'
                                             }"
                                             class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                                             >Out Of Stock</a
                                        >
                                   </div>
                              </div>
                         </div>
                    </ng-template>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
               </swiper>
          </div>
     </main>
</div>
<div *ngIf="showSpinner">
     <div class="view w-100 ph-item">
          <div class="ph-col-12">
               <div style="height: 600px" class="ph-picture" width="100%"></div>
          </div>
     </div>
</div>

<app-footer></app-footer>
