import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import "hammerjs";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule, REGION } from "@angular/fire/functions";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { FabComponent } from "./fab/fab.component";
import { SwiperModule } from "swiper/angular";
import { BranchesComponent } from "./branches/branches.component";
import { SidenavService } from "./services/sidenav.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ProductsComponent } from "./products/products.component";
import { ProductComponent } from "./product/product.component";
import { CareersComponent } from "./careers/careers.component";
import { MatBadgeModule } from "@angular/material/badge";
import { TeamComponent } from "./team/team.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { AnimateOnScrollModule } from "ng2-animate-on-scroll";
import { FacebookModule } from "ngx-facebook";
import { LightboxModule } from "ngx-lightbox";
import { LazyImgDirective } from "./directives/lazy-img.directive";
import { DeferLoadDirective } from "./directives/defer-load.directive";
import { Globals } from "./global";
import { MatExpansionModule } from "@angular/material/expansion";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  AuthInterceptor,
  UnauthorizedInterceptor,
} from "./services/auth.interceptor";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { SafePipe } from "./pipes/safe.pipe";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { AuthComponent } from "./auth/auth.component";
import { ProfileComponent } from "./profile/profile.component";
import { UserAgreementComponent } from "./user-agreement/user-agreement.component";
import { SafeHTMLPipe } from "./pipes/safe-html.pipe";
import { CartComponent, AddressDialog } from "./cart/cart.component";
import { AutoCompleteComponent } from "./auto-complete/auto-complete.component";
import { ProductTableComponent } from "./product-table/product-table.component";
import { MyOrdersComponent } from "./my-orders/my-orders.component";
import {
  MyAddressesComponent,
  AddAddressDialog,
  EditAddressDialog,
} from "./my-addresses/my-addresses.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { WinWinComponent } from "./win-win/win-win.component";
import { baseURL } from "./shared/baseurl";
import { MatTabsModule } from "@angular/material/tabs";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { PaymentComponent } from "./payment/payment.component";
import { NavigatorListComponent } from "./navigator-list/navigator-list.component";
import { ImgMagnifier } from "ng-img-magnifier";
import { FollowUsComponent } from "./follow-us/follow-us.component";
import { DeleteDialogComponent } from "./cart/delete-dialog/delete-dialog.component";
import { BlogComponent } from "./blog/blog.component";
import { VisionMissionComponent } from "./vision-mission/vision-mission.component";
import { BlogDetailComponent } from "./blog-detail/blog-detail.component";
import { CategoriesComponent } from './categories/categories.component';
import { ProductOneComponent } from './product-one/product-one.component';

const config = {
  apiKey: "AIzaSyDZjNobS5LZ88QQI5PM3FYiBz7B0yn8fL8",
  authDomain: "halloum-database.firebaseapp.com",
  projectId: "halloum-database",
  storageBucket: "halloum-database.appspot.com",
  messagingSenderId: "114131428719",
  appId: "1:114131428719:web:e22abb66a913f71cce749a",
  measurementId: "G-6RB6GSQN29",
};

const mat = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSliderModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatRadioModule,
  MatTooltipModule,
  MatBadgeModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTableModule,
  MatPaginatorModule,
  MatStepperModule,
  MatTabsModule,
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,

    HomeComponent,
    FileSizePipe,
    OnlyNumbersDirective,
    AboutComponent,
    ContactComponent,
    FabComponent,
    BranchesComponent,
    ProductsComponent,
    ProductComponent,
    CareersComponent,
    TeamComponent,
    GalleryComponent,
    LazyImgDirective,
    DeferLoadDirective,
    PrivacyPolicyComponent,
    SafePipe,
    SignInComponent,
    SignUpComponent,
    AuthComponent,
    ProfileComponent,
    UserAgreementComponent,
    SafeHTMLPipe,
    CartComponent,
    AddressDialog,
    AutoCompleteComponent,
    ProductTableComponent,
    MyOrdersComponent,
    MyAddressesComponent,
    AddAddressDialog,
    EditAddressDialog,
    ForgetPasswordComponent,
    WinWinComponent,
    PaymentComponent,
    NavigatorListComponent,
    FollowUsComponent,
    DeleteDialogComponent,
    BlogComponent,
    VisionMissionComponent,
    BlogDetailComponent,
    CategoriesComponent,
    ProductOneComponent,
  ],
  imports: [
    ...mat,
    BrowserModule,
    HttpClientModule,
    AngularEditorModule,

    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    AnimateOnScrollModule.forRoot(),
    FacebookModule.forRoot(),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config, "halloum-database"),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    Ng2SearchPipeModule,
    SwiperModule,
    LightboxModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CreditCardDirectivesModule,
    ImgMagnifier,
  ],
  exports: [...mat],
  providers: [
    SidenavService,
    Globals,
    { provide: REGION, useValue: "us-central1" },
    { provide: "BaseURL", useValue: baseURL },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
