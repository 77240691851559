import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { SidenavService } from "../services/sidenav.service";

@Component({
     selector: "app-footer",
     templateUrl: "./footer.component.html",
     styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
     data: Date = new Date();
     date: { year: number; month: number };

     selectedLang = "english";
     mdScreen: boolean = false;

     @HostListener("window:resize", ["$event"])
     onResize(event) {
          if (event.target.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }

     constructor(
          private sidenav: SidenavService,
          private router: Router,
          @Inject(DOCUMENT) private document: Document
     ) {
          if (window.innerWidth <= 850) {
               this.mdScreen = true;
          } else {
               this.mdScreen = false;
          }
     }

     ngOnInit(): void {}

     toggleSidenav() {
          this.sidenav.toggle();
     }

     goToEN(): void {
          var routerURL = this.router.url;
          this.document.location.href = "https://en.halloumoglu.com" + routerURL;
     }

     goToTR(): void {
          var routerURL = this.router.url;
          this.document.location.href = "https://tr.halloumoglu.com" + routerURL;
     }

     goToAR(): void {
          var routerURL = this.router.url;
          this.document.location.href = "https://ar.halloumoglu.com" + routerURL;
     }
}
